import React from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useFunctions, useRefs } from '../hooks';
import { Qmf_wradministradoresWrapper } from '../wrappers';

import { useLocation } from 'react-router-dom';
import { getLocationParams } from '../util';

// Custom imports:
import { Group, Text, Image } from '../components';
import { useEffect } from 'react';
import calculadora from '../assets/iconos/assest/calculadora.png';
import conversacion from '../assets/iconos/assest/conversacion.png';
import carpeta from '../assets/iconos/assest/carpeta.png';
import relacion from '../assets/iconos/assest/relacion.png';
import carrito from '../assets/iconos/assest/carrito.png';
import vender from '../assets/iconos/assest/vender.png';
import { useTranslation } from 'react-i18next';

export const Qmf_administradoresView = () => {
  const { run } = useFunctions();
  const { search, pathname } = useLocation();


  // Custom exports:
	const { t } = useTranslation();

  // Custom functions:
	const qmf_administradoresParams = () => {
		run( getLocationParams( search, pathname ) )
	}
	useEffect( qmf_administradoresParams, [])
	const qmf_administradoresOnLoaded = async (options) => {
		run([{"id":"goto1694131755051","function":"goto","view":"qmfhome","if":{"what":"@firebase.user.id","is":null}}], options)
	}
	useEffect( qmf_administradoresOnLoaded, [])
	const homeHighlightsGroupu1701466896214OnClick = async (options) => {
		run([{"id":"goto1701466896214","function":"goto","view":"qmfsimulador_1"}], options)
	}
	const homeHighlightsGroupu1696659215836OnClick = async (options) => {
		run([{"id":"goto1696659215836","function":"goto","view":"qmf_visitas"}], options)
	}
	const homeHighlightsGroupu1693639406566OnClick = async (options) => {
		run([{"id":"goto1693639406566","function":"goto","view":"qmf_conversaciones"}], options)
	}
	const homeHighlightsGroupu1693636830635OnClick = async (options) => {
		run([{"id":"goto1693636830635","function":"goto","view":"qmfhistorial"}], options)
	}
	const homeHighlightsGroupi1693636830635OnClick = async (options) => {
		run([{"id":"goto1693636830635","function":"goto","view":"qmfrelaciones"}], options)
	}
	const homeHighlightsGroupe1693636830635OnClick = async (options) => {
		run([{"id":"goto1693636830635","function":"goto","view":"qmf_demandas"}], options)
	}
	const homeHighlightsGroupo1693636830635OnClick = async (options) => {
		run([{"id":"goto1693636830635","function":"goto","view":"qmf_ofertas"}], options)
	}
	const homeHighlightsGroupo1705426208500OnClick = async (options) => {
		run([{"id":"goto1705426208500","function":"goto","view":"qmf_perfil"}], options)
	}

  return (
    <>
      <Helmet>
				<title>{t('qmf_administradores.title')}</title>
      </Helmet>
      <Qmf_wradministradoresWrapper>
          
			<Group
				id="grupoadministradores1693636830635"
				direction="vertical"
				className="w-100 h-auto align-center position-relative" >
				<Group
					id="signio5566ounFortrerm1693636830635"
					direction="vertical"
					className="w-100 h-auto align-center position-relative p-15px" >
					<Text
						id="te1233x888t1693636830635"
						tag="h1"
						className="w-100 h-auto position-relative text-primary-light ff-gantaribold" >
						{t('qmf_administradores.te1233x888t1693636830635')}
					</Text>
					<Text
						id="treiooyut1693636830635"
						className="w-100 min-h-3px h-3px position-relative bg-primary-light" >
						{t('qmf_administradores.treiooyut1693636830635')}
					</Text>
				</Group>
				<Group
					id="groulotripleevp1693636830635"
					direction="auto"
					className="w-100 h-auto align-center valign-center position-relative p-10px" >
					<Group
						id="homeHighlights1693636830635"
						direction="vertical"
						className="max-w-1800px w-100 h-auto align-center position-relative p-15px" >
						<Group
							id="homeHighlightsWrapper1693636830635"
							direction="auto"
							className="w-100 h-auto position-relative mt-32px" >
							<Group
								id="homeHighlightsGroupo1705426208500"
								onClick={homeHighlightsGroupo1705426208500OnClick}
								direction="vertical"
								className="min-w-300px w-fill min-h-300px h-300px border-radius-40px position-relative bg-primary-light-to-primary-dark p-16px me-30px mb-30px" >
								<Image
									id="homeHighlightsGroup1Icon1705426208500"
									src={vender}
									className="max-w-200px w-50 h-auto align-center position-relative p-15px" />
								<Text
									id="homeHighlightsGroup1Title1705426208500"
									tag="h2"
									className="max-w-400px w-auto h-auto align-center position-relative m-12px text-text-on-accent ff-home text-center" >
									{t('qmf_administradores.homeHighlightsGroup1Title1705426208500')}
								</Text>
							</Group>
							<Group
								id="homeHighlightsGroupo1693636830635"
								onClick={homeHighlightsGroupo1693636830635OnClick}
								direction="vertical"
								className="min-w-300px w-fill min-h-300px h-300px border-radius-40px position-relative bg-primary-light-to-primary-dark p-16px me-30px mb-30px" >
								<Image
									id="homeHighlightsGroup1Icon1693636830635"
									src={vender}
									className="max-w-200px w-50 h-auto align-center position-relative p-15px" />
								<Text
									id="homeHighlightsGroup1Title1693636830635"
									tag="h2"
									className="max-w-400px w-auto h-auto align-center position-relative m-12px text-text-on-accent ff-home text-center" >
									{t('qmf_administradores.homeHighlightsGroup1Title1693636830635')}
								</Text>
							</Group>
							<Group
								id="homeHighlightsGroupe1693636830635"
								onClick={homeHighlightsGroupe1693636830635OnClick}
								direction="vertical"
								className="min-w-300px w-fill min-h-300px h-300px border-radius-40px position-relative bg-primary-light-to-primary-dark p-16px me-30px mb-30px" >
								<Image
									id="homeHighlightsGroup2Icon1693636830635"
									src={carrito}
									className="max-w-200px w-50 max-h-100 h-auto align-center position-relative p-15px mt-20px" />
								<Text
									id="homeHighlightsGroup2Title1693636830635"
									tag="h2"
									className="max-w-400px w-auto h-auto align-center position-relative m-12px text-text-on-accent ff-home text-center" >
									{t('qmf_administradores.homeHighlightsGroup2Title1693636830635')}
								</Text>
							</Group>
							<Group
								id="homeHighlightsGroupi1693636830635"
								onClick={homeHighlightsGroupi1693636830635OnClick}
								direction="vertical"
								className="min-w-300px w-fill min-h-300px h-300px border-radius-40px position-relative bg-primary-light-to-primary-dark p-16px me-30px mb-30px" >
								<Image
									id="homeHighlightsGroup3Icon1693636830635"
									src={relacion}
									className="max-w-200px w-50 h-auto align-center position-relative p-15px" />
								<Text
									id="homeHighlightsGroup3Title1693636830635"
									tag="h2"
									className="max-w-400px w-auto h-auto align-center position-relative m-12px text-text-on-accent ff-home text-center" >
									{t('qmf_administradores.homeHighlightsGroup3Title1693636830635')}
								</Text>
							</Group>
							<Group
								id="homeHighlightsGroupu1693636830635"
								onClick={homeHighlightsGroupu1693636830635OnClick}
								direction="vertical"
								className="min-w-300px w-fill min-h-300px h-300px border-radius-40px position-relative bg-primary-light-to-primary-dark p-16px me-30px mb-30px" >
								<Image
									id="homeHighlightsGroup4Icon1693636830635"
									src={carpeta}
									className="max-w-200px w-50 h-auto align-center position-relative p-15px" />
								<Text
									id="homeHighlightsGroup4Title1693636830635"
									tag="h2"
									className="max-w-400px w-auto h-auto align-center position-relative m-12px text-text-on-accent ff-home text-center" >
									{t('qmf_administradores.homeHighlightsGroup4Title1693636830635')}
								</Text>
							</Group>
							<Group
								id="homeHighlightsGroupu1693639406566"
								onClick={homeHighlightsGroupu1693639406566OnClick}
								direction="vertical"
								className="min-w-300px w-fill min-h-300px h-300px border-radius-40px position-relative bg-primary-light-to-primary-dark p-16px me-30px mb-30px" >
								<Image
									id="homeHighlightsGroup5Icon1693639406566"
									src={conversacion}
									className="max-w-200px w-50 h-auto align-center position-relative p-15px" />
								<Text
									id="homeHighlightsGroup5Title1693639406566"
									tag="h2"
									className="max-w-400px w-auto h-auto align-center position-relative m-12px text-text-on-accent ff-home text-center" >
									{t('qmf_administradores.homeHighlightsGroup5Title1693639406566')}
								</Text>
							</Group>
							<Group
								id="homeHighlightsGroupu1696659215836"
								onClick={homeHighlightsGroupu1696659215836OnClick}
								direction="vertical"
								className="min-w-300px w-fill min-h-300px h-300px border-radius-40px position-relative bg-primary-light-to-primary-dark p-16px me-30px mb-30px" >
								<Image
									id="homeHighlightsGroup6Icon1696659215836"
									src={conversacion}
									className="max-w-200px w-50 h-auto align-center position-relative p-15px" />
								<Text
									id="homeHighlightsGroup6Title1696659215836"
									tag="h2"
									className="max-w-400px w-auto h-auto align-center position-relative m-12px text-text-on-accent ff-home text-center" >
									{t('qmf_administradores.homeHighlightsGroup6Title1696659215836')}
								</Text>
							</Group>
							<Group
								id="homeHighlightsGroupu1701466896214"
								onClick={homeHighlightsGroupu1701466896214OnClick}
								direction="vertical"
								className="min-w-300px w-fill min-h-300px h-300px border-radius-40px position-relative bg-primary-light-to-primary-dark p-16px me-30px mb-30px" >
								<Image
									id="homeHighlightsGroup6Icon1701466896214"
									src={calculadora}
									className="max-w-200px w-50 h-auto align-center position-relative p-15px" />
								<Text
									id="homeHighlightsGroup6Title1701466896214"
									tag="h2"
									className="max-w-400px w-auto h-auto align-center position-relative m-12px text-text-on-accent ff-home text-center" >
									{t('qmf_administradores.homeHighlightsGroup6Title1701466896214')}
								</Text>
							</Group>
						</Group>
					</Group>
				</Group>
			</Group>
      </Qmf_wradministradoresWrapper>
    </>
  );
};
