import React from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useFunctions, useRefs } from '../hooks';
import { Wr_menu_portadaWrapper } from '../wrappers';

import { useLocation } from 'react-router-dom';
import { getLocationParams } from '../util';

// Custom imports:
import { Group, Image, Text } from '../components';
import icon_512 from '../assets/icon/icon_512.png';
import { useTranslation } from 'react-i18next';

export const BienvenidaView = () => {
  const { run } = useFunctions();
  const { search, pathname } = useLocation();


  // Custom exports:
	const { t } = useTranslation();

  // Custom functions:

  return (
    <>
      <Helmet>
				<title>{t('bienvenida.title')}</title>
      </Helmet>
      <Wr_menu_portadaWrapper>
          
			<Group
				id="groulodobleevp1686389618844"
				direction="auto"
				className="w-100 h-auto position-relative p-40px" >
				<Group
					id="grogfrup1686389618844"
					direction="vertical"
					className="min-w-300px w-fill h-auto align-center valign-center position-relative" >
					<Image
						id="imagenhr1686389618844"
						src={icon_512}
						className="w-100 min-h-500px h-500px align-center valign-center position-relative contain" />
				</Group>
				<Group
					id="grohhyup1686389618844"
					direction="vertical"
					className="w-auto h-auto position-relative p-20px" >
					<Text
						id="textprinci1686389618844"
						className="max-w-800px w-auto h-auto position-relative text-primary-text text-start" >
						{t('bienvenida.textprinci1686389618844')}
					</Text>
				</Group>
			</Group>
      </Wr_menu_portadaWrapper>
    </>
  );
};
