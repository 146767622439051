import React from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useFunctions, useRefs } from '../hooks';
import { Qmf_wradministradoresWrapper } from '../wrappers';

import { useLocation } from 'react-router-dom';
import { getLocationParams } from '../util';

// Custom imports:
import { Group, Text, Input, List } from '../components';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export const Qmf_visitasView = () => {
  const { run } = useFunctions();
  const { search, pathname } = useLocation();


  // Custom exports:
	const { t } = useTranslation();

  // Custom functions:
	const qmf_visitasParams = () => {
		run( getLocationParams( search, pathname ) )
	}
	useEffect( qmf_visitasParams, [])
	const qmf_visitasOnLoaded = async (options) => {
		run([{"id":"goto1696660722233","function":"goto","view":"qmfhome","if":{"what":"@firebase.user.id","is":null}},{"id":"set1696660722233","function":"set","what":"@property.desde","value":0},{"id":"set1696660722233","function":"set","what":"@property.hasta","value":4102444800000}], options)
	}
	useEffect( qmf_visitasOnLoaded, [])
	const texeliminar1696660722233OnClick = async (options) => {
		run([{"id":"delete1696660722233","function":"delete","what":"@firebase.firestore.cookies.(@element.lista_visitas.selected)"}], options)
	}
	const inputtextdedes1696660722233OnChanged = async (options) => {
		run([{"id":"set1696660722233","function":"set","what":"@property.desde","value":0,"if":{"what":"@property.desde","more_than":"@property.hasta"}},{"id":"set1696660722233","function":"set","what":"@property.desde","value":0,"if":{"what":"@property.desde","is":"@property.hasta"}}], options)
	}

  return (
    <>
      <Helmet>
				<title>{t('qmf_visitas.title')}</title>
      </Helmet>
      <Qmf_wradministradoresWrapper>
          
			<Group
				id="groukkyy45544p1696660722233"
				direction="vertical"
				className="w-100 h-fill align-center position-relative p-20px" >
				<Group
					id="group1696660722233"
					direction="auto"
					className="w-100 h-auto position-relative mt-24px" >
					<Text
						id="ttfgcse1233x888t1696660722232"
						className="w-auto h-auto valign-center position-relative text-primary-light f-25px ff-gantaribold" >
						{t('qmf_visitas.ttfgcse1233x888t1696660722232')}
					</Text>
					<Text
						id="texfgfgft1696660722233"
						className="w-fill h-auto position-relative" >
						{t('qmf_visitas.texfgfgft1696660722233')}
					</Text>
				</Group>
				<Group
					id="groupfiltroofer1696660722233"
					direction="auto"
					className="w-100 h-auto position-relative" >
					<Group
						id="groupdesde1696660722233"
						direction="horizontal"
						className="w-auto h-auto position-relative" >
						<Text
							id="texdedesd1696660722233"
							className="w-auto h-auto position-relative m-5px text-primary" >
							{t('qmf_visitas.texdedesd1696660722233')}
						</Text>
						<Input.Date
							id="inputtextdedes1696660722233"
							name="text"
							src="@property.desde"
						placeholder={t('qmf_visitas.inputtextdedes1696660722233.hint')}
						autoComplete="off"
						onChange={inputtextdedes1696660722233OnChanged}
							className="w-auto h-auto position-relative m-5px" />
					</Group>
					<Group
						id="grouphasta1696660722233"
						direction="horizontal"
						className="w-auto h-auto position-relative" >
						<Text
							id="tehasta1696660722233"
							className="w-auto h-auto position-relative m-5px text-primary" >
							{t('qmf_visitas.tehasta1696660722233')}
						</Text>
						<Input.Date
							id="inputtexthasta1696660722233"
							name="text"
							src="@property.hasta"
						placeholder={t('qmf_visitas.inputtexthasta1696660722233.hint')}
						autoComplete="off"
							className="w-auto h-auto position-relative m-5px" />
					</Group>
				</Group>
				<Text
					id="trrtreiooyut1696660722233"
					className="w-100 min-h-3px h-3px position-relative bg-primary-light" >
					{t('qmf_visitas.trrtreiooyut1696660722233')}
				</Text>
				<Group
					id="signioounForm1696660722233"
					direction="vertical"
					className="w-100 h-auto border-radius-15px align-center position-relative p-32px mt-40px" >
					<List
						id="lista_visitas"
						src="@firebase.firestore.cookies"
						filter={{"what":"timestamp","more_equal_than":"@property.desde","and":{"what":"timestamp","less_than":"@property.hasta"}}}
						order={{"type":"desc","field":"timestamp"}}
						className="w-100 h-auto position-relative" >
						<Group
							id="group1696660722233"
							if={{"what":"@field.estado","is_not":"Borrador"}}
							direction="vertical"
							className="w-100 h-auto border-top border-bottom border-start border-end border-radius-20px position-relative p-10px mt-10px" >
							<Group
								id="groupprincida1696660722233"
								direction="auto"
								className="w-100 h-auto position-relative" >
								<Group
									id="groupdato1696660722233"
									direction="vertical"
									className="min-w-600px w-fill h-auto position-relative p-10px" >
									<Group
										id="grouhdhfech1696660722233"
										direction="horizontal"
										className="w-100 h-auto position-relative" >
										<Text
											id="texjjkoppt1696660722233"
											className="w-auto h-auto position-relative ff-gantaribold" >
											{t('qmf_visitas.texjjkoppt1696660722233')}
										</Text>
										<Text
											id="texjjkoppt1696660722233"
											format={{"type":"date","day":"numeric","hour":"numeric","month":"numeric","year":"numeric","minute":"numeric"}}
											src="timestamp"
											className="w-fill h-auto position-relative ff-gantaribold" >
											{t('qmf_visitas.texjjkoppt1696660722233')}
										</Text>
									</Group>
									<Group
										id="grouhdhfech1700773996847"
										direction="horizontal"
										className="w-100 h-auto position-relative" >
										<Text
											id="texjjkoppt1700773996846"
											src="direccionip"
											className="w-auto h-auto border-radius-5px position-relative p-5px ff-gantaribold" >
											{t('qmf_visitas.texjjkoppt1700773996846')}
										</Text>
									</Group>
								</Group>
							</Group>
							<Group
								id="groupmenulista1696660722233"
								visible={false}
								direction="auto"
								className="w-100 h-auto position-relative mt-20px" >
								<Text
									id="texgggt1696660722233"
									className="w-fill h-auto position-relative" >
									{t('qmf_visitas.texgggt1696660722233')}
								</Text>
								<Text
									id="texeliminar1696660722233"
									onClick={texeliminar1696660722233OnClick}
									tag="sup"
									className="w-auto h-auto border-radius-10px position-relative bg-primary-light-to-primary-dark p-15px me-15px text-text-on-accent text-center" >
									{t('qmf_visitas.texeliminar1696660722233')}
								</Text>
							</Group>
						</Group>
					</List>
				</Group>
			</Group>
      </Qmf_wradministradoresWrapper>
    </>
  );
};
