import React from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useFunctions, useRefs } from '../hooks';
import { Qmf_wradministradoresWrapper } from '../wrappers';

import { useLocation } from 'react-router-dom';
import { getLocationParams } from '../util';

// Custom imports:
import { Group, Text, Input, List } from '../components';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export const QmfhistorialView = () => {
  const { run } = useFunctions();
  const { search, pathname } = useLocation();


  // Custom exports:
	const { t } = useTranslation();

  // Custom functions:
	const qmfhistorialParams = () => {
		run( getLocationParams( search, pathname ) )
	}
	useEffect( qmfhistorialParams, [])
	const qmfhistorialOnLoaded = async (options) => {
		run([{"id":"set1689280816268","function":"set","what":"@property.desde","value":0},{"id":"set1689280848286","function":"set","what":"@property.hasta","value":4102444800000}], options)
	}
	useEffect( qmfhistorialOnLoaded, [])
	const inputtext1689188275690OnChanged = async (options) => {
		run([{"id":"set1689280571079","function":"set","what":"@property.desde","value":0,"if":{"what":"@property.desde","more_than":"@property.hasta"}},{"id":"set1689280697191","function":"set","what":"@property.desde","value":0,"if":{"what":"@property.desde","is":"@property.hasta"}}], options)
	}

  return (
    <>
      <Helmet>
				<title>{t('qmfhistorial.title')}</title>
      </Helmet>
      <Qmf_wradministradoresWrapper>
          
			<Group
				id="gr12ou45544p1689188170414"
				if={{"what":"","is":""}}
				direction="vertical"
				className="w-100 h-auto align-center position-relative" >
				<Group
					id="signio5566ounFortrerm1689188170414"
					direction="vertical"
					className="w-100 h-auto border-radius-15px align-center position-relative p-32px mt-24px" >
					<Text
						id="te1233x888t1689188170414"
						tag="h1"
						className="w-100 h-auto position-relative text-primary-light ff-gantaribold" >
						{t('qmfhistorial.te1233x888t1689188170414')}
					</Text>
					<Group
						id="group1689188170414"
						direction="auto"
						className="w-100 h-auto position-relative" >
						<Group
							id="group1690563782392"
							direction="horizontal"
							className="w-auto h-auto position-relative" >
							<Text
								id="te44124xt167lll1689274135215"
								className="w-auto h-auto position-relative m-5px text-primary" >
								{t('qmfhistorial.te44124xt167lll1689274135215')}
							</Text>
							<Input.Date
								id="inputtext1689188275690"
								name="text"
								src="@property.desde"
							placeholder={t('qmfhistorial.inputtext1689188275690.hint')}
							autoComplete="off"
							onChange={inputtext1689188275690OnChanged}
								className="w-auto h-auto position-relative m-5px" />
						</Group>
						<Group
							id="group1690563806908"
							direction="horizontal"
							className="w-auto h-auto position-relative" >
							<Text
								id="te44124xt167lll1689274175007"
								className="w-auto h-auto position-relative m-5px text-primary" >
								{t('qmfhistorial.te44124xt167lll1689274175007')}
							</Text>
							<Input.Date
								id="inputtext1689188297116"
								name="text"
								src="@property.hasta"
							placeholder={t('qmfhistorial.inputtext1689188297116.hint')}
							autoComplete="off"
								className="w-auto h-auto position-relative m-5px" />
						</Group>
					</Group>
					<Text
						id="treiooyut1689188170414"
						className="w-100 min-h-3px h-3px position-relative bg-primary-light mt-10px" >
						{t('qmfhistorial.treiooyut1689188170414')}
					</Text>
					<Group
						id="groupssdetvasq1690736350266"
						direction="vertical"
						className="w-100 h-auto position-relative scrollable" >
						<List
							id="listshistorial"
							src="@firebase.firestore.historial"
							filter={{"what":"timestamp","and":{"what":"timestamp","more_than":"@property.desde"},"less_equal_than":"@property.hasta"}}
							order={{"type":"desc","field":"timestamp"}}
							className="w-100 h-auto position-relative" >
							<Group
								id="group16pp1689188170414"
								direction="vertical"
								className="w-100 h-auto border-top border-bottom border-start border-end border-radius-20px position-relative p-10px mt-10px" >
								<Group
									id="gr4353pp9oup167oo1689188170414"
									direction="auto"
									className="w-80 h-auto position-relative" >
									<Text
										id="te44124xt167lll1689188170414"
										className="w-auto h-auto position-relative text-primary" >
										{t('qmfhistorial.te44124xt167lll1689188170414')}
									</Text>
									<Text
										id="te444xt167lll1689188170414"
										format={{"type":"date","day":"numeric","hour":"numeric","month":"numeric","weekday":"short","year":"numeric","minute":"numeric"}}
										src="timestamp"
										className="w-fill h-auto position-relative" >
										{t('qmfhistorial.te444xt167lll1689188170414')}
									</Text>
								</Group>
								<Group
									id="gr4353pp9oup167oo1689188723324"
									direction="auto"
									className="w-80 h-auto position-relative" >
									<Text
										id="te444xt167lll1689188723324"
										src="anotacion"
										className="w-fill h-auto position-relative" >
										{t('qmfhistorial.te444xt167lll1689188723324')}
									</Text>
								</Group>
							</Group>
						</List>
					</Group>
				</Group>
			</Group>
      </Qmf_wradministradoresWrapper>
    </>
  );
};
