import { i18next } from './IntlProvider';

const languages = [
	{
		code: 'xx',
		text: '',
	},
];

export { i18next as i18n, languages };
