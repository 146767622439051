import React from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useFunctions, useRefs } from '../hooks';
import { Qmf_wradministradoresWrapper } from '../wrappers';

import { useLocation } from 'react-router-dom';
import { getLocationParams } from '../util';

// Custom imports:
import { Group, Icon, Text, Input, List, Lottie } from '../components';
import { useEffect } from 'react';
import puntos from '../assets/puntos.json';
import { useTranslation } from 'react-i18next';

export const Qmfsimulador_1View = () => {
  const { run } = useFunctions();
  const { search, pathname } = useLocation();


  // Custom exports:
	const { t } = useTranslation();

  // Custom functions:
	const qmfsimulador_1Params = () => {
		run( getLocationParams( search, pathname ) )
	}
	useEffect( qmfsimulador_1Params, [])
	const qmfsimulador_1OnLoaded = async (options) => {
		run([{"id":"set1701709123989","function":"set","what":"@property.ini","value":"@app.timestamp"},{"id":"set1701709123989","function":"set","what":"@cookie.keysimulador","value":"qmfsim(@property.ini)"},{"id":"do1703322241327","function":"do","what":[{"id":"set1703322263606","function":"set","what":"@property.simcapitalpropio","value":0},{"id":"set1703322417413","function":"set","what":"@property.simduracionprestamo","value":20},{"id":"set1703322438954","function":"set","what":"@property.simfacturacion","value":500000},{"id":"set1703322500801","function":"set","what":"@property.simgastosfijos","value":6},{"id":"set1703322545532","function":"set","what":"@property.simgastosformalizacion","value":30000},{"id":"set1703322570234","function":"set","what":"@property.simincrementofacturacion","value":0},{"id":"set1703322878840","function":"set","what":"@property.siminteresprestamo","value":5},{"id":"set1703322626508","function":"set","what":"@property.siminversor","value":0},{"id":"set1703322685215","function":"set","what":"@property.simmargenbruto","value":30},{"id":"set1703322726879","function":"set","what":"@property.simprecio","value":700000},{"id":"set1703322794091","function":"set","what":"@property.simpreciolocal","value":100000},{"id":"set1703322811145","function":"set","what":"@property.simrentabilidadinversor","value":6}]},{"id":"set1701709123989","function":"set","what":"@property.anualidad","value":1},{"id":"set1701716438794","function":"set","what":"@property.calculadora","value":1}], options)
	}
	useEffect( qmfsimulador_1OnLoaded, [])
	const iconribotdd1op1701713686340OnClick = async (options) => {
		run([{"id":"goto1701974998688","function":"goto","view":"qmf_administradores"}], options)
	}
	const iconatras1op1701713686340OnClick = async (options) => {
		run([{"id":"set1701713686340","function":"set","what":"@property.calculadora","value":4}], options)
	}
	const concomprar1701710124190OnClick = async (options) => {
		run([{"id":"set1701710124190","function":"set","what":"@property.calculadora","value":5},{"id":"add1703323332618","function":"add","what":{"facturacion":"@property.simfacturacion","gastosfijos":"@property.simgastosfijos","precio":"@property.simprecio","preciolocal":"@property.simpreciolocal","gastosformalizacion":"@property.simgastosformalizacion","inversor":"@property.siminversor","capitalpropio":"@property.simcapitalpropio","duracionprestamo":"@property.simduracionprestamo","interesprestamo":"@property.siminteresprestamo","rentabilidadinversor":"@property.simrentabilidadinversor","margenbruto":"@property.simmargenbruto","incrementofacturacion":"@property.simincrementofacturacion"},"to":"@firebase.firestore.simulador","key":"@cookie.keysimulador"},{"id":"set1703353063683","function":"set","what":"@firebase.firestore.simulador.(@cookie.keysimulador).lottie","value":true},{"id":"set1701715755702","function":"set","what":"@firebase.firestore.simulador.(@cookie.keysimulador).calcular","value":"@app.timestamp"}], options)
	}
	const iconribotdd1op1701710124190OnClick = async (options) => {
		run([{"id":"goto1701975204110","function":"goto","view":"qmf_administradores"}], options)
	}
	const iconatras1op1701710124190OnClick = async (options) => {
		run([{"id":"set1701710124190","function":"set","what":"@property.calculadora","value":3}], options)
	}
	const concomprar1701710115440OnClick = async (options) => {
		run([{"id":"set1701710115440","function":"set","what":"@property.calculadora","value":4}], options)
	}
	const iconribotdd1op1701710115440OnClick = async (options) => {
		run([{"id":"goto1701975153780","function":"goto","view":"qmf_administradores"}], options)
	}
	const iconatras1op1701710115440OnClick = async (options) => {
		run([{"id":"set1701710115440","function":"set","what":"@property.calculadora","value":2}], options)
	}
	const concomprar1701710001951OnClick = async (options) => {
		run([{"id":"set1701710001951","function":"set","what":"@property.calculadora","value":3}], options)
	}
	const iconribotdd1op1701710001951OnClick = async (options) => {
		run([{"id":"goto1701975142520","function":"goto","view":"qmf_administradores"}], options)
	}
	const iconatras1op1701710001951OnClick = async (options) => {
		run([{"id":"set1701710001951","function":"set","what":"@property.calculadora","value":1}], options)
	}
	const concomprar1701709125847OnClick = async (options) => {
		run([{"id":"set1701709125847","function":"set","what":"@property.calculadora","value":2}], options)
	}
	const iconribotdd1op1701709125847OnClick = async (options) => {
		run([{"id":"goto1701975086743","function":"goto","view":"qmf_administradores"}], options)
	}
	const iconatras1op1701709125847OnClick = async (options) => {
		run([{"id":"set1701709125847","function":"set","what":"@property.formulario","value":"inicio"}], options)
	}

  return (
    <>
      <Helmet>
				<title>{t('qmfsimulador_1.title')}</title>
      </Helmet>
      <Qmf_wradministradoresWrapper>
          
			<Group
				id="groupprincicalculadordos"
				direction="vertical"
				className="w-100 h-auto position-relative p-30px" >
				<Group
					id="authForms1701709125847"
					direction="vertical"
					className="w-100 h-auto border-accent border-top border-bottom border-start border-end border-radius-15px valign-start position-relative" >
					<Group
						id="calculadora1sim"
						if={{"what":"@property.calculadora","is":1}}
						direction="vertical"
						className="w-100 h-auto border-radius-15px position-relative bg-whitetrans p-15px" >
						<Group
							id="groupatrascerrar1701709125847"
							direction="horizontal"
							className="w-100 h-auto position-relative" >
							<Icon
								id="iconatras1op1701709125847"
								onClick={iconatras1op1701709125847OnClick}
								src="arrow-left-bold-circle-outline"
								className="w-auto h-auto align-end valign-center position-relative"
							/>
							<Text
								id="textgrdwb1op1701709125847"
								className="w-fill h-auto position-relative" >
								{t('qmfsimulador_1.textgrdwb1op1701709125847')}
							</Text>
							<Icon
								id="iconribotdd1op1701709125847"
								onClick={iconribotdd1op1701709125847OnClick}
								src="close-box-outline"
								className="w-auto h-auto align-end valign-center position-relative"
							/>
						</Group>
						<Group
							id="tituloinicio1701709125847"
							direction="vertical"
							className="w-100 h-auto position-relative" >
							<Text
								id="textotitulo1701709125847"
								tag="h1"
								className="w-auto h-auto align-start valign-center position-relative text-primary-light ff-sonobold" >
								{t('qmfsimulador_1.textotitulo1701709125847')}
							</Text>
						</Group>
						<Group
							id="pregustas1compra1701709125847"
							direction="vertical"
							className="w-100 h-auto position-relative" >
							<Group
								id="groupsimfacturacion1701710006720"
								direction="vertical"
								className="w-100 h-auto position-relative" >
								<Group
									id="grouplintitulo1701710006720"
									direction="auto"
									className="w-100 h-auto position-relative" >
									<Text
										id="texttitulo1701710006720"
										className="w-auto h-auto position-relative" >
										{t('qmfsimulador_1.texttitulo1701710006720')}
									</Text>
									<Text
										id="textcamposim1701710006720"
										format={{"type":"currency"}}
										src="@property.simfacturacion"
										className="w-auto h-auto position-relative" >
										{t('qmfsimulador_1.textcamposim1701710006720')}
									</Text>
								</Group>
								<Input.Range
									id="rangesimuladorcampo1701710006720"
									name="range"
									src="@property.simfacturacion"
								max={5000000}
								min={0}
								step={100000}
									className="w-100 h-auto position-relative mb-5px" />
								<Group
									id="grouplinlimites1701710006720"
									direction="horizontal"
									className="w-100 h-auto position-relative" >
									<Text
										id="textlimiteuno1701710006720"
										tag="sub"
										className="w-auto h-auto position-relative" >
										{t('qmfsimulador_1.textlimiteuno1701710006720')}
									</Text>
									<Text
										id="text1701710006720"
										className="w-fill h-auto position-relative" >
										{t('qmfsimulador_1.text1701710006720')}
									</Text>
									<Text
										id="textcamposim1701710006720"
										tag="sub"
										className="w-auto h-auto align-end position-relative" >
										{t('qmfsimulador_1.textcamposim1701710006720')}
									</Text>
								</Group>
							</Group>
							<Group
								id="groupsincrementofacturacionanual1701798404167"
								direction="vertical"
								className="w-100 h-auto position-relative" >
								<Group
									id="grouplintitulo1701798404167"
									direction="auto"
									className="w-100 h-auto position-relative" >
									<Text
										id="texttitulo1701798404167"
										className="w-auto h-auto position-relative" >
										{t('qmfsimulador_1.texttitulo1701798404167')}
									</Text>
									<Text
										id="textcamposijjjjm1701798404167"
										src="@property.simincrementofacturacion"
										className="w-auto h-auto position-relative" >
										{t('qmfsimulador_1.textcamposijjjjm1701798404167')}
									</Text>
									<Text
										id="textcamposporcineto1701798404167"
										className="w-auto h-auto position-relative" >
										{t('qmfsimulador_1.textcamposporcineto1701798404167')}
									</Text>
								</Group>
								<Input.Range
									id="rangesimuladorcampo1701798404167"
									name="range"
									src="@property.simincrementofacturacion"
								max={10}
								min={0}
								step={0.25}
									className="w-100 h-auto position-relative mb-5px" />
								<Group
									id="grouplinlimites1701798404167"
									direction="horizontal"
									className="w-100 h-auto position-relative" >
									<Text
										id="textlimiteuno1701798404167"
										tag="sub"
										className="w-auto h-auto position-relative" >
										{t('qmfsimulador_1.textlimiteuno1701798404167')}
									</Text>
									<Text
										id="text1701798404167"
										className="w-fill h-auto position-relative" >
										{t('qmfsimulador_1.text1701798404167')}
									</Text>
									<Text
										id="textcamposim1791798404167"
										tag="sub"
										className="w-auto h-auto align-end position-relative" >
										{t('qmfsimulador_1.textcamposim1791798404167')}
									</Text>
								</Group>
							</Group>
							<Group
								id="groupsimmargenbruto1701710165914"
								direction="vertical"
								className="w-100 h-auto position-relative" >
								<Group
									id="grouplintitulo1701710165914"
									direction="auto"
									className="w-100 h-auto position-relative" >
									<Text
										id="texttitulo1701710165914"
										className="w-auto h-auto position-relative" >
										{t('qmfsimulador_1.texttitulo1701710165914')}
									</Text>
									<Text
										id="textcamposim1701710165914"
										src="@property.simmargenbruto"
										className="w-auto h-auto position-relative" >
										{t('qmfsimulador_1.textcamposim1701710165914')}
									</Text>
									<Text
										id="textcamposporcineto1701710165914"
										className="w-auto h-auto position-relative" >
										{t('qmfsimulador_1.textcamposporcineto1701710165914')}
									</Text>
								</Group>
								<Input.Range
									id="rangesimuladorcampo1701710165914"
									name="range"
									src="@property.simmargenbruto"
								max={60}
								min={0}
								step={5}
									className="w-100 h-auto position-relative mb-5px" />
								<Group
									id="grouplinlimites1701710165914"
									direction="horizontal"
									className="w-100 h-auto position-relative" >
									<Text
										id="textlimiteuno1701710165914"
										tag="sub"
										className="w-auto h-auto position-relative" >
										{t('qmfsimulador_1.textlimiteuno1701710165914')}
									</Text>
									<Text
										id="text1701710165914"
										className="w-fill h-auto position-relative" >
										{t('qmfsimulador_1.text1701710165914')}
									</Text>
									<Text
										id="textcamposim1701710165914"
										tag="sub"
										className="w-auto h-auto align-end position-relative" >
										{t('qmfsimulador_1.textcamposim1701710165914')}
									</Text>
								</Group>
							</Group>
							<Group
								id="groupsimgastosfijos1701713546392"
								direction="vertical"
								className="w-100 h-auto position-relative" >
								<Group
									id="grouplintitulo1701713546392"
									direction="auto"
									className="w-100 h-auto position-relative" >
									<Text
										id="texttitulo1701713546392"
										className="w-auto h-auto position-relative" >
										{t('qmfsimulador_1.texttitulo1701713546392')}
									</Text>
									<Text
										id="textcamposimggght1701713546392"
										src="@property.simgastosfijos"
										className="w-auto h-auto position-relative" >
										{t('qmfsimulador_1.textcamposimggght1701713546392')}
									</Text>
									<Text
										id="text1spacesdoble1701713546392"
										className="w-auto h-auto position-relative" >
										{t('qmfsimulador_1.text1spacesdoble1701713546392')}
									</Text>
								</Group>
								<Input.Range
									id="rangesimuladorcampo1701713546392"
									name="range"
									src="@property.simgastosfijos"
								max={10}
								min={0}
								step={0.25}
									className="w-100 h-auto position-relative mb-5px" />
								<Group
									id="grouplinlimites1701713546392"
									direction="horizontal"
									className="w-100 h-auto position-relative" >
									<Text
										id="textlimiteuno1701713546392"
										tag="sub"
										className="w-auto h-auto position-relative" >
										{t('qmfsimulador_1.textlimiteuno1701713546392')}
									</Text>
									<Text
										id="textbggrtrt1701713546392"
										className="w-fill h-auto position-relative" >
										{t('qmfsimulador_1.textbggrtrt1701713546392')}
									</Text>
									<Text
										id="textcamposimghjuu1701713546392"
										tag="sub"
										className="w-auto h-auto align-end position-relative" >
										{t('qmfsimulador_1.textcamposimghjuu1701713546392')}
									</Text>
								</Group>
							</Group>
						</Group>
						<Text
							id="espacios1701709125847"
							className="w-100 h-fill position-relative" >
							{t('qmfsimulador_1.espacios1701709125847')}
						</Text>
						<Group
							id="opcion1continuar1701709125847"
							direction="vertical"
							className="w-100 h-auto position-relative" >
							<Text
								id="concomprar1701709125847"
								onClick={concomprar1701709125847OnClick}
								className="w-100 h-auto border-radius-10px position-relative bg-accent py-8px mt-32px text-text-on-accent ff-auth_regular text-center" >
								{t('qmfsimulador_1.concomprar1701709125847')}
							</Text>
						</Group>
					</Group>
					<Group
						id="calculadora2sim1701710001951"
						if={{"what":"@property.calculadora","is":2}}
						direction="vertical"
						className="w-100 h-auto border-radius-15px position-relative bg-whitetrans p-15px" >
						<Group
							id="groupatrascerrar1701710001951"
							direction="horizontal"
							className="w-100 h-auto position-relative" >
							<Icon
								id="iconatras1op1701710001951"
								onClick={iconatras1op1701710001951OnClick}
								src="arrow-left-bold-circle-outline"
								className="w-auto h-auto align-end valign-center position-relative"
							/>
							<Text
								id="textgrdwb1op1701710001951"
								className="w-fill h-auto position-relative" >
								{t('qmfsimulador_1.textgrdwb1op1701710001951')}
							</Text>
							<Icon
								id="iconribotdd1op1701710001951"
								onClick={iconribotdd1op1701710001951OnClick}
								src="close-box-outline"
								className="w-auto h-auto align-end valign-center position-relative"
							/>
						</Group>
						<Group
							id="tituloinicio1701710001951"
							direction="vertical"
							className="w-100 h-auto position-relative" >
							<Text
								id="textotitulo1701710001951"
								tag="h1"
								className="w-auto h-auto align-start valign-center position-relative text-primary-light ff-sonobold" >
								{t('qmfsimulador_1.textotitulo1701710001951')}
							</Text>
						</Group>
						<Group
							id="pregustas1compra1701710001951"
							direction="vertical"
							className="w-100 h-auto position-relative" >
							<Group
								id="groupsimprecio1701710228003"
								direction="vertical"
								className="w-100 h-auto position-relative" >
								<Group
									id="grouplintitulo1701710228003"
									direction="auto"
									className="w-100 h-auto position-relative" >
									<Text
										id="texttitulo1701710228003"
										className="w-auto h-auto position-relative" >
										{t('qmfsimulador_1.texttitulo1701710228003')}
									</Text>
									<Text
										id="textcamposimdfdlkfd1701710228003"
										format={{"type":"currency"}}
										src="@property.simprecio"
										className="w-auto h-auto position-relative" >
										{t('qmfsimulador_1.textcamposimdfdlkfd1701710228003')}
									</Text>
								</Group>
								<Input.Range
									id="rangesimuladorcampo1701710228003"
									name="range"
									src="@property.simprecio"
								max={5000000}
								min={0}
								step={100000}
									className="w-100 h-auto position-relative mb-5px" />
								<Group
									id="grouplinlimites1701710228003"
									direction="horizontal"
									className="w-100 h-auto position-relative" >
									<Text
										id="textlimiteuno1701710228003"
										tag="sub"
										className="w-auto h-auto position-relative" >
										{t('qmfsimulador_1.textlimiteuno1701710228003')}
									</Text>
									<Text
										id="textcfdgd1701710228003"
										className="w-fill h-auto position-relative" >
										{t('qmfsimulador_1.textcfdgd1701710228003')}
									</Text>
									<Text
										id="textcamposimdfdfd1701710228003"
										tag="sub"
										className="w-auto h-auto align-end position-relative" >
										{t('qmfsimulador_1.textcamposimdfdfd1701710228003')}
									</Text>
								</Group>
							</Group>
							<Group
								id="groupsimpreciolocal1701710347213"
								direction="vertical"
								className="w-100 h-auto position-relative" >
								<Group
									id="grouplintitulo1701710347213"
									direction="auto"
									className="w-100 h-auto position-relative" >
									<Text
										id="texttitulo1701710347213"
										className="w-auto h-auto position-relative" >
										{t('qmfsimulador_1.texttitulo1701710347213')}
									</Text>
									<Text
										id="textcamposimhhhfgesc1701710347213"
										format={{"type":"currency"}}
										src="@property.simpreciolocal"
										className="w-auto h-auto position-relative" >
										{t('qmfsimulador_1.textcamposimhhhfgesc1701710347213')}
									</Text>
								</Group>
								<Input.Range
									id="rangesimuladorcampo1701710347213"
									name="range"
									src="@property.simpreciolocal"
								max={500000}
								min={0}
								step={10000}
									className="w-100 h-auto position-relative mb-5px" />
								<Group
									id="grouplinlimites1701710347213"
									direction="horizontal"
									className="w-100 h-auto position-relative" >
									<Text
										id="textlimiteuno1701710347213"
										tag="sub"
										className="w-auto h-auto position-relative" >
										{t('qmfsimulador_1.textlimiteuno1701710347213')}
									</Text>
									<Text
										id="texthhfgff1701710347213"
										className="w-fill h-auto position-relative" >
										{t('qmfsimulador_1.texthhfgff1701710347213')}
									</Text>
									<Text
										id="textcamposim1hhgfddff701710347213"
										tag="sub"
										className="w-auto h-auto align-end position-relative" >
										{t('qmfsimulador_1.textcamposim1hhgfddff701710347213')}
									</Text>
								</Group>
							</Group>
							<Group
								id="groupsgastosformalizacion1701710365359"
								direction="vertical"
								className="w-100 h-auto position-relative" >
								<Group
									id="grouplintitulo1701710365359"
									direction="horizontal"
									className="w-100 h-auto position-relative" >
									<Text
										id="texttitulo1701710365359"
										className="w-auto h-auto position-relative" >
										{t('qmfsimulador_1.texttitulo1701710365359')}
									</Text>
									<Text
										id="textcamposim1701710365359"
										format={{"type":"currency"}}
										src="@property.simgastosformalizacion"
										className="w-auto h-auto position-relative" >
										{t('qmfsimulador_1.textcamposim1701710365359')}
									</Text>
								</Group>
								<Input.Range
									id="rangesimuladorcampo1701710365359"
									name="range"
									src="@property.simgastosformalizacion"
								max={100000}
								min={0}
								step={500}
									className="w-100 h-auto position-relative mb-5px" />
								<Group
									id="grouplinlimites1701710365359"
									direction="horizontal"
									className="w-100 h-auto position-relative" >
									<Text
										id="textlimiteuno1701710365359"
										tag="sub"
										className="w-auto h-auto position-relative" >
										{t('qmfsimulador_1.textlimiteuno1701710365359')}
									</Text>
									<Text
										id="text1701710365359"
										className="w-fill h-auto position-relative" >
										{t('qmfsimulador_1.text1701710365359')}
									</Text>
									<Text
										id="textcamposim1701710365359"
										tag="sub"
										className="w-auto h-auto align-end position-relative" >
										{t('qmfsimulador_1.textcamposim1701710365359')}
									</Text>
								</Group>
							</Group>
						</Group>
						<Text
							id="espacios1701710001951"
							className="w-100 h-fill position-relative" >
							{t('qmfsimulador_1.espacios1701710001951')}
						</Text>
						<Group
							id="opcion1continuar1701710001951"
							direction="vertical"
							className="w-100 h-auto position-relative" >
							<Text
								id="concomprar1701710001951"
								onClick={concomprar1701710001951OnClick}
								className="w-100 h-auto border-radius-10px position-relative bg-accent py-8px mt-32px text-text-on-accent ff-auth_regular text-center" >
								{t('qmfsimulador_1.concomprar1701710001951')}
							</Text>
						</Group>
					</Group>
					<Group
						id="calculadora3sim1701710115440"
						if={{"what":"@property.calculadora","is":3}}
						direction="vertical"
						className="w-100 h-auto border-radius-15px position-relative bg-whitetrans p-15px" >
						<Group
							id="groupatrascerrar1701710115440"
							direction="horizontal"
							className="w-100 h-auto position-relative" >
							<Icon
								id="iconatras1op1701710115440"
								onClick={iconatras1op1701710115440OnClick}
								src="arrow-left-bold-circle-outline"
								className="w-auto h-auto align-end valign-center position-relative"
							/>
							<Text
								id="textgrdwb1op1701710115440"
								className="w-fill h-auto position-relative" >
								{t('qmfsimulador_1.textgrdwb1op1701710115440')}
							</Text>
							<Icon
								id="iconribotdd1op1701710115440"
								onClick={iconribotdd1op1701710115440OnClick}
								src="close-box-outline"
								className="w-auto h-auto align-end valign-center position-relative"
							/>
						</Group>
						<Group
							id="tituloinicio1701710115440"
							direction="vertical"
							className="w-100 h-auto position-relative" >
							<Text
								id="textotitulo1701710115440"
								tag="h1"
								className="w-auto h-auto align-start valign-center position-relative text-primary-light ff-sonobold" >
								{t('qmfsimulador_1.textotitulo1701710115440')}
							</Text>
						</Group>
						<Group
							id="pregustas1compra1701710115440"
							direction="vertical"
							className="w-100 h-auto position-relative" >
							<Group
								id="groupsimcapitalpropio1701710584391"
								direction="vertical"
								className="w-100 h-auto position-relative" >
								<Group
									id="grouplintitulo1701710584391"
									direction="auto"
									className="w-100 h-auto position-relative" >
									<Text
										id="texttitulo1701710584391"
										className="w-auto h-auto position-relative" >
										{t('qmfsimulador_1.texttitulo1701710584391')}
									</Text>
									<Text
										id="textcamposimhhhhh1701710584391"
										format={{"type":"currency"}}
										src="@property.simcapitalpropio"
										className="w-auto h-auto position-relative" >
										{t('qmfsimulador_1.textcamposimhhhhh1701710584391')}
									</Text>
								</Group>
								<Input.Range
									id="rangesimuladorcampo1701710584391"
									name="range"
									src="@property.simcapitalpropio"
								max={5000000}
								min={0}
								step={100000}
									className="w-100 h-auto position-relative mb-5px" />
								<Group
									id="grouplinlimites1701710584391"
									direction="horizontal"
									className="w-100 h-auto position-relative" >
									<Text
										id="textlimiteuno1701710584391"
										tag="sub"
										className="w-auto h-auto position-relative" >
										{t('qmfsimulador_1.textlimiteuno1701710584391')}
									</Text>
									<Text
										id="texthhhgg1701710584391"
										className="w-fill h-auto position-relative" >
										{t('qmfsimulador_1.texthhhgg1701710584391')}
									</Text>
									<Text
										id="textcamposimjjjjjj1701710584391"
										tag="sub"
										className="w-auto h-auto align-end position-relative" >
										{t('qmfsimulador_1.textcamposimjjjjjj1701710584391')}
									</Text>
								</Group>
							</Group>
							<Group
								id="groupsiminversor1701710439995"
								direction="vertical"
								className="w-100 h-auto position-relative" >
								<Group
									id="grouplintitulo1701710439995"
									direction="auto"
									className="w-100 h-auto position-relative" >
									<Text
										id="texttitulo1701710439995"
										className="w-auto h-auto position-relative" >
										{t('qmfsimulador_1.texttitulo1701710439995')}
									</Text>
									<Text
										id="textcamposimhht1701710439995"
										format={{"type":"currency"}}
										src="@property.siminversor"
										className="w-auto h-auto position-relative" >
										{t('qmfsimulador_1.textcamposimhht1701710439995')}
									</Text>
								</Group>
								<Input.Range
									id="rangesimuladosdwdrcampo1701710439995"
									name="range"
									src="@property.siminversor"
								max={5000000}
								min={0}
								step={10000}
									className="w-100 h-auto position-relative mb-5px" />
								<Group
									id="grouplinlimites1701710439995"
									direction="horizontal"
									className="w-100 h-auto position-relative" >
									<Text
										id="textlimiteuno1701710439995"
										tag="sub"
										className="w-auto h-auto position-relative" >
										{t('qmfsimulador_1.textlimiteuno1701710439995')}
									</Text>
									<Text
										id="textkias1701710439995"
										className="w-fill h-auto position-relative" >
										{t('qmfsimulador_1.textkias1701710439995')}
									</Text>
									<Text
										id="textcamposimqwetrt1701710439995"
										tag="sub"
										className="w-auto h-auto align-end position-relative" >
										{t('qmfsimulador_1.textcamposimqwetrt1701710439995')}
									</Text>
								</Group>
							</Group>
							<Group
								id="groupsimrentabilidadinversor1701710564678"
								direction="vertical"
								className="w-100 h-auto position-relative" >
								<Group
									id="grouplintitulo1701710564678"
									direction="auto"
									className="w-100 h-auto position-relative" >
									<Text
										id="texttitulo1701710564678"
										className="w-auto h-auto position-relative" >
										{t('qmfsimulador_1.texttitulo1701710564678')}
									</Text>
									<Text
										id="textcamposimfgfthjlp1701710564678"
										src="@property.simrentabilidadinversor"
										className="w-auto h-auto position-relative" >
										{t('qmfsimulador_1.textcamposimfgfthjlp1701710564678')}
									</Text>
									<Text
										id="textcamposporcineto1701710564678"
										className="w-auto h-auto position-relative" >
										{t('qmfsimulador_1.textcamposporcineto1701710564678')}
									</Text>
								</Group>
								<Input.Range
									id="rangesimuladorcampo1701710564678"
									name="range"
									src="@property.simrentabilidadinversor"
								max={10}
								min={0}
								step={0.25}
									className="w-100 h-auto position-relative mb-5px" />
								<Group
									id="grouplinlimites1701710564678"
									direction="horizontal"
									className="w-100 h-auto position-relative" >
									<Text
										id="textlimiteuno1701710564678"
										tag="sub"
										className="w-auto h-auto position-relative" >
										{t('qmfsimulador_1.textlimiteuno1701710564678')}
									</Text>
									<Text
										id="textfgynn1701710564678"
										className="w-fill h-auto position-relative" >
										{t('qmfsimulador_1.textfgynn1701710564678')}
									</Text>
									<Text
										id="textcamposimhyna1701710564678"
										tag="sub"
										className="w-auto h-auto align-end position-relative" >
										{t('qmfsimulador_1.textcamposimhyna1701710564678')}
									</Text>
								</Group>
							</Group>
						</Group>
						<Text
							id="espacios1701710115440"
							className="w-100 h-fill position-relative" >
							{t('qmfsimulador_1.espacios1701710115440')}
						</Text>
						<Group
							id="opcion1continuar1701710115440"
							direction="vertical"
							className="w-100 h-auto position-relative" >
							<Text
								id="concomprar1701710115440"
								onClick={concomprar1701710115440OnClick}
								className="w-100 h-auto border-radius-10px position-relative bg-accent py-8px mt-32px text-text-on-accent ff-auth_regular text-center" >
								{t('qmfsimulador_1.concomprar1701710115440')}
							</Text>
						</Group>
					</Group>
					<Group
						id="calculadora4sim1701710124190"
						if={{"what":"@property.calculadora","is":4}}
						direction="vertical"
						className="w-100 h-auto border-radius-15px position-relative bg-whitetrans p-15px" >
						<Group
							id="groupatrascerrar1701710124190"
							direction="horizontal"
							className="w-100 h-auto position-relative" >
							<Icon
								id="iconatras1op1701710124190"
								onClick={iconatras1op1701710124190OnClick}
								src="arrow-left-bold-circle-outline"
								className="w-auto h-auto align-end valign-center position-relative"
							/>
							<Text
								id="textgrdwb1op1701710124190"
								className="w-fill h-auto position-relative" >
								{t('qmfsimulador_1.textgrdwb1op1701710124190')}
							</Text>
							<Icon
								id="iconribotdd1op1701710124190"
								onClick={iconribotdd1op1701710124190OnClick}
								src="close-box-outline"
								className="w-auto h-auto align-end valign-center position-relative"
							/>
						</Group>
						<Group
							id="tituloinicio1701710124190"
							direction="vertical"
							className="w-100 h-auto position-relative" >
							<Text
								id="textotitulo1701710124190"
								tag="h1"
								className="w-auto h-auto align-start valign-center position-relative text-primary-light ff-sonobold" >
								{t('qmfsimulador_1.textotitulo1701710124190')}
							</Text>
						</Group>
						<Group
							id="pregustas1compra1701710124190"
							direction="vertical"
							className="w-100 h-auto position-relative" >
							<Group
								id="groupsiminteresprestamo1701710660794"
								direction="vertical"
								className="w-100 h-auto position-relative" >
								<Group
									id="grouplintitulo1701710660794"
									direction="auto"
									className="w-100 h-auto position-relative" >
									<Text
										id="texttitulo1701710660794"
										className="w-auto h-auto position-relative" >
										{t('qmfsimulador_1.texttitulo1701710660794')}
									</Text>
									<Text
										id="textcamposimhjumas1701710660794"
										src="@property.siminteresprestamo"
										className="w-auto h-auto position-relative" >
										{t('qmfsimulador_1.textcamposimhjumas1701710660794')}
									</Text>
									<Text
										id="textcamposporcineto1701710660794"
										className="w-auto h-auto position-relative" >
										{t('qmfsimulador_1.textcamposporcineto1701710660794')}
									</Text>
								</Group>
								<Input.Range
									id="rangesimuladorcampo1701710660794"
									name="range"
									src="@property.siminteresprestamo"
								max={10}
								min={0}
								step={0.25}
									className="w-100 h-auto position-relative mb-5px" />
								<Group
									id="grouplinlimites1701710660794"
									direction="horizontal"
									className="w-100 h-auto position-relative" >
									<Text
										id="textlimiteuno1701710660794"
										tag="sub"
										className="w-auto h-auto position-relative" >
										{t('qmfsimulador_1.textlimiteuno1701710660794')}
									</Text>
									<Text
										id="textnjmqw1701710660794"
										className="w-fill h-auto position-relative" >
										{t('qmfsimulador_1.textnjmqw1701710660794')}
									</Text>
									<Text
										id="textcamposimjtasdv1701710660794"
										tag="sub"
										className="w-auto h-auto align-end position-relative" >
										{t('qmfsimulador_1.textcamposimjtasdv1701710660794')}
									</Text>
								</Group>
							</Group>
							<Group
								id="groupsimduracionprestamo1701713422865"
								direction="vertical"
								className="w-100 h-auto position-relative" >
								<Group
									id="grouplintitulo1701713422865"
									direction="auto"
									className="w-100 h-auto position-relative" >
									<Text
										id="texttitulo1701713422865"
										className="w-auto h-auto position-relative" >
										{t('qmfsimulador_1.texttitulo1701713422865')}
									</Text>
									<Text
										id="textcamposidfdfdm1701713422865"
										format={{"type":"amount"}}
										src="@property.simduracionprestamo"
										className="w-auto h-auto position-relative" >
										{t('qmfsimulador_1.textcamposidfdfdm1701713422865')}
									</Text>
									<Text
										id="textcamposporcineto1701713422865"
										className="w-auto h-auto position-relative" >
										{t('qmfsimulador_1.textcamposporcineto1701713422865')}
									</Text>
								</Group>
								<Input.Range
									id="rangesimuladorcampo1701713422865"
									name="range"
									src="@property.simduracionprestamo"
								max={30}
								min={0}
								step={1}
									className="w-100 h-auto position-relative mb-5px" />
								<Group
									id="grouplinlimites1701713422865"
									direction="horizontal"
									className="w-100 h-auto position-relative" >
									<Text
										id="textlimiteuno1701713422865"
										tag="sub"
										className="w-auto h-auto position-relative" >
										{t('')}
									</Text>
									<Text
										id="textfhfhreq1701713422865"
										className="w-fill h-auto position-relative" >
										{t('qmfsimulador_1.textfhfhreq1701713422865')}
									</Text>
									<Text
										id="textcamposimljkjq1701713422865"
										tag="sub"
										className="w-auto h-auto align-end position-relative" >
										{t('qmfsimulador_1.textcamposimljkjq1701713422865')}
									</Text>
								</Group>
							</Group>
						</Group>
						<Text
							id="espacios1701710124190"
							className="w-100 h-fill position-relative" >
							{t('qmfsimulador_1.espacios1701710124190')}
						</Text>
						<Group
							id="opcion1continuar1701710124190"
							direction="vertical"
							className="w-100 h-auto position-relative" >
							<Text
								id="concomprar1701710124190"
								onClick={concomprar1701710124190OnClick}
								className="w-100 h-auto border-radius-10px position-relative bg-accent py-8px mt-32px text-text-on-accent ff-auth_regular text-center" >
								{t('qmfsimulador_1.concomprar1701710124190')}
							</Text>
						</Group>
					</Group>
					<Group
						id="calculadora5sim1701713686340"
						if={{"what":"@property.calculadora","is":5}}
						direction="vertical"
						className="w-100 h-auto border-radius-15px position-relative bg-whitetrans p-15px" >
						<Group
							id="groupatrascerrar1701713686340"
							direction="horizontal"
							className="w-100 h-auto position-relative" >
							<Icon
								id="iconatras1op1701713686340"
								onClick={iconatras1op1701713686340OnClick}
								src="arrow-left-bold-circle-outline"
								className="w-auto h-auto align-end valign-center position-relative"
							/>
							<Text
								id="textgrdwb1op1701713686340"
								className="w-fill h-auto position-relative" >
								{t('qmfsimulador_1.textgrdwb1op1701713686340')}
							</Text>
							<Icon
								id="iconribotdd1op1701713686340"
								onClick={iconribotdd1op1701713686340OnClick}
								src="close-box-outline"
								className="w-auto h-auto align-end valign-center position-relative"
							/>
						</Group>
						<Group
							id="tituloinicio1701713686340"
							direction="vertical"
							className="w-100 h-auto position-relative" >
							<Text
								id="textotitulo1701713686340"
								tag="h1"
								className="w-auto h-auto align-start valign-center position-relative text-primary-light ff-sonobold" >
								{t('qmfsimulador_1.textotitulo1701713686340')}
							</Text>
						</Group>
						<Group
							id="pregustas1compra1701713686340"
							direction="vertical"
							className="w-100 h-auto position-relative" >
							<Group
								id="groupcamposresulta1701713919309"
								direction="vertical"
								className="w-100 h-auto border-radius-30px valign-start position-relative bg-white p-30px" >
								<Group
									id="groupcolecionprinc1701713919309"
									direction="vertical"
									className="w-100 h-auto position-relative" >
									<Group
										id="groupcinversion1701713919309"
										direction="auto"
										className="w-100 h-auto position-relative" >
										<Text
											id="text1titinver1701713919309"
											className="w-auto h-auto position-relative" >
											{t('qmfsimulador_1.text1titinver1701713919309')}
										</Text>
										<Text
											id="textvarinve1701713919309"
											format={{"type":"currency"}}
											src="@firebase.firestore.simulador.(@cookie.keysimulador).inversiontotal"
											className="w-auto h-auto position-relative" >
											{t('qmfsimulador_1.textvarinve1701713919309')}
										</Text>
									</Group>
									<Group
										id="groupcapitalprestamo1701713919309"
										direction="auto"
										className="w-100 h-auto position-relative" >
										<Text
											id="texttitcpaterere1701713919309"
											className="w-auto h-auto position-relative" >
											{t('qmfsimulador_1.texttitcpaterere1701713919309')}
										</Text>
										<Text
											id="textvalcaperewrewr1701713919309"
											format={{"type":"currency"}}
											src="@firebase.firestore.simulador.(@cookie.keysimulador).capitalprestamo"
											className="w-auto h-auto position-relative" >
											{t('qmfsimulador_1.textvalcaperewrewr1701713919309')}
										</Text>
									</Group>
									<Group
										id="groupcouotadelprestamo1701713919309"
										direction="auto"
										className="w-100 h-auto position-relative" >
										<Text
											id="texttitrerecoutasim1701713919309"
											className="w-auto h-auto position-relative" >
											{t('qmfsimulador_1.texttitrerecoutasim1701713919309')}
										</Text>
										<Text
											id="textvaloerewrewrcoutasim1701713919309"
											format={{"type":"currency"}}
											src="@firebase.firestore.simulador.(@cookie.keysimulador).cuotaprestamo"
											className="w-auto h-auto position-relative" >
											{t('qmfsimulador_1.textvaloerewrewrcoutasim1701713919309')}
										</Text>
									</Group>
									<Group
										id="groupcouotadelprestamoanaual1701713919309"
										direction="auto"
										className="w-100 h-auto position-relative" >
										<Text
											id="texttitcoutasim1701713919309"
											className="w-auto h-auto position-relative" >
											{t('qmfsimulador_1.texttitcoutasim1701713919309')}
										</Text>
										<Text
											id="textvalorcoutasim1701713919309"
											format={{"type":"currency"}}
											src="@firebase.firestore.simulador.(@cookie.keysimulador).cuotaanualprestamo"
											className="w-auto h-auto position-relative" >
											{t('qmfsimulador_1.textvalorcoutasim1701713919309')}
										</Text>
									</Group>
									<Group
										id="groupbeneinversor1701713919309"
										direction="auto"
										className="w-100 h-auto position-relative" >
										<Text
											id="texttitbenfsim1701713919309"
											className="w-auto h-auto position-relative" >
											{t('qmfsimulador_1.texttitbenfsim1701713919309')}
										</Text>
										<Text
											id="textvalortitsm1701713919309"
											format={{"type":"currency"}}
											src="@firebase.firestore.simulador.(@cookie.keysimulador).beneficioinversoranual"
											className="w-auto h-auto position-relative" >
											{t('qmfsimulador_1.textvalortitsm1701713919309')}
										</Text>
									</Group>
								</Group>
								<Group
									id="groupanualaidades1701713919309"
									if={{"what":"@firebase.firestore.simulador.(@cookie.keysimulador).lottie","is":false}}
									direction="vertical"
									className="w-100 h-auto position-relative" >
									<Input.Select
										id="select1701713919309"
										name="select"
										src="@property.anualidad"
										className="w-auto h-auto position-relative my-15px" >
										<option value={1}>{t('qmfsimulador_1.select1701713919309.opt0')}</option>
										<option value={2}>{t('qmfsimulador_1.select1701713919309.opt1')}</option>
										<option value={3}>{t('qmfsimulador_1.select1701713919309.opt2')}</option>
										<option value={4}>{t('qmfsimulador_1.select1701713919309.opt3')}</option>
										<option value={5}>{t('qmfsimulador_1.select1701713919309.opt4')}</option>
										<option value={6}>{t('qmfsimulador_1.select1701713919309.opt5')}</option>
										<option value={7}>{t('qmfsimulador_1.select1701713919309.opt6')}</option>
										<option value={8}>{t('qmfsimulador_1.select1701713919309.opt7')}</option>
										<option value={9}>{t('qmfsimulador_1.select1701713919309.opt8')}</option>
										<option value={10}>{t('qmfsimulador_1.select1701713919309.opt9')}</option>
										<option value={11}>{t('qmfsimulador_1.select1701713919309.opt10')}</option>
										<option value={12}>{t('qmfsimulador_1.select1701713919309.opt11')}</option>
										<option value={13}>{t('qmfsimulador_1.select1701713919309.opt12')}</option>
										<option value={14}>{t('qmfsimulador_1.select1701713919309.opt13')}</option>
										<option value={15}>{t('qmfsimulador_1.select1701713919309.opt14')}</option>
										<option value={16}>{t('qmfsimulador_1.select1701713919309.opt15')}</option>
										<option value={17}>{t('qmfsimulador_1.select1701713919309.opt16')}</option>
										<option value={18}>{t('qmfsimulador_1.select1701713919309.opt17')}</option>
										<option value={19}>{t('qmfsimulador_1.select1701713919309.opt18')}</option>
										<option value={20}>{t('qmfsimulador_1.select1701713919309.opt19')}</option>
										<option value={21}>{t('qmfsimulador_1.select1701713919309.opt20')}</option>
										<option value={22}>{t('qmfsimulador_1.select1701713919309.opt21')}</option>
										<option value={23}>{t('qmfsimulador_1.select1701713919309.opt22')}</option>
										<option value={24}>{t('qmfsimulador_1.select1701713919309.opt23')}</option>
										<option value={27}>{t('qmfsimulador_1.select1701713919309.opt24')}</option>
										<option value={28}>{t('qmfsimulador_1.select1701713919309.opt25')}</option>
										<option value={29}>{t('qmfsimulador_1.select1701713919309.opt26')}</option>
										<option value={30}>{t('qmfsimulador_1.select1701713919309.opt27')}</option>
									</Input.Select>
									<List
										id="list1701713919309"
										src="@firebase.firestore.simulador.(@cookie.keysimulador).anualidades"
										filter={{"what":"anualidad","is":"@property.anualidad"}}
										className="w-100 h-auto position-relative" >
										<Group
											id="group1701713919309"
											direction="vertical"
											className="w-100 h-auto position-relative" >
											<Group
												id="groupfacturacionanual1701713919309"
												direction="auto"
												className="w-100 h-auto position-relative" >
												<Text
													id="texttitbendfdffsim1701713919309"
													className="w-auto h-auto position-relative" >
													{t('qmfsimulador_1.texttitbendfdffsim1701713919309')}
												</Text>
												<Text
													id="textvalorfdfdtitsm1701713919309"
													format={{"type":"currency"}}
													src="facturacion"
													className="w-auto h-auto position-relative" >
													{t('qmfsimulador_1.textvalorfdfdtitsm1701713919309')}
												</Text>
											</Group>
											<Group
												id="groupgastosfic1701713919309"
												direction="auto"
												className="w-100 h-auto position-relative" >
												<Text
													id="texttitbenfsidddddm1701713919309"
													className="w-auto h-auto position-relative" >
													{t('qmfsimulador_1.texttitbenfsidddddm1701713919309')}
												</Text>
												<Text
													id="textvalorsfddfstitsm1701713919309"
													format={{"type":"currency"}}
													src="gastosfijos"
													className="w-auto h-auto position-relative" >
													{t('qmfsimulador_1.textvalorsfddfstitsm1701713919309')}
												</Text>
											</Group>
											<Group
												id="groupbeneficionetoanaul1701713919309"
												direction="auto"
												className="w-100 h-auto position-relative" >
												<Text
													id="texttitbenfdfsdfdsim1701713919309"
													className="w-auto h-auto position-relative" >
													{t('qmfsimulador_1.texttitbenfdfsdfdsim1701713919309')}
												</Text>
												<Text
													id="textvalorxzfsdtfdsfdsitsm1701713919309"
													format={{"type":"currency"}}
													src="beneficioneto"
													className="w-auto h-auto position-relative" >
													{t('qmfsimulador_1.textvalorxzfsdtfdsfdsitsm1701713919309')}
												</Text>
											</Group>
											<Group
												id="groupbeneficionacumulado1701713919309"
												direction="auto"
												className="w-100 h-auto position-relative" >
												<Text
													id="texttitbeddasdnfsim1701713919309"
													className="w-auto h-auto position-relative text-primary-text" >
													{t('qmfsimulador_1.texttitbeddasdnfsim1701713919309')}
												</Text>
												<Text
													id="textvalortierewrewtsm1701713919309"
													format={{"type":"currency"}}
													src="beneficionetoacumulado"
													className="w-auto h-auto position-relative" >
													{t('qmfsimulador_1.textvalortierewrewtsm1701713919309')}
												</Text>
											</Group>
											<Group
												id="groupcapitapendi1701713919309"
												visible={false}
												direction="auto"
												className="w-100 h-auto position-relative" >
												<Text
													id="texttitbedsdsdfsim1701713919309"
													tag="h3"
													className="w-auto h-auto position-relative" >
													{t('qmfsimulador_1.texttitbedsdsdfsim1701713919309')}
												</Text>
												<Text
													id="textvalortidferfedtsm1701713919309"
													format={{"type":"currency"}}
													tag="h3"
													src="capitalpendiente"
													className="w-auto h-auto position-relative text-primary-text" >
													{t('qmfsimulador_1.textvalortidferfedtsm1701713919309')}
												</Text>
											</Group>
										</Group>
									</List>
								</Group>
								<Lottie
									id="lottieesperandocompra1701713919309"
									if={{"what":"@firebase.firestore.simulador.(@cookie.keysimulador).lottie","is":true}}
									src={puntos}
									className="w-50px min-h-50px h-50px align-center valign-center position-absolute" />
							</Group>
						</Group>
						<Text
							id="espacios1701713686340"
							className="w-100 h-fill position-relative" >
							{t('qmfsimulador_1.espacios1701713686340')}
						</Text>
					</Group>
				</Group>
			</Group>
      </Qmf_wradministradoresWrapper>
    </>
  );
};
