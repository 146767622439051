import React from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useFunctions, useRefs } from '../hooks';
import { FullscreenWrapper } from '../wrappers';

import { useLocation } from 'react-router-dom';
import { getLocationParams } from '../util';

// Custom imports:
import { Group, Text, Accordion, Image, Icon } from '../components';
import { useEffect } from 'react';
import perfil from '../assets/iconos/assest/perfil.png';
import { useTranslation } from 'react-i18next';

export const Qmf_perfilView = () => {
  const { run } = useFunctions();
  const { search, pathname } = useLocation();


  // Custom exports:
	const { t } = useTranslation();

  // Custom functions:
	const qmf_perfilParams = () => {
		run( getLocationParams( search, pathname ) )
	}
	useEffect( qmf_perfilParams, [])
	const qmf_perfilOnLoaded = async (options) => {
		run([{"id":"goto1705426329242","function":"goto","view":"qmfhome","if":{"what":"@firebase.user.id","is":null}}], options)
	}
	useEffect( qmf_perfilOnLoaded, [])
	const texvofdflvergfgft1705426329242OnClick = async (options) => {
		run([{"id":"back1705426329242","function":"back"}], options)
	}
	const icon1705830121489OnClick = async (options) => {
		run([{"function":"set","what":"@property.authMessage","value":"forgot"},{"function":"popup","dialog":"auth_dialog_1"}], options)
	}
	const icon1705512608866OnClick = async (options) => {
		run([{"id":"set1705516378464","function":"set","what":"@property.nombrecampo","value":"Poblaci\u00f3n"},{"id":"set1705516378464","function":"set","what":"@property.campo","value":"poblacion"},{"id":"popup1705516378464","function":"popup","dialog":"qmf_editar_campo"}], options)
	}
	const icon1705512600368OnClick = async (options) => {
		run([{"id":"set1705516316379","function":"set","what":"@property.nombrecampo","value":"C\u00f3digo Postal"},{"id":"set1705516316379","function":"set","what":"@property.campo","value":"cpostal"},{"id":"popup1705516316379","function":"popup","dialog":"qmf_editar_campo"}], options)
	}
	const icon1705511982693OnClick = async (options) => {
		run([{"id":"set1705513169055","function":"set","what":"@property.nombrecampo","value":"Nombre"},{"id":"set1705513381988","function":"set","what":"@property.campo","value":"nombre"},{"id":"popup1705513114253","function":"popup","dialog":"qmf_editar_campo"}], options)
	}
	const icon1705519069651OnClick = async (options) => {
		run([{"id":"file1705519499493","function":"file","into":"@property.tempFile","onsuccess":[{"id":"set1705519679654","function":"set","what":"@firebase.storage.usuarios\/(@firebase.user.id)\/foto.png","value":"@property.tempFile"}],"type":".jpg"}], options)
	}

  return (
    <>
      <Helmet>
				<title>{t('qmf_perfil.title')}</title>
				<meta name="description" content={t('qmf_perfil.title')} data-react-helmet="true" />
      </Helmet>
      <FullscreenWrapper>
          
			<Group
				id="groupprinciofertas1705426329242"
				direction="vertical"
				className="w-100 min-h-100 fg-100 position-relative" >
				<Group
					id="groupkkjjhjh1705426329242"
					direction="vertical"
					className="w-100 h-fill position-relative scrollable" >
					<Group
						id="signio5566ounFortrerm1705426329242"
						direction="vertical"
						className="w-100 h-auto border-radius-15px align-center position-relative p-32px mt-24px" >
						<Text
							id="te12lli33x888t1705426329241"
							tag="h1"
							className="w-100 h-auto position-relative text-primary-light ff-gantaribold" >
							{t('qmf_perfil.te12lli33x888t1705426329241')}
						</Text>
						<Text
							id="treiollooyut1705426329241"
							className="w-100 min-h-3px h-3px position-relative bg-primary-light" >
							{t('qmf_perfil.treiollooyut1705426329241')}
						</Text>
						<Accordion defaultActiveKey="0"
							id="accordion1705426329242"
							className="w-auto h-auto position-relative" >
						</Accordion>
					</Group>
					<Group
						id="gr12ou45544p1705426329242"
						direction="vertical"
						className="w-100 h-fill align-center position-relative p-30px scrollable" >
						<Group
							id="grouppubicacion1705519069651"
							direction="auto"
							className="w-100 h-auto position-relative p-10px" >
							<Group
								id="groutitubicacion1705519069651"
								direction="vertical"
								className="w-100 h-auto position-relative" >
								<Text
									id="titubicacion1705519069651"
									tag="h2"
									className="w-auto h-auto position-relative text-primary-light ff-gantaribold" >
									{t('qmf_perfil.titubicacion1705519069651')}
								</Text>
								<Text
									id="linubicacion1705519069651"
									className="w-100 min-h-3px h-3px position-relative bg-primary-light" >
									{t('qmf_perfil.linubicacion1705519069651')}
								</Text>
							</Group>
							<Group
								id="grupodatosubicacion1705519069651"
								direction="horizontal"
								className="w-auto h-auto valign-center position-relative p-10px" >
								<Image
									id="image1705519136634"
									fallback={perfil}
									src="@firebase.storage.usuarios/(@firebase.user.id)/foto.png"
									className="max-w-200px w-40 max-h-200px min-h-40 h-40 border-radius-100px position-relative bg-accent contain" />
								<Icon
									id="icon1705519069651"
									onClick={icon1705519069651OnClick}
									src="pencil"
									size={2}
									color="var(--accent)"
									className="w-auto h-auto valign-end position-relative"
								/>
							</Group>
						</Group>
						<Group
							id="grouppubicacion1705426329242"
							direction="auto"
							className="w-100 h-auto position-relative p-10px" >
							<Group
								id="groutitubicacion1705426329242"
								direction="vertical"
								className="w-100 h-auto position-relative" >
								<Text
									id="titubicacion1705426329242"
									tag="h2"
									className="w-auto h-auto position-relative text-primary-light ff-gantaribold" >
									{t('qmf_perfil.titubicacion1705426329242')}
								</Text>
								<Text
									id="linubicacion1705426329242"
									className="w-100 min-h-3px h-3px position-relative bg-primary-light" >
									{t('qmf_perfil.linubicacion1705426329242')}
								</Text>
							</Group>
							<Group
								id="grupodatosubicacion1705426329242"
								direction="horizontal"
								className="w-auto h-auto valign-center position-relative" >
								<Text
									id="textcampo1705426700382"
									tag="h2"
									src="@firebase.firestore.usuarios.(@firebase.user.id).nombre"
									className="w-auto h-auto valign-center position-relative" >
									{t('qmf_perfil.textcampo1705426700382')}
								</Text>
								<Icon
									id="icon1705511982693"
									onClick={icon1705511982693OnClick}
									src="pencil"
									size={2}
									color="var(--accent)"
									className="w-auto h-auto valign-center position-relative"
								/>
							</Group>
						</Group>
						<Group
							id="grouptipologia1705426329242"
							direction="auto"
							className="w-100 h-auto position-relative p-10px" >
							<Group
								id="groutittipologia1705426329242"
								direction="vertical"
								className="w-100 h-auto position-relative" >
								<Text
									id="tittipologia1705426329242"
									tag="h2"
									className="w-100 h-auto position-relative text-primary-light ff-gantaribold" >
									{t('qmf_perfil.tittipologia1705426329242')}
								</Text>
								<Text
									id="lintipologia1705426329242"
									className="w-100 min-h-3px h-3px position-relative bg-primary-light" >
									{t('qmf_perfil.lintipologia1705426329242')}
								</Text>
							</Group>
							<Group
								id="grupodatostipoologia1705426329242"
								direction="auto"
								className="w-100 h-auto valign-center position-relative" >
								<Group
									id="grupodatosubicacion1705426813511"
									direction="auto"
									className="w-auto h-auto valign-center position-relative p-10px" >
									<Text
										id="text1705426813511"
										tag="h2"
										src="@firebase.firestore.usuarios.(@firebase.user.id).email"
										className="w-auto h-auto position-relative" >
										{t('qmf_perfil.text1705426813511')}
									</Text>
								</Group>
							</Group>
						</Group>
						<Group
							id="groupeconomicos1705426329242"
							direction="auto"
							className="w-100 h-auto position-relative p-10px" >
							<Group
								id="grouptiteconomicos1705426329242"
								direction="vertical"
								className="w-100 h-auto position-relative" >
								<Text
									id="te1233x888t1705426329242"
									tag="h2"
									className="w-100 h-auto position-relative text-primary-light ff-gantaribold" >
									{t('qmf_perfil.te1233x888t1705426329242')}
								</Text>
								<Text
									id="treiooyut1705426329242"
									className="w-100 min-h-3px h-3px position-relative bg-primary-light" >
									{t('qmf_perfil.treiooyut1705426329242')}
								</Text>
							</Group>
							<Group
								id="grupoodtoseconomicos1705426329242"
								direction="auto"
								className="w-100 h-auto valign-center position-relative p-15px" >
								<Text
									id="textcampo1705426950513"
									tag="h2"
									src="@firebase.firestore.usuarios.(@firebase.user.id).cpostal"
									className="w-auto h-auto valign-center position-relative" >
									{t('qmf_perfil.textcampo1705426950513')}
								</Text>
								<Icon
									id="icon1705512600368"
									onClick={icon1705512600368OnClick}
									src="pencil"
									size={2}
									color="var(--accent)"
									className="w-auto h-auto valign-center position-relative"
								/>
							</Group>
						</Group>
						<Group
							id="grouppmotivo1705426329242"
							direction="auto"
							className="w-100 h-auto position-relative p-10px" >
							<Group
								id="grouhhup1705426329242"
								direction="vertical"
								className="w-100 h-auto position-relative" >
								<Text
									id="temotivo1705426329242"
									tag="h2"
									className="w-100 h-auto position-relative text-primary-light ff-gantaribold" >
									{t('qmf_perfil.temotivo1705426329242')}
								</Text>
								<Text
									id="linmotivo1705426329242"
									className="w-100 min-h-3px h-3px position-relative bg-primary-light" >
									{t('qmf_perfil.linmotivo1705426329242')}
								</Text>
							</Group>
							<Group
								id="grupoodstmotivo1705426329242"
								direction="auto"
								className="w-100 h-auto valign-center position-relative p-10px" >
								<Text
									id="textcampo1705427062092"
									tag="h2"
									src="@firebase.firestore.usuarios.(@firebase.user.id).poblacion"
									className="w-auto h-auto valign-center position-relative" >
									{t('qmf_perfil.textcampo1705427062092')}
								</Text>
								<Icon
									id="icon1705512608866"
									onClick={icon1705512608866OnClick}
									src="pencil"
									size={2}
									color="var(--accent)"
									className="w-auto h-auto valign-center position-relative"
								/>
							</Group>
						</Group>
						<Group
							id="grouppmotivo1705830121489"
							direction="auto"
							className="w-100 h-auto position-relative p-10px" >
							<Group
								id="grouhhup1705830121488"
								direction="vertical"
								className="w-100 h-auto position-relative" >
								<Text
									id="temotivo1705830121488"
									tag="h2"
									className="w-100 h-auto position-relative text-primary-light ff-gantaribold" >
									{t('qmf_perfil.temotivo1705830121488')}
								</Text>
								<Text
									id="linmotivo1705830121488"
									className="w-100 min-h-3px h-3px position-relative bg-primary-light" >
									{t('qmf_perfil.linmotivo1705830121488')}
								</Text>
							</Group>
							<Group
								id="grupoodstmotivo1705830121489"
								direction="auto"
								className="w-100 h-auto valign-center position-relative p-10px" >
								<Icon
									id="icon1705830121489"
									onClick={icon1705830121489OnClick}
									src="pencil"
									size={2}
									color="var(--accent)"
									className="w-auto h-auto valign-center position-relative"
								/>
							</Group>
						</Group>
					</Group>
				</Group>
				<Group
					id="groufffp1705426329242"
					direction="auto"
					className="w-auto h-auto align-end position-relative" >
					<Text
						id="texvofdflvergfgft1705426329242"
						onClick={texvofdflvergfgft1705426329242OnClick}
						tag="mark"
						className="w-auto h-auto border-radius-20px align-end valign-end position-relative bg-primary-light-to-primary-dark p-15px me-30px mt-15px mb-20px text-text-on-accent text-center" >
						{t('qmf_perfil.texvofdflvergfgft1705426329242')}
					</Text>
				</Group>
			</Group>
      </FullscreenWrapper>
    </>
  );
};
