import React from 'react';
import ReactLottie from 'react-lottie';
import Component from './Component';

export const Lottie = ({
  autoplay = true,
  className = '',
  src: animationData,
  loop = true,
  speed = 1,
  ...props
}) => {
  const options = {
    animationData,
    autoplay,
    loop,
    speed,
  };
  return (
    <Component {...props} className={`lottie ${className}`.trim()}>
      <ReactLottie options={options} speed={speed} />
    </Component>
  );
};

Lottie.displayName = 'Lottie';
export default Lottie;
