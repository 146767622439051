import React from 'react';
import { useDialogs } from '../hooks';
import { useFunctions } from '../hooks';

// Custom imports:
import { Modal, Text } from '../components';
import { useTranslation } from 'react-i18next';

const Template_dialogDialog = () => {
    const { run } = useFunctions();
    // Custom exports:
	const { t } = useTranslation();

    // Custom functions:

  return (
    
    
			<Modal id="template_dialog" centered>
					<Modal.Body>
						<Text
							id="templateTextError"
							if={{"what":"@property.action","is":"template_error"}}
							className="w-100 h-auto position-relative" >
							{t('template_dialog.templateTextError')}
						</Text>
						<Text
							id="templateTextSuccess"
							if={{"what":"@property.action","is":"template_success"}}
							className="w-100 h-auto position-relative" >
							{t('template_dialog.templateTextSuccess')}
						</Text>
					</Modal.Body>
			</Modal>
    
  );
};

export default Template_dialogDialog;
