import React from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useFunctions, useRefs } from '../hooks';
import { Qmf_wradministradoresWrapper } from '../wrappers';

import { useLocation } from 'react-router-dom';
import { getLocationParams } from '../util';

// Custom imports:
import { Group, Text, Input, List, Lottie, Chart } from '../components';
import { useEffect } from 'react';
import puntos from '../assets/puntos.json';
import { useTranslation } from 'react-i18next';

export const QmfsimuladorView = () => {
  const { run } = useFunctions();
  const { search, pathname } = useLocation();


  // Custom exports:
	const { t } = useTranslation();

  // Custom functions:
	const qmfsimuladorParams = () => {
		run( getLocationParams( search, pathname ) )
	}
	useEffect( qmfsimuladorParams, [])
	const qmfsimuladorOnLoaded = async (options) => {
		run([{"id":"set1701501955349","function":"set","what":"@property.ini","value":"@app.timestamp"},{"id":"set1701502047438","function":"set","what":"@cookie.keysimulador","value":"qmfsim(@property.ini)"},{"id":"add1701501640605","function":"add","what":{"facturacion":500000,"gastosfijos":6,"precio":700000,"preciolocal":0,"gastosformalizacion":30000,"inversor":0,"capitalpropio":0,"duracionprestamo":20,"interesprestamo":5,"rentabilidadinversor":6,"margenbruto":30,"incrementofacturacion":0},"to":"@firebase.firestore.simulador","key":"@cookie.keysimulador"},{"id":"set1701687428903","function":"set","what":"@property.anualidad","value":0}], options)
	}
	useEffect( qmfsimuladorOnLoaded, [])
	const text1701628142480OnClick = async (options) => {
		run([{"id":"set1701628296249","function":"set","what":"@firebase.firestore.simulador.(@cookie.keysimulador).calcular","value":true}], options)
	}

  return (
    <>
      <Helmet>
				<title>{t('qmfsimulador.title')}</title>
      </Helmet>
      <Qmf_wradministradoresWrapper>
          
			<Group
				id="group1701507150672"
				direction="auto"
				className="w-100 h-auto valign-start position-relative bg-accent p-10px" >
				<Group
					id="group1701643735584"
					direction="vertical"
					className="w-100 h-auto valign-end position-relative bg-accent p-10px" >
					<Group
						id="groupcamposvariablessim"
						direction="vertical"
						className="w-100 h-auto border-radius-30px position-relative bg-white p-30px" >
						<Group
							id="groupsimfacturacion"
							direction="vertical"
							className="w-100 h-auto position-relative" >
							<Group
								id="grouplintitulo"
								direction="auto"
								className="w-100 h-auto position-relative" >
								<Text
									id="texttitulo"
									className="w-auto h-auto position-relative" >
									{t('qmfsimulador.texttitulo')}
								</Text>
								<Text
									id="textcamposim"
									format={{"type":"currency"}}
									src="@firebase.firestore.simulador.(@cookie.keysimulador).facturacion"
									className="w-auto h-auto position-relative" >
									{t('qmfsimulador.textcamposim')}
								</Text>
							</Group>
							<Input.Range
								id="rangesimuladorcampo"
								name="range"
								src="@firebase.firestore.simulador.(@cookie.keysimulador).facturacion"
							max={5000000}
							min={0}
							step={100000}
								className="w-100 h-auto position-relative mb-5px" />
							<Group
								id="grouplinlimites"
								direction="horizontal"
								className="w-100 h-auto position-relative" >
								<Text
									id="textlimiteuno"
									tag="sub"
									className="w-auto h-auto position-relative" >
									{t('qmfsimulador.textlimiteuno')}
								</Text>
								<Text
									id="text1701509893641"
									className="w-fill h-auto position-relative" >
									{t('qmfsimulador.text1701509893641')}
								</Text>
								<Text
									id="textcamposim1701509710441"
									tag="sub"
									className="w-auto h-auto align-end position-relative" >
									{t('qmfsimulador.textcamposim1701509710441')}
								</Text>
							</Group>
						</Group>
						<Group
							id="groupsincrementofacturacionanual"
							direction="vertical"
							className="w-100 h-auto position-relative" >
							<Group
								id="grouplintitulo1701514773389"
								direction="auto"
								className="w-100 h-auto position-relative" >
								<Text
									id="texttitulo1701514773389"
									className="w-auto h-auto position-relative" >
									{t('qmfsimulador.texttitulo1701514773389')}
								</Text>
								<Text
									id="textcamposim1701514773389"
									src="@firebase.firestore.simulador.(@cookie.keysimulador).incrementofacturacion"
									className="w-auto h-auto position-relative" >
									{t('qmfsimulador.textcamposim1701514773389')}
								</Text>
								<Text
									id="textcamposporcineto"
									className="w-auto h-auto position-relative" >
									{t('qmfsimulador.textcamposporcineto')}
								</Text>
							</Group>
							<Input.Range
								id="rangesimuladorcampo1701514773389"
								name="range"
								src="@firebase.firestore.simulador.(@cookie.keysimulador).incrementofacturacion"
							max={10}
							min={0}
							step={0.25}
								className="w-100 h-auto position-relative mb-5px" />
							<Group
								id="grouplinlimites1701514773389"
								direction="horizontal"
								className="w-100 h-auto position-relative" >
								<Text
									id="textlimiteuno1701514773389"
									tag="sub"
									className="w-auto h-auto position-relative" >
									{t('qmfsimulador.textlimiteuno1701514773389')}
								</Text>
								<Text
									id="text1701514773389"
									className="w-fill h-auto position-relative" >
									{t('qmfsimulador.text1701514773389')}
								</Text>
								<Text
									id="textcamposim1701514773389"
									tag="sub"
									className="w-auto h-auto align-end position-relative" >
									{t('qmfsimulador.textcamposim1701514773389')}
								</Text>
							</Group>
						</Group>
						<Group
							id="groupsimmargenbruto"
							direction="vertical"
							className="w-100 h-auto position-relative" >
							<Group
								id="grouplintitulo1701510014073"
								direction="auto"
								className="w-100 h-auto position-relative" >
								<Text
									id="texttitulo1701510014073"
									className="w-auto h-auto position-relative" >
									{t('qmfsimulador.texttitulo1701510014073')}
								</Text>
								<Text
									id="textcamposim1701510014073"
									src="@firebase.firestore.simulador.(@cookie.keysimulador).margenbruto"
									className="w-auto h-auto position-relative" >
									{t('qmfsimulador.textcamposim1701510014073')}
								</Text>
								<Text
									id="textcamposporcineto1701531265406"
									className="w-auto h-auto position-relative" >
									{t('qmfsimulador.textcamposporcineto1701531265406')}
								</Text>
							</Group>
							<Input.Range
								id="rangesimuladorcampo1701510014073"
								name="range"
								src="@firebase.firestore.simulador.(@cookie.keysimulador).margenbruto"
							max={60}
							min={0}
							step={5}
								className="w-100 h-auto position-relative mb-5px" />
							<Group
								id="grouplinlimites1701510014073"
								direction="horizontal"
								className="w-100 h-auto position-relative" >
								<Text
									id="textlimiteuno1701510014073"
									tag="sub"
									className="w-auto h-auto position-relative" >
									{t('qmfsimulador.textlimiteuno1701510014073')}
								</Text>
								<Text
									id="text1701510014073"
									className="w-fill h-auto position-relative" >
									{t('qmfsimulador.text1701510014073')}
								</Text>
								<Text
									id="textcamposim1701510014073"
									tag="sub"
									className="w-auto h-auto align-end position-relative" >
									{t('qmfsimulador.textcamposim1701510014073')}
								</Text>
							</Group>
						</Group>
						<Group
							id="groupsimgastosfijos"
							direction="vertical"
							className="w-100 h-auto position-relative" >
							<Group
								id="grouplintitulo1701514543877"
								direction="auto"
								className="w-100 h-auto position-relative" >
								<Text
									id="texttitulo1701514543877"
									className="w-auto h-auto position-relative" >
									{t('qmfsimulador.texttitulo1701514543877')}
								</Text>
								<Text
									id="textcamposim1701514543877"
									format={{"type":"amount","decimals":2}}
									src="@firebase.firestore.simulador.(@cookie.keysimulador).gastosfijos"
									className="w-auto h-auto position-relative" >
									{t('qmfsimulador.textcamposim1701514543877')}
								</Text>
								<Text
									id="text1spacesdoble"
									className="w-auto h-auto position-relative" >
									{t('qmfsimulador.text1spacesdoble')}
								</Text>
							</Group>
							<Input.Range
								id="rangesimuladorcampo1701514543877"
								name="range"
								src="@firebase.firestore.simulador.(@cookie.keysimulador).gastosfijos"
							max={10}
							min={0}
							step={0.25}
								className="w-100 h-auto position-relative mb-5px" />
							<Group
								id="grouplinlimites1701514543877"
								direction="horizontal"
								className="w-100 h-auto position-relative" >
								<Text
									id="textlimiteuno1701514543877"
									tag="sub"
									className="w-auto h-auto position-relative" >
									{t('qmfsimulador.textlimiteuno1701514543877')}
								</Text>
								<Text
									id="text1701514543877"
									className="w-fill h-auto position-relative" >
									{t('qmfsimulador.text1701514543877')}
								</Text>
								<Text
									id="textcamposim1701514543877"
									tag="sub"
									className="w-auto h-auto align-end position-relative" >
									{t('qmfsimulador.textcamposim1701514543877')}
								</Text>
							</Group>
						</Group>
						<Group
							id="groupsimprecio"
							direction="vertical"
							className="w-100 h-auto position-relative" >
							<Group
								id="grouplintitulo1701510789181"
								direction="auto"
								className="w-100 h-auto position-relative" >
								<Text
									id="texttitulo1701510789181"
									className="w-auto h-auto position-relative" >
									{t('qmfsimulador.texttitulo1701510789181')}
								</Text>
								<Text
									id="textcamposim1701510789181"
									format={{"type":"currency"}}
									src="@firebase.firestore.simulador.(@cookie.keysimulador).precio"
									className="w-auto h-auto position-relative" >
									{t('qmfsimulador.textcamposim1701510789181')}
								</Text>
							</Group>
							<Input.Range
								id="rangesimuladorcampo1701510789181"
								name="range"
								src="@firebase.firestore.simulador.(@cookie.keysimulador).precio"
							max={5000000}
							min={0}
							step={100000}
								className="w-100 h-auto position-relative mb-5px" />
							<Group
								id="grouplinlimites1701510789181"
								direction="horizontal"
								className="w-100 h-auto position-relative" >
								<Text
									id="textlimiteuno1701510789181"
									tag="sub"
									className="w-auto h-auto position-relative" >
									{t('qmfsimulador.textlimiteuno1701510789181')}
								</Text>
								<Text
									id="text1701510789181"
									className="w-fill h-auto position-relative" >
									{t('qmfsimulador.text1701510789181')}
								</Text>
								<Text
									id="textcamposim1701510789181"
									tag="sub"
									className="w-auto h-auto align-end position-relative" >
									{t('qmfsimulador.textcamposim1701510789181')}
								</Text>
							</Group>
						</Group>
						<Group
							id="groupsimpreciolocal"
							direction="vertical"
							className="w-100 h-auto position-relative" >
							<Group
								id="grouplintitulo1701510910697"
								direction="auto"
								className="w-100 h-auto position-relative" >
								<Text
									id="texttitulo1701510910697"
									className="w-auto h-auto position-relative" >
									{t('qmfsimulador.texttitulo1701510910697')}
								</Text>
								<Text
									id="textcamposim1701510910697"
									format={{"type":"currency"}}
									src="@firebase.firestore.simulador.(@cookie.keysimulador).preciolocal"
									className="w-auto h-auto position-relative" >
									{t('qmfsimulador.textcamposim1701510910697')}
								</Text>
							</Group>
							<Input.Range
								id="rangesimuladorcampo1701510910697"
								name="range"
								src="@firebase.firestore.simulador.(@cookie.keysimulador).preciolocal"
							max={500000}
							min={0}
							step={10000}
								className="w-100 h-auto position-relative mb-5px" />
							<Group
								id="grouplinlimites1701510910697"
								direction="horizontal"
								className="w-100 h-auto position-relative" >
								<Text
									id="textlimiteuno1701510910697"
									tag="sub"
									className="w-auto h-auto position-relative" >
									{t('qmfsimulador.textlimiteuno1701510910697')}
								</Text>
								<Text
									id="text1701510910697"
									className="w-fill h-auto position-relative" >
									{t('qmfsimulador.text1701510910697')}
								</Text>
								<Text
									id="textcamposim1701510910697"
									tag="sub"
									className="w-auto h-auto align-end position-relative" >
									{t('qmfsimulador.textcamposim1701510910697')}
								</Text>
							</Group>
						</Group>
						<Group
							id="groupsgastosformalizacion"
							direction="vertical"
							className="w-100 h-auto position-relative" >
							<Group
								id="grouplintitulo1701515230900"
								direction="horizontal"
								className="w-100 h-auto position-relative" >
								<Text
									id="texttitulo1701515230900"
									className="w-auto h-auto position-relative" >
									{t('qmfsimulador.texttitulo1701515230900')}
								</Text>
								<Text
									id="textcamposim1701515230900"
									format={{"type":"currency"}}
									src="@firebase.firestore.simulador.(@cookie.keysimulador).gastosformalizacion"
									className="w-auto h-auto position-relative" >
									{t('qmfsimulador.textcamposim1701515230900')}
								</Text>
							</Group>
							<Input.Range
								id="rangesimuladorcampo1701515230900"
								name="range"
								src="@firebase.firestore.simulador.(@cookie.keysimulador).gastosformalizacion"
							max={100000}
							min={0}
							step={500}
								className="w-100 h-auto position-relative mb-5px" />
							<Group
								id="grouplinlimites1701515230900"
								direction="horizontal"
								className="w-100 h-auto position-relative" >
								<Text
									id="textlimiteuno1701515230900"
									tag="sub"
									className="w-auto h-auto position-relative" >
									{t('qmfsimulador.textlimiteuno1701515230900')}
								</Text>
								<Text
									id="text1701515230900"
									className="w-fill h-auto position-relative" >
									{t('qmfsimulador.text1701515230900')}
								</Text>
								<Text
									id="textcamposim1701515230900"
									tag="sub"
									className="w-auto h-auto align-end position-relative" >
									{t('qmfsimulador.textcamposim1701515230900')}
								</Text>
							</Group>
						</Group>
						<Group
							id="groupsiminversor"
							direction="vertical"
							className="w-100 h-auto position-relative" >
							<Group
								id="grouplintitulo1701515510476"
								direction="auto"
								className="w-100 h-auto position-relative" >
								<Text
									id="texttitulo1701515510476"
									className="w-auto h-auto position-relative" >
									{t('qmfsimulador.texttitulo1701515510476')}
								</Text>
								<Text
									id="textcamposimhhh1701515510476"
									format={{"type":"currency"}}
									src="@firebase.firestore.simulador.(@cookie.keysimulador).inversor"
									className="w-auto h-auto position-relative" >
									{t('qmfsimulador.textcamposimhhh1701515510476')}
								</Text>
							</Group>
							<Input.Range
								id="rangesimuladorcampo1701515510476"
								name="range"
								src="@firebase.firestore.simulador.(@cookie.keysimulador).inversor"
							max={5000000}
							min={0}
							step={100000}
								className="w-100 h-auto position-relative mb-5px" />
							<Group
								id="grouplinlimites1701515510476"
								direction="horizontal"
								className="w-100 h-auto position-relative" >
								<Text
									id="textlimiteuno1701515510476"
									tag="sub"
									className="w-auto h-auto position-relative" >
									{t('qmfsimulador.textlimiteuno1701515510476')}
								</Text>
								<Text
									id="text1701515510476"
									className="w-fill h-auto position-relative" >
									{t('qmfsimulador.text1701515510476')}
								</Text>
								<Text
									id="textcamposim1701515510476"
									tag="sub"
									className="w-auto h-auto align-end position-relative" >
									{t('qmfsimulador.textcamposim1701515510476')}
								</Text>
							</Group>
						</Group>
						<Group
							id="groupsimcapitalpropio"
							direction="vertical"
							className="w-100 h-auto position-relative" >
							<Group
								id="grouplintitulo1701517196492"
								direction="auto"
								className="w-100 h-auto position-relative" >
								<Text
									id="texttitulo1701517196492"
									className="w-auto h-auto position-relative" >
									{t('qmfsimulador.texttitulo1701517196492')}
								</Text>
								<Text
									id="textcamposim1701517196492"
									format={{"type":"currency"}}
									src="@firebase.firestore.simulador.(@cookie.keysimulador).capitalpropio"
									className="w-auto h-auto position-relative" >
									{t('qmfsimulador.textcamposim1701517196492')}
								</Text>
							</Group>
							<Input.Range
								id="rangesimuladorcampo1701517196492"
								name="range"
								src="@firebase.firestore.simulador.(@cookie.keysimulador).capitalpropio"
							max={5000000}
							min={0}
							step={100000}
								className="w-100 h-auto position-relative mb-5px" />
							<Group
								id="grouplinlimites1701517196492"
								direction="horizontal"
								className="w-100 h-auto position-relative" >
								<Text
									id="textlimiteuno1701517196492"
									tag="sub"
									className="w-auto h-auto position-relative" >
									{t('qmfsimulador.textlimiteuno1701517196492')}
								</Text>
								<Text
									id="text1701517196492"
									className="w-fill h-auto position-relative" >
									{t('qmfsimulador.text1701517196492')}
								</Text>
								<Text
									id="textcamposim1701517196492"
									tag="sub"
									className="w-auto h-auto align-end position-relative" >
									{t('qmfsimulador.textcamposim1701517196492')}
								</Text>
							</Group>
						</Group>
						<Group
							id="groupsiminteresprestamo"
							direction="vertical"
							className="w-100 h-auto position-relative" >
							<Group
								id="grouplintitulo1701517314517"
								direction="auto"
								className="w-100 h-auto position-relative" >
								<Text
									id="texttitulo1701517314517"
									className="w-auto h-auto position-relative" >
									{t('qmfsimulador.texttitulo1701517314517')}
								</Text>
								<Text
									id="textcamposim1701517314517"
									src="@firebase.firestore.simulador.(@cookie.keysimulador).interesprestamo"
									className="w-auto h-auto position-relative" >
									{t('qmfsimulador.textcamposim1701517314517')}
								</Text>
								<Text
									id="textcamposporcineto1701531813711"
									className="w-auto h-auto position-relative" >
									{t('qmfsimulador.textcamposporcineto1701531813711')}
								</Text>
							</Group>
							<Input.Range
								id="rangesimuladorcampo1701517314517"
								name="range"
								src="@firebase.firestore.simulador.(@cookie.keysimulador).interesprestamo"
							max={10}
							min={0}
							step={0.25}
								className="w-100 h-auto position-relative mb-5px" />
							<Group
								id="grouplinlimites1701517314517"
								direction="horizontal"
								className="w-100 h-auto position-relative" >
								<Text
									id="textlimiteuno1701517314517"
									tag="sub"
									className="w-auto h-auto position-relative" >
									{t('qmfsimulador.textlimiteuno1701517314517')}
								</Text>
								<Text
									id="text1701517314517"
									className="w-fill h-auto position-relative" >
									{t('qmfsimulador.text1701517314517')}
								</Text>
								<Text
									id="textcamposim1701517314517"
									tag="sub"
									className="w-auto h-auto align-end position-relative" >
									{t('qmfsimulador.textcamposim1701517314517')}
								</Text>
							</Group>
						</Group>
						<Group
							id="groupsimduracionprestamo"
							direction="vertical"
							className="w-100 h-auto position-relative" >
							<Group
								id="grouplintitulo1701518858819"
								direction="auto"
								className="w-100 h-auto position-relative" >
								<Text
									id="texttitulo1701518858819"
									className="w-auto h-auto position-relative" >
									{t('qmfsimulador.texttitulo1701518858819')}
								</Text>
								<Text
									id="textcamposim1701518858819"
									format={{"type":"amount"}}
									src="@firebase.firestore.simulador.(@cookie.keysimulador).duracionprestamo"
									className="w-auto h-auto position-relative" >
									{t('qmfsimulador.textcamposim1701518858819')}
								</Text>
								<Text
									id="textcamposporcineto1701535331030"
									className="w-auto h-auto position-relative" >
									{t('qmfsimulador.textcamposporcineto1701535331030')}
								</Text>
							</Group>
							<Input.Range
								id="rangesimuladorcampo1701518858819"
								name="range"
								src="@firebase.firestore.simulador.(@cookie.keysimulador).duracionprestamo"
							max={30}
							min={0}
							step={1}
								className="w-100 h-auto position-relative mb-5px" />
							<Group
								id="grouplinlimites1701518858819"
								direction="horizontal"
								className="w-100 h-auto position-relative" >
								<Text
									id="textlimiteuno1701518858819"
									tag="sub"
									className="w-auto h-auto position-relative" >
									{t('')}
								</Text>
								<Text
									id="text1701518858819"
									className="w-fill h-auto position-relative" >
									{t('qmfsimulador.text1701518858819')}
								</Text>
								<Text
									id="textcamposim1701518858819"
									tag="sub"
									className="w-auto h-auto align-end position-relative" >
									{t('qmfsimulador.textcamposim1701518858819')}
								</Text>
							</Group>
						</Group>
						<Group
							id="groupsimrentabilidadinversor"
							direction="vertical"
							className="w-100 h-auto position-relative" >
							<Group
								id="grouplintitulo1701518861593"
								direction="auto"
								className="w-100 h-auto position-relative" >
								<Text
									id="texttitulo1701518861593"
									className="w-auto h-auto position-relative" >
									{t('qmfsimulador.texttitulo1701518861593')}
								</Text>
								<Text
									id="textcamposim1701518861593"
									src="@firebase.firestore.simulador.(@cookie.keysimulador).rentabilidadinversor"
									className="w-auto h-auto position-relative" >
									{t('qmfsimulador.textcamposim1701518861593')}
								</Text>
								<Text
									id="textcamposporcineto1701531819768"
									className="w-auto h-auto position-relative" >
									{t('qmfsimulador.textcamposporcineto1701531819768')}
								</Text>
							</Group>
							<Input.Range
								id="rangesimuladorcampo1701518861593"
								name="range"
								src="@firebase.firestore.simulador.(@cookie.keysimulador).rentabilidadinversor"
							max={10}
							min={0}
							step={0.25}
								className="w-100 h-auto position-relative mb-5px" />
							<Group
								id="grouplinlimites1701518861593"
								direction="horizontal"
								className="w-100 h-auto position-relative" >
								<Text
									id="textlimiteuno1701518861593"
									tag="sub"
									className="w-auto h-auto position-relative" >
									{t('qmfsimulador.textlimiteuno1701518861593')}
								</Text>
								<Text
									id="text1701518861593"
									className="w-fill h-auto position-relative" >
									{t('qmfsimulador.text1701518861593')}
								</Text>
								<Text
									id="textcamposim1701518861593"
									tag="sub"
									className="w-auto h-auto align-end position-relative" >
									{t('qmfsimulador.textcamposim1701518861593')}
								</Text>
							</Group>
						</Group>
					</Group>
				</Group>
				<Group
					id="group1701643921866"
					direction="vertical"
					className="w-100 h-auto valign-center position-relative bg-accent p-10px" >
					<Group
						id="groupcamposresulta"
						direction="vertical"
						className="w-100 h-auto border-radius-30px valign-start position-relative bg-white p-30px" >
						<Group
							id="groupcolecionprinc"
							direction="vertical"
							className="w-100 h-auto position-relative" >
							<Text
								id="text1701628142480"
								onClick={text1701628142480OnClick}
								className="w-100 h-auto border-radius-20px position-relative bg-accent my-20px text-text-on-accent text-center" >
								{t('qmfsimulador.text1701628142480')}
							</Text>
							<Group
								id="groupcinversion"
								direction="auto"
								className="w-100 h-auto position-relative" >
								<Text
									id="text1titinver701586183231"
									className="w-auto h-auto position-relative" >
									{t('qmfsimulador.text1titinver701586183231')}
								</Text>
								<Text
									id="textvarinve1701586183231"
									format={{"type":"currency"}}
									src="@firebase.firestore.simulador.(@cookie.keysimulador).inversiontotal"
									className="w-auto h-auto position-relative" >
									{t('qmfsimulador.textvarinve1701586183231')}
								</Text>
							</Group>
							<Group
								id="groupcapitalprestamo"
								direction="auto"
								className="w-100 h-auto position-relative" >
								<Text
									id="texttitcpat1701517929809"
									className="w-auto h-auto position-relative" >
									{t('qmfsimulador.texttitcpat1701517929809')}
								</Text>
								<Text
									id="textvalcap1701518018445"
									format={{"type":"currency"}}
									src="@firebase.firestore.simulador.(@cookie.keysimulador).capitalprestamo"
									className="w-auto h-auto position-relative" >
									{t('qmfsimulador.textvalcap1701518018445')}
								</Text>
							</Group>
							<Group
								id="groupcouotadelprestamo"
								direction="auto"
								className="w-100 h-auto position-relative" >
								<Text
									id="texttitcoutasim1701519396053"
									className="w-auto h-auto position-relative" >
									{t('qmfsimulador.texttitcoutasim1701519396053')}
								</Text>
								<Text
									id="textvalorcoutasim1701519396053"
									format={{"type":"currency"}}
									src="@firebase.firestore.simulador.(@cookie.keysimulador).cuotaprestamo"
									className="w-auto h-auto position-relative" >
									{t('qmfsimulador.textvalorcoutasim1701519396053')}
								</Text>
							</Group>
							<Group
								id="groupcouotadelprestamoanaual"
								direction="auto"
								className="w-100 h-auto position-relative" >
								<Text
									id="texttitcoutasim1701588403753"
									className="w-auto h-auto position-relative" >
									{t('qmfsimulador.texttitcoutasim1701588403753')}
								</Text>
								<Text
									id="textvalorcoutasim1701588403753"
									format={{"type":"currency"}}
									src="@firebase.firestore.simulador.(@cookie.keysimulador).cuotaanualprestamo"
									className="w-auto h-auto position-relative" >
									{t('qmfsimulador.textvalorcoutasim1701588403753')}
								</Text>
							</Group>
							<Group
								id="groupbeneinversor"
								direction="auto"
								className="w-100 h-auto position-relative" >
								<Text
									id="texttitbenfsim1701519596241"
									className="w-auto h-auto position-relative" >
									{t('qmfsimulador.texttitbenfsim1701519596241')}
								</Text>
								<Text
									id="textvalortitsm1701519596241"
									format={{"type":"currency"}}
									src="@firebase.firestore.simulador.(@cookie.keysimulador).beneficioinversoranual"
									className="w-auto h-auto position-relative" >
									{t('qmfsimulador.textvalortitsm1701519596241')}
								</Text>
							</Group>
						</Group>
						<Group
							id="groupanualaidades"
							if={{"what":"@firebase.firestore.simulador.(@cookie.keysimulador).calcular","is":false}}
							direction="vertical"
							className="w-100 h-auto position-relative" >
							<Input.Select
								id="select1701603595954"
								name="select"
								src="@property.anualidad"
								className="w-auto h-auto position-relative my-15px" >
								<option value={0}>{t('qmfsimulador.select1701603595954.opt0')}</option>
								<option value={1}>{t('qmfsimulador.select1701603595954.opt1')}</option>
								<option value={2}>{t('qmfsimulador.select1701603595954.opt2')}</option>
								<option value={3}>{t('qmfsimulador.select1701603595954.opt3')}</option>
								<option value={4}>{t('qmfsimulador.select1701603595954.opt4')}</option>
								<option value={5}>{t('qmfsimulador.select1701603595954.opt5')}</option>
								<option value={6}>{t('qmfsimulador.select1701603595954.opt6')}</option>
								<option value={7}>{t('qmfsimulador.select1701603595954.opt7')}</option>
								<option value={8}>{t('qmfsimulador.select1701603595954.opt8')}</option>
								<option value={9}>{t('qmfsimulador.select1701603595954.opt9')}</option>
								<option value={10}>{t('qmfsimulador.select1701603595954.opt10')}</option>
								<option value={11}>{t('qmfsimulador.select1701603595954.opt11')}</option>
								<option value={12}>{t('qmfsimulador.select1701603595954.opt12')}</option>
								<option value={13}>{t('qmfsimulador.select1701603595954.opt13')}</option>
								<option value={14}>{t('qmfsimulador.select1701603595954.opt14')}</option>
								<option value={15}>{t('qmfsimulador.select1701603595954.opt15')}</option>
								<option value={16}>{t('qmfsimulador.select1701603595954.opt16')}</option>
								<option value={17}>{t('qmfsimulador.select1701603595954.opt17')}</option>
								<option value={18}>{t('qmfsimulador.select1701603595954.opt18')}</option>
								<option value={19}>{t('qmfsimulador.select1701603595954.opt19')}</option>
								<option value={20}>{t('qmfsimulador.select1701603595954.opt20')}</option>
								<option value={21}>{t('qmfsimulador.select1701603595954.opt21')}</option>
								<option value={22}>{t('qmfsimulador.select1701603595954.opt22')}</option>
								<option value={23}>{t('qmfsimulador.select1701603595954.opt23')}</option>
								<option value={24}>{t('qmfsimulador.select1701603595954.opt24')}</option>
								<option value={27}>{t('qmfsimulador.select1701603595954.opt25')}</option>
								<option value={28}>{t('qmfsimulador.select1701603595954.opt26')}</option>
								<option value={29}>{t('qmfsimulador.select1701603595954.opt27')}</option>
								<option value={30}>{t('qmfsimulador.select1701603595954.opt28')}</option>
							</Input.Select>
							<List
								id="list1701603475046"
								src="@firebase.firestore.simulador.(@cookie.keysimulador).anualidades"
								filter={{"what":"anualidad","is":"@property.anualidad"}}
								className="w-100 h-auto position-relative" >
								<Group
									id="group1701604654595"
									direction="vertical"
									className="w-100 h-auto position-relative" >
									<Group
										id="groupfacturacionanual"
										direction="auto"
										className="w-100 h-auto position-relative" >
										<Text
											id="texttitbenfsim1701604664884"
											className="w-auto h-auto position-relative" >
											{t('qmfsimulador.texttitbenfsim1701604664884')}
										</Text>
										<Text
											id="textvalortitsm1701604664884"
											format={{"type":"currency"}}
											src="facturacion"
											className="w-auto h-auto position-relative" >
											{t('qmfsimulador.textvalortitsm1701604664884')}
										</Text>
									</Group>
									<Group
										id="groupgastosfic"
										direction="auto"
										className="w-100 h-auto position-relative" >
										<Text
											id="texttitbenfsim1701604768766"
											className="w-auto h-auto position-relative" >
											{t('qmfsimulador.texttitbenfsim1701604768766')}
										</Text>
										<Text
											id="textvalortitsm1701604768766"
											format={{"type":"currency"}}
											src="gastosfijos"
											className="w-auto h-auto position-relative" >
											{t('qmfsimulador.textvalortitsm1701604768766')}
										</Text>
									</Group>
									<Group
										id="groupbeneficionetoanaul"
										direction="auto"
										className="w-100 h-auto position-relative" >
										<Text
											id="texttitbenfsim1701604843139"
											className="w-auto h-auto position-relative" >
											{t('qmfsimulador.texttitbenfsim1701604843139')}
										</Text>
										<Text
											id="textvalortitsm1701604843139"
											format={{"type":"currency"}}
											src="beneficioneto"
											className="w-auto h-auto position-relative" >
											{t('qmfsimulador.textvalortitsm1701604843139')}
										</Text>
									</Group>
									<Group
										id="groupbeneficionacumulado"
										direction="auto"
										className="w-100 h-auto position-relative" >
										<Text
											id="texttitbenfsim1701604932335"
											className="w-auto h-auto position-relative text-primary-text" >
											{t('qmfsimulador.texttitbenfsim1701604932335')}
										</Text>
										<Text
											id="textvalortitsm1701604932335"
											format={{"type":"currency"}}
											src="beneficionetoacumulado"
											className="w-auto h-auto position-relative" >
											{t('qmfsimulador.textvalortitsm1701604932335')}
										</Text>
									</Group>
									<Group
										id="groupcapitapendi"
										visible={false}
										direction="auto"
										className="w-100 h-auto position-relative" >
										<Text
											id="texttitbenfsim1701604991899"
											tag="h3"
											className="w-auto h-auto position-relative" >
											{t('qmfsimulador.texttitbenfsim1701604991899')}
										</Text>
										<Text
											id="textvalortitsm1701604991899"
											format={{"type":"currency"}}
											tag="h3"
											src="capitalpendiente"
											className="w-auto h-auto position-relative text-primary-text" >
											{t('qmfsimulador.textvalortitsm1701604991899')}
										</Text>
									</Group>
								</Group>
							</List>
						</Group>
						<Lottie
							id="lottieesperandocompra1701629463763"
							if={{"what":"@firebase.firestore.simulador.(@cookie.keysimulador).calcular","is":true}}
							src={puntos}
							className="w-50px min-h-50px h-50px align-center valign-center position-absolute" />
					</Group>
					<Group
						id="group1701680629624"
						direction="vertical"
						className="w-100 h-auto position-relative" >
						<Text
							id="text1701680735772"
							className="w-100 h-fill position-relative" >
							{t('qmfsimulador.text1701680735772')}
						</Text>
						<Chart.Bar
							id="chart1701707862536"
							data={{"datasets":[],"labels":[]}}
							className="w-100 h-auto position-relative" />
					</Group>
				</Group>
			</Group>
      </Qmf_wradministradoresWrapper>
    </>
  );
};
