import React from 'react';
import { useDialogs } from '../hooks';
import { useFunctions } from '../hooks';

// Custom imports:
import { Modal, Text } from '../components';
import { useTranslation } from 'react-i18next';

const AccesorestringidoDialog = () => {
    const { run } = useFunctions();
    // Custom exports:
	const { t } = useTranslation();

    // Custom functions:

  return (
    
    
			<Modal id="accesorestringido">
					<Modal.Header closeButton>
						<Modal.Title>{t('accesorestringido.title')}</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Text
							id="text1687777574637"
							className="w-100 h-auto position-relative" >
							{t('accesorestringido.text1687777574637')}
						</Text>
					</Modal.Body>
					<Modal.Footer>
					</Modal.Footer>
			</Modal>
    
  );
};

export default AccesorestringidoDialog;
