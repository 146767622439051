import React from 'react';
import { useDialogs } from '../hooks';
import { useFunctions } from '../hooks';

// Custom imports:
import { Modal, Input, Button } from '../components';
import { useTranslation } from 'react-i18next';

const Anadir_documento_ofertaDialog = () => {
    const { run } = useFunctions();
    // Custom exports:
	const { t } = useTranslation();

    // Custom functions:
	const anadir_documento_oferta_buttons_positiveOnClick = async (options) => {
		run([{"id":"file1687939581856","function":"file","onsuccess":[{"id":"set1687939581856","function":"set","what":"@property.do","value":"@app.timestamp"},{"id":"set1687939581856","function":"set","what":"@property.documento","value":"do(@property.do)"},{"id":"set1687939581856","function":"set","what":"@firebase.storage.ofertas\/(@cookie.keyoferta)\/(@property.documento)\/(@property.nombredocumento).pdf","value":"@property.tempFile"},{"id":"set1687939581856","function":"set","what":"@property.arroba","value":"@"},{"id":"add1687939581856","function":"add","what":{"documento":"(@property.arroba)firebase.storage.ofertas\/(@cookie.keyoferta)\/(@property.documento)\/(@property.nombredocumento).pdf","ruta":"ofertas\/(@cookie.keyoferta)\/(@property.documento)\/(@property.nombredocumento).pdf","nombre":"@property.nombredocumento"},"to":"@firebase.firestore.ofertas.(@cookie.keyoferta).documentos","key":"@property.documento"},{"id":"add1687939581856","function":"add","what":{"ruta":"ofertas\/(@cookie.keyoferta)\/(@property.documento)\/(@property.nombredocumento).pdf","oferta":"@cookie.keyoferta","usuario":"@cookie.userID","nombre":"@property.nombredocumento"},"to":"@firebase.firestore.documentostorage","key":"@property.documento"}],"into":"@property.tempFile","type":".pdf","if":{"what":"@property.nombredocumento","is_not":null}},{"function":"hide","dialog":"anadir_documento_oferta"}], options)
	}
	const anadir_documento_ofertaOnLoaded = async (options) => {
		run([{"id":"set1687939581856","function":"set","what":"@property.nombredocumento","value":null}], options)
	}

  return (
    
    
			<Modal id="anadir_documento_oferta" onShow={anadir_documento_ofertaOnLoaded}>
					<Modal.Header closeButton>
						<Modal.Title>{t('anadir_documento_oferta.title')}</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						{t('anadir_documento_oferta.text')}
						<Input.Text
							id="inputpuja1687939581856"
							name="text"
							src="@property.nombredocumento"
						placeholder={t('anadir_documento_oferta.inputpuja1687939581856.hint')}
						autoComplete="off"
							className="w-auto h-auto position-relative" />
					</Modal.Body>
					<Modal.Footer>
						<Button
							id="anadir_documento_oferta_buttons_positive"
							onClick={anadir_documento_oferta_buttons_positiveOnClick}
							variant="primary"
							className="w-auto h-auto position-relative" >
							{t('anadir_documento_oferta.anadir_documento_oferta_buttons_positive')}
						</Button>
					</Modal.Footer>
			</Modal>
    
  );
};

export default Anadir_documento_ofertaDialog;
