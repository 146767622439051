import React from 'react';
import { useDialogs } from '../hooks';
import { useFunctions } from '../hooks';

// Custom imports:
import { Modal, Group, Input, Button } from '../components';
import { useTranslation } from 'react-i18next';

const Crear_valoracionDialog = () => {
    const { run } = useFunctions();
    // Custom exports:
	const { t } = useTranslation();

    // Custom functions:
	const crear_valoracion_buttons_positiveOnClick = async (options) => {
		run([{"id":"set1687088321044","function":"set","what":"@property.ini","value":"@app.timestamp"},{"id":"set1687088321044","function":"set","what":"@cookie.keyvaloracion","value":"qmfcon(@property.ini)"},{"id":"set1687088321044","function":"set","what":"@property.nombre","value":"@firebase.firestore.usuarios.(@cookie.userID).nombre"},{"id":"set1687088321044","function":"set","what":"@property.consulta","value":"Debes realizar una respuesta, al ser posible esquematizada, sobre la valoraci\u00f3n que puede tener una farmacia teniendo en cuenta los siguientes datos, explic\u00e1ndole al cliente sobre los m\u00e1rgenes que se puede mover y coeficientes a multiplicar: zona de ubicaci\u00f3n en (@property.zona), metros del local (@property.metroslocal), n\u00famero de empleados (@property.empleados), la facturaci\u00f3n de la farmacia es de (@property.facturacion) euros.\u00a0 El nombre del que realiza la consulta se llama (@property.nombre). Hay que tener en cuenta que la zona de ubicaci\u00f3n de la farmacia siempre ser\u00e1 en Espa\u00f1a."},{"id":"add1687088321044","function":"add","what":{"consulta":"@property.consulta","timestamp":"@app.timestamp","pregunta":"@property.consultarealizada","usuario":"@cookie.userID"},"to":"@firebase.firestore.usuarios.(@cookie.userID).valoraciones","key":"@cookie.keyvaloracion"},{"id":"goto1688116570420","function":"goto","view":"esperando_valoracion"},{"id":"set1689280947524","function":"set","what":"@property.ini","value":"@app.timestamp"},{"id":"set1689282539193","function":"set","what":"@property.keyhistorial","value":"his(@property.ini)"},{"id":"add1689271409051","function":"add","what":{"accion":"(@property.nombre) ha solicitado valoraci\u00f3n de la farmacia","timestamp":"@app.timestamp"},"to":"@firebase.firestore.historial","key":"@property.keyhistorial"},{"function":"hide","dialog":"crear_valoracion"}], options)
	}
	const crear_valoracionOnLoaded = async (options) => {
		run([{"id":"set1687074342013","function":"set","what":"@property.zona","value":"@property.vacia"},{"id":"set1687074342013","function":"set","what":"@property.metroslocal","value":"@property.vacia"},{"id":"set1687074342013","function":"set","what":"@property.empleados","value":"@property.vacia"},{"id":"set1687074342013","function":"set","what":"@property.facturacion","value":"@property.vacia"}], options)
	}

  return (
    
    
			<Modal id="crear_valoracion" centered animation={false} onShow={crear_valoracionOnLoaded}>
					<Modal.Header closeButton>
						<Modal.Title>{t('crear_valoracion.title')}</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Group
							id="group1685773309634"
							direction="vertical"
							className="w-100 h-auto position-relative p-10px mb-30px scrollable" >
							<Input.Select
								id="select1685773344795"
								label={t('crear_valoracion.select1685773344795.label')}
								name="select"
								src="@property.zona"
								className="w-100 h-auto position-relative mb-10px" >
								<option value={"@property.vacia "}>{t('crear_valoracion.select1685773344795.opt0')}</option>
								<option value={"Zona rural"}>{t('crear_valoracion.select1685773344795.opt1')}</option>
								<option value={"Zona rural"}>{t('crear_valoracion.select1685773344795.opt2')}</option>
								<option value={"Zona turística"}>{t('crear_valoracion.select1685773344795.opt3')}</option>
								<option value={"Zona industrial"}>{t('crear_valoracion.select1685773344795.opt4')}</option>
							</Input.Select>
							<Input.Select
								id="select1685774282162"
								label={t('crear_valoracion.select1685774282162.label')}
								name="select"
								src="@property.empleados"
								className="w-100 h-auto position-relative mb-10px" >
								<option value={0}>{t('crear_valoracion.select1685774282162.opt0')}</option>
								<option value={1}>{t('crear_valoracion.select1685774282162.opt1')}</option>
								<option value={2}>{t('crear_valoracion.select1685774282162.opt2')}</option>
								<option value={3}>{t('crear_valoracion.select1685774282162.opt3')}</option>
								<option value={4}>{t('crear_valoracion.select1685774282162.opt4')}</option>
								<option value={5}>{t('crear_valoracion.select1685774282162.opt5')}</option>
								<option value={6}>{t('crear_valoracion.select1685774282162.opt6')}</option>
								<option value={"Más de 6"}>{t('crear_valoracion.select1685774282162.opt7')}</option>
							</Input.Select>
							<Input.Number
								id="inputtext1685260356525"
								label={t('crear_valoracion.inputtext1685260356525.label')}
								name="text"
								src="@property.metroslocal"
							placeholder={t('crear_valoracion.inputtext1685260356525.hint')}
							autoComplete="off"
								className="w-100 h-auto position-relative mb-10px" />
							<Input.Number
								id="inputtext1687076189558"
								label={t('crear_valoracion.inputtext1687076189558.label')}
								name="text"
								src="@property.facturacion"
							placeholder={t('crear_valoracion.inputtext1687076189558.hint')}
							autoComplete="off"
								className="w-100 h-auto position-relative mb-10px" />
						</Group>
					</Modal.Body>
					<Modal.Footer>
						<Button
							id="crear_valoracion_buttons_positive"
							onClick={crear_valoracion_buttons_positiveOnClick}
							variant="primary"
							className="w-auto h-auto position-relative" >
							{t('crear_valoracion.crear_valoracion_buttons_positive')}
						</Button>
					</Modal.Footer>
			</Modal>
    
  );
};

export default Crear_valoracionDialog;
