import React from 'react';
import { useDialogs } from '../hooks';
import { useFunctions } from '../hooks';

// Custom imports:
import { Modal, Group, Input, Button } from '../components';
import { useTranslation } from 'react-i18next';

const Qmf_gestionardemandaDialog = () => {
    const { run } = useFunctions();
    // Custom exports:
	const { t } = useTranslation();

    // Custom functions:
	const qmf_gestionardemanda_buttons_positiveOnClick = async (options) => {
		run([{"id":"set1694161174396","function":"set","what":"@property.nombre","value":"@firebase.firestore.demandacompradores.(@cookie.keydemanda).nombre"},{"id":"set1700297061529","function":"set","what":"@property.nombreadmin","value":"@firebase.firestore.usuarios.(@firebase.user.id).nombre"},{"id":"add1700297557608","function":"add","what":{"anotacion":"@property.anotacion","timestamp":"@app.timestamp","estado":"@property.estado","administrador":"@property.nombreadmin"},"to":"@firebase.firestore.demandacompradores.(@cookie.keydemanda).anotaciones","if":{"what":"@property.estado","is_not":"@propety.vacio","else":[{"id":"popup1700297557608","function":"popup","dialog":"gestionaroferta"}]}},{"id":"add1694161174396","function":"add","what":{"timestamp":"@app.timestamp","anotacion":"La demanda (@cookie.keydemanda) de (@property.nombre) ha cambiado a estado (@property.estado), gestionada por: (@property.nombreadmin)"},"to":"@firebase.firestore.historial","if":{"what":"@property.estado","is_not":"@propety.vacio","else":[{"id":"popup1694161174396","function":"popup","dialog":"gestionaroferta"}]}},{"id":"set1694161174396","function":"set","what":"@firebase.firestore.demandacompradores.(@cookie.keydemanda).estado","value":"@property.estado","if":{"what":"@property.estado","is_not":"@property.vacio"}},{"id":"add1694161174396","function":"add","what":{"email":"ctorres@quieromifarma.es; germanmunoz1@gmail.com; ajpedraza@gmail.com","asunto":"Demanda (@cookie.keydemanda) (@property.estado)","contenido":"Ref:\u00a0(@cookie.keydemanda) de (@property.nombre).\nEstado: (@property.estado).\nAnotaci\u00f3n: (@property.anotacion)\n\nGestionada por: (@property.nombreadmin)\n"},"to":"@firebase.firestore.enviarmail","if":{"what":"@property.estado","is_not":"@property.vacio"}},{"function":"hide","dialog":"qmf_gestionardemanda"}], options)
	}
	const qmf_gestionardemanda_buttons_negativeOnClick = async (options) => {
		run([{"function":"hide","dialog":"qmf_gestionardemanda"}], options)
	}
	const qmf_gestionardemandaOnLoaded = async (options) => {
		run([{"id":"set1694161174396","function":"set","what":"@property.estado","value":"@firebase.firestore.demandacompradores.(@cookie.keydemanda).estado"},{"id":"set1694161174396","function":"set","what":"@property.anotacion","value":"@property.vacio"}], options)
	}

  return (
    
    
			<Modal id="qmf_gestionardemanda" onShow={qmf_gestionardemandaOnLoaded}>
					<Modal.Header closeButton>
						<Modal.Title>{t('qmf_gestionardemanda.title')}</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Group
							id="group1694161174396"
							direction="vertical"
							className="w-100 h-auto position-relative" >
							<Input.TextArea
								id="inputtext1694161174396"
								label={t('qmf_gestionardemanda.inputtext1694161174396.label')}
								name="text"
								src="@property.anotacion"
							placeholder={t('qmf_gestionardemanda.inputtext1694161174396.hint')}
							autoComplete="off"
								className="w-100 h-auto position-relative" />
							<Input.Select
								id="select1694161174396"
								label={t('qmf_gestionardemanda.select1694161174396.label')}
								name="select"
								src="@property.estado"
								dataSrc="@firebase.firestore.estados_demandas"
								className="w-100 h-auto position-relative" >
							</Input.Select>
						</Group>
					</Modal.Body>
					<Modal.Footer>
						<Button
							id="qmf_gestionardemanda_buttons_negative"
							onClick={qmf_gestionardemanda_buttons_negativeOnClick}
							variant="transparent"
							className="w-auto h-auto position-relative" >
							{t('qmf_gestionardemanda.qmf_gestionardemanda_buttons_negative')}
						</Button>
						<Button
							id="qmf_gestionardemanda_buttons_positive"
							onClick={qmf_gestionardemanda_buttons_positiveOnClick}
							variant="primary"
							className="w-auto h-auto position-relative" >
							{t('qmf_gestionardemanda.qmf_gestionardemanda_buttons_positive')}
						</Button>
					</Modal.Footer>
			</Modal>
    
  );
};

export default Qmf_gestionardemandaDialog;
