import React from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useFunctions, useRefs } from '../hooks';
import { FullscreenWrapper } from '../wrappers';

import { useLocation } from 'react-router-dom';
import { getLocationParams } from '../util';

// Custom imports:
import { Group, Text, Input } from '../components';
import { useTranslation } from 'react-i18next';

export const LoginView = () => {
  const { run } = useFunctions();
  const { search, pathname } = useLocation();


  // Custom exports:
	const { t } = useTranslation();

  // Custom functions:
	const showSignupGroupLinkOnClick = async (options) => {
		run([{"id":"goto1686432153699","function":"goto","view":"coach1"}], options)
	}
	const showSignupGroupOnClick = async (options) => {
		run([{"id":"goto1678811920165","function":"goto","view":"coach1"}], options)
	}
	const loginRecoverLinkOnClick = async (options) => {
		run([{"function":"set","what":"@property.authMessage","value":"forgot"},{"function":"popup","dialog":"auth_dialog_1"}], options)
	}
	const loginButtonOnClick = async (options) => {
		run([{"function":"login","provider":"firebase.email","email":"@property.authEmail","pass":"@property.authPassword","onvalid":[{"function":"set","what":"@cookie.userID","value":"@firebase.user.id"},{"function":"goto","view":"qmf_administradores"}],"oninvalid":[{"function":"set","what":"@property.authMessage","value":"signin"},{"function":"popup","dialog":"auth_dialog_1"}]}], options)
	}

  return (
    <>
      <Helmet>
				<title>{t('login.title')}</title>
      </Helmet>
      <FullscreenWrapper>
          
			<Group
				id="authBody"
				direction="vertical"
				className="w-100 min-h-100vh h-100vh position-relative" >
				<Group
					id="authWrapper"
					direction="horizontal"
					className="min-w-320px w-fill min-h-100 fg-100 position-relative py-32px" >
					<Group
						id="authArea"
						direction="vertical"
						className="w-100 h-auto valign-center position-relative" >
						<Group
							id="authForms"
							direction="vertical"
							className="max-w-400px w-fill h-auto align-center valign-center position-absolute" >
							<Group
								id="signinForm"
								direction="vertical"
								className="w-100 h-auto border-radius-15px position-relative p-32px mt-24px" >
								<Text
									id="googleSigninLabel1666647755197"
									tag="h1"
									className="w-auto h-auto align-center valign-center position-relative text-primary-text ff-auth_bold" >
									{t('login.googleSigninLabel1666647755197')}
								</Text>
								<Group
									id="authSigninEmail"
									direction="vertical"
									className="w-100 h-auto position-relative" >
									<Input.Email
										id="authLoginEmailInput"
										name="loginEmail"
										src="@property.authEmail"
									placeholder={t('login.authLoginEmailInput.hint')}
									autoComplete="off"
										className="w-100 h-auto position-relative bg-primary-light" />
									<Input.Password
										id="authLoginPasswordInput"
										name="loginEmail"
										src="@property.authPassword"
									placeholder={t('login.authLoginPasswordInput.hint')}
									autoComplete="off"
										className="w-100 h-auto position-relative bg-primary-light mt-24px" />
								</Group>
								<Text
									id="loginButton"
									onClick={loginButtonOnClick}
									className="w-100 h-auto border-radius-10px position-relative bg-accent py-8px mt-32px text-text-on-accent ff-auth_regular text-center" >
									{t('login.loginButton')}
								</Text>
								<Text
									id="loginRecoverLink"
									onClick={loginRecoverLinkOnClick}
									className="w-auto h-auto align-center position-relative mt-16px text-accent ff-auth_regular" >
									{t('login.loginRecoverLink')}
								</Text>
								<Group
									id="showSignupGroup"
									visible={false}
									onClick={showSignupGroupOnClick}
									direction="horizontal"
									className="w-auto h-auto align-center position-relative p-8px mt-16px" >
									<Text
										id="showSignupGroupLabel"
										className="w-auto h-auto position-relative pe-8px text-primary-dark ff-auth_regular" >
										{t('login.showSignupGroupLabel')}
									</Text>
									<Text
										id="showSignupGroupLink"
										onClick={showSignupGroupLinkOnClick}
										className="w-auto h-auto position-relative text-accent ff-auth_regular" >
										{t('login.showSignupGroupLink')}
									</Text>
								</Group>
							</Group>
						</Group>
					</Group>
				</Group>
			</Group>
      </FullscreenWrapper>
    </>
  );
};
