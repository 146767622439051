import React from 'react';
import { useDialogs } from '../hooks';
import { useFunctions } from '../hooks';

// Custom imports:
import { Modal, Input, Button } from '../components';
import { useTranslation } from 'react-i18next';

const Qmf_anadir_documento_demandaDialog = () => {
    const { run } = useFunctions();
    // Custom exports:
	const { t } = useTranslation();

    // Custom functions:
	const qmf_anadir_documento_demanda_buttons_positiveOnClick = async (options) => {
		run([{"id":"file1693844309971","function":"file","onsuccess":[{"id":"set1693844309971","function":"set","what":"@property.do","value":"@app.timestamp"},{"id":"set1693844309971","function":"set","what":"@property.documento","value":"do(@property.do)"},{"id":"set1693844309971","function":"set","what":"@firebase.storage.ofertas\/(@cookie.keydemanda)\/(@property.documento)\/(@property.nombredocumento).pdf","value":"@property.tempFile"},{"id":"set1693844309971","function":"set","what":"@property.arroba","value":"@"},{"id":"add1693844309971","function":"add","what":{"documento":"(@property.arroba)firebase.storage.ofertas\/(@cookie.keydemanda)\/(@property.documento)\/(@property.nombredocumento).pdf","ruta":"demandas\/(@cookie.keydemanda)\/(@property.documento)\/(@property.nombredocumento).pdf","nombre":"@property.nombredocumento"},"to":"@firebase.firestore.demandacompradores.(@cookie.keydemanda).documentos","key":"@property.documento"},{"id":"add1693844309971","function":"add","what":{"ruta":"demandas\/(@cookie.keydemanda)\/(@property.documento)\/(@property.nombredocumento).pdf"},"to":"@firebase.firestore.documentostorage","key":"@property.documento"}],"into":"@property.tempFile","type":".pdf","if":{"what":"@property.nombredocumento","is_not":null}},{"function":"hide","dialog":"qmf_anadir_documento_demanda"}], options)
	}
	const qmf_anadir_documento_demandaOnLoaded = async (options) => {
		run([{"id":"set1693844309971","function":"set","what":"@property.nombredocumento","value":null}], options)
	}

  return (
    
    
			<Modal id="qmf_anadir_documento_demanda" onShow={qmf_anadir_documento_demandaOnLoaded}>
					<Modal.Header closeButton>
						<Modal.Title>{t('qmf_anadir_documento_demanda.title')}</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						{t('qmf_anadir_documento_demanda.text')}
						<Input.Text
							id="inputpuja1693844309971"
							name="text"
							src="@property.nombredocumento"
						placeholder={t('qmf_anadir_documento_demanda.inputpuja1693844309971.hint')}
						autoComplete="off"
							className="w-auto h-auto position-relative" />
					</Modal.Body>
					<Modal.Footer>
						<Button
							id="qmf_anadir_documento_demanda_buttons_positive"
							onClick={qmf_anadir_documento_demanda_buttons_positiveOnClick}
							variant="primary"
							className="w-auto h-auto position-relative" >
							{t('qmf_anadir_documento_demanda.qmf_anadir_documento_demanda_buttons_positive')}
						</Button>
					</Modal.Footer>
			</Modal>
    
  );
};

export default Qmf_anadir_documento_demandaDialog;
