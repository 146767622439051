import React from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useFunctions, useRefs } from '../hooks';
import { FullscreenWrapper } from '../wrappers';

import { useLocation } from 'react-router-dom';
import { getLocationParams } from '../util';

// Custom imports:
import { Group, Text, Accordion, List, Image } from '../components';
import { useEffect } from 'react';
import publicarverde from '../assets/iconos/assest/publicarverde.png';
import { useTranslation } from 'react-i18next';

export const Qmf_consulta_ofertaView = () => {
  const { run } = useFunctions();
  const { search, pathname } = useLocation();


  // Custom exports:
	const { t } = useTranslation();

  // Custom functions:
	const qmf_consulta_ofertaParams = () => {
		run( getLocationParams( search, pathname ) )
	}
	useEffect( qmf_consulta_ofertaParams, [])
	const qmf_consulta_ofertaOnLoaded = async (options) => {
		run([{"id":"goto1694131725972","function":"goto","view":"qmfhome","if":{"what":"@firebase.user.id","is":null}}], options)
	}
	useEffect( qmf_consulta_ofertaOnLoaded, [])
	const texvofdflvergfgft1693650557147OnClick = async (options) => {
		run([{"id":"back1693650557147","function":"back"}], options)
	}
	const texgestionarfgft1693650557147OnClick = async (options) => {
		run([{"id":"popup1693650557147","function":"popup","dialog":"qmf_gestionaroferta"}], options)
	}
	const imagfverdocumentoOnClick = async (options) => {
		run([{"id":"browser1693650557147","function":"browser","url":"@firebase.firestore.documentostorage.(@element.listadocumentosoferta.selected).token"}], options)
	}
	const texgfganadirOnClick = async (options) => {
		run([{"id":"popup1693678058589","function":"popup","dialog":"qmf_anadir_documento_demanda"}], options)
	}

  return (
    <>
      <Helmet>
				<title>{t('qmf_consulta_oferta.title')}</title>
				<meta name="description" content={t('qmf_consulta_oferta.title')} data-react-helmet="true" />
      </Helmet>
      <FullscreenWrapper>
          
			<Group
				id="groupprinciofertas1693650557147"
				direction="vertical"
				className="w-100 min-h-100 fg-100 position-relative" >
				<Group
					id="groupkkjjhjh1693650557147"
					direction="vertical"
					className="w-100 h-fill position-relative scrollable" >
					<Group
						id="gr12ou45544p1693650557147"
						direction="vertical"
						className="w-100 h-fill align-center position-relative scrollable" >
						<Group
							id="signio5566ounFortrerm1693650557147"
							direction="vertical"
							className="w-100 h-auto border-radius-15px align-center position-relative p-32px mt-24px" >
							<Text
								id="te12lli33x888t1693650557147"
								tag="h1"
								className="w-100 h-auto position-relative text-primary-light ff-gantaribold" >
								{t('qmf_consulta_oferta.te12lli33x888t1693650557147')}
							</Text>
							<Text
								id="treiollooyut1693650557147"
								className="w-100 min-h-3px h-3px position-relative bg-primary-light" >
								{t('qmf_consulta_oferta.treiollooyut1693650557147')}
							</Text>
							<Accordion defaultActiveKey="0"
								id="accordion1693650557147"
								className="w-auto h-auto position-relative" >
							</Accordion>
						</Group>
						<Group
							id="grouppubicacion"
							direction="auto"
							className="w-100 h-auto position-relative p-25px" >
							<Group
								id="groutitubicacion"
								direction="vertical"
								className="w-100 h-auto position-relative" >
								<Text
									id="titubicacion"
									tag="h2"
									className="w-100 h-auto position-relative text-primary-light ff-gantaribold" >
									{t('qmf_consulta_oferta.titubicacion')}
								</Text>
								<Text
									id="linubicacion"
									className="w-100 min-h-3px h-3px position-relative bg-primary-light" >
									{t('qmf_consulta_oferta.linubicacion')}
								</Text>
							</Group>
							<Group
								id="grupodatosubicacion"
								direction="auto"
								className="w-100 h-auto valign-center position-relative p-10px" >
								<Group
									id="grouposcomunidad"
									direction="vertical"
									className="max-w-500px min-w-250px w-auto h-auto position-relative me-30px" >
									<Text
										id="texttitulcomunidad"
										className="w-100 h-auto position-relative" >
										{t('qmf_consulta_oferta.texttitulcomunidad')}
									</Text>
									<Text
										id="textcampocomunidd"
										src="@firebase.firestore.ofertavendedores.(@cookie.keyoferta).comunidad"
										className="w-100 h-auto border-top border-bottom border-start border-end position-relative mt-15px" >
										{t('qmf_consulta_oferta.textcampocomunidd')}
									</Text>
								</Group>
								<Group
									id="groupooprovincia"
									direction="vertical"
									className="max-w-500px min-w-250px w-auto h-auto position-relative me-30px" >
									<Text
										id="texttituprovincia"
										className="w-100 h-auto position-relative" >
										{t('qmf_consulta_oferta.texttituprovincia')}
									</Text>
									<Text
										id="textcampoprovincia"
										src="@firebase.firestore.ofertavendedores.(@cookie.keyoferta).provincia"
										className="w-100 h-auto border-top border-bottom border-start border-end position-relative mt-15px" >
										{t('qmf_consulta_oferta.textcampoprovincia')}
									</Text>
								</Group>
								<Group
									id="grouposhabitantes"
									direction="vertical"
									className="max-w-500px min-w-250px w-auto h-auto position-relative me-30px" >
									<Text
										id="texttitulhabitabntes"
										className="w-100 h-auto position-relative" >
										{t('qmf_consulta_oferta.texttitulhabitabntes')}
									</Text>
									<Text
										id="texthabitantes"
										src="@firebase.firestore.ofertavendedores.(@cookie.keyoferta).habitantes"
										className="w-100 h-auto border-top border-bottom border-start border-end position-relative mt-15px" >
										{t('qmf_consulta_oferta.texthabitantes')}
									</Text>
								</Group>
							</Group>
						</Group>
						<Group
							id="grouptipologia"
							direction="auto"
							className="w-100 h-auto position-relative p-25px" >
							<Group
								id="groutittipologia"
								direction="vertical"
								className="w-100 h-auto position-relative" >
								<Text
									id="tittipologia"
									tag="h2"
									className="w-100 h-auto position-relative text-primary-light ff-gantaribold" >
									{t('qmf_consulta_oferta.tittipologia')}
								</Text>
								<Text
									id="lintipologia"
									className="w-100 min-h-3px h-3px position-relative bg-primary-light" >
									{t('qmf_consulta_oferta.lintipologia')}
								</Text>
							</Group>
							<Group
								id="grupodatostipoologia"
								direction="auto"
								className="w-100 h-auto valign-center position-relative p-10px" >
								<Group
									id="grouposmetros"
									direction="vertical"
									className="max-w-500px min-w-250px w-auto h-auto position-relative me-30px" >
									<Text
										id="texttitulmetros"
										className="w-100 h-auto position-relative" >
										{t('qmf_consulta_oferta.texttitulmetros')}
									</Text>
									<Text
										id="textmetros"
										src="@firebase.firestore.ofertavendedores.(@cookie.keyoferta).metros"
										className="w-100 h-auto border-top border-bottom border-start border-end position-relative mt-15px" >
										{t('qmf_consulta_oferta.textmetros')}
									</Text>
								</Group>
								<Group
									id="groupootenencia"
									direction="vertical"
									className="max-w-500px min-w-250px w-auto h-auto position-relative me-30px" >
									<Text
										id="texttitenencia"
										className="w-100 h-auto position-relative" >
										{t('qmf_consulta_oferta.texttitenencia')}
									</Text>
									<Text
										id="texttenencia"
										src="@firebase.firestore.ofertavendedores.(@cookie.keyoferta).tenencia"
										className="w-100 h-auto border-top border-bottom border-start border-end position-relative mt-15px" >
										{t('qmf_consulta_oferta.texttenencia')}
									</Text>
								</Group>
								<Group
									id="grouposempleados"
									direction="vertical"
									className="max-w-500px min-w-250px w-auto h-auto position-relative me-30px" >
									<Text
										id="texttiempleados"
										className="w-100 h-auto position-relative" >
										{t('qmf_consulta_oferta.texttiempleados')}
									</Text>
									<Text
										id="textempleados"
										src="@firebase.firestore.ofertavendedores.(@cookie.keyoferta).empleados"
										className="w-100 h-auto border-top border-bottom border-start border-end position-relative mt-15px" >
										{t('qmf_consulta_oferta.textempleados')}
									</Text>
								</Group>
							</Group>
						</Group>
						<Group
							id="groupeconomicos"
							direction="auto"
							className="w-100 h-auto position-relative p-25px" >
							<Group
								id="grouptiteconomicos"
								direction="vertical"
								className="w-100 h-auto position-relative" >
								<Text
									id="te1233x888t1693650557147"
									tag="h2"
									className="w-100 h-auto position-relative text-primary-light ff-gantaribold" >
									{t('qmf_consulta_oferta.te1233x888t1693650557147')}
								</Text>
								<Text
									id="treiooyut1693650557147"
									className="w-100 min-h-3px h-3px position-relative bg-primary-light" >
									{t('qmf_consulta_oferta.treiooyut1693650557147')}
								</Text>
							</Group>
							<Group
								id="grupoodtoseconomicos"
								direction="auto"
								className="w-100 h-auto valign-center position-relative p-15px" >
								<Group
									id="grouposcampofactgruacion"
									direction="vertical"
									className="max-w-500px min-w-250px w-auto h-auto position-relative me-30px" >
									<Text
										id="texttitulofacturacion"
										className="w-100 h-auto position-relative" >
										{t('qmf_consulta_oferta.texttitulofacturacion')}
									</Text>
									<Text
										id="textcampofacturacion"
										src="@firebase.firestore.ofertavendedores.(@cookie.keyoferta).facturacion"
										className="w-100 h-auto border-top border-bottom border-start border-end position-relative mt-15px text-end" >
										{t('qmf_consulta_oferta.textcampofacturacion')}
									</Text>
								</Group>
								<Group
									id="grouposventasoe"
									direction="vertical"
									className="max-w-500px min-w-250px w-auto h-auto position-relative me-30px" >
									<Text
										id="texttituloventasoe"
										className="w-100 h-auto position-relative" >
										{t('qmf_consulta_oferta.texttituloventasoe')}
									</Text>
									<Text
										id="textcampoventasoe"
										src="@firebase.firestore.ofertavendedores.(@cookie.keyoferta).ventasoe"
										className="w-100 h-auto border-top border-bottom border-start border-end position-relative mt-15px text-end" >
										{t('qmf_consulta_oferta.textcampoventasoe')}
									</Text>
								</Group>
								<Group
									id="grouposventalibre"
									direction="vertical"
									className="max-w-500px min-w-250px w-auto h-auto position-relative me-30px" >
									<Text
										id="texttitulo1693674581349"
										className="w-100 h-auto position-relative" >
										{t('qmf_consulta_oferta.texttitulo1693674581349')}
									</Text>
									<Text
										id="textcampo1693674581349"
										src="@firebase.firestore.ofertavendedores.(@cookie.keyoferta).ventalibre"
										className="w-100 h-auto border-top border-bottom border-start border-end position-relative mt-15px text-end" >
										{t('qmf_consulta_oferta.textcampo1693674581349')}
									</Text>
								</Group>
								<Group
									id="grouposcampoalquiler"
									if={{"what":"@firebase.firestore.ofertavendores.(@cookie.keyoferta).tenencia","is":"alquiler"}}
									direction="vertical"
									className="max-w-500px min-w-250px w-auto h-auto position-relative me-30px" >
									<Text
										id="texttitulo1693674750943"
										className="w-100 h-auto position-relative" >
										{t('qmf_consulta_oferta.texttitulo1693674750943')}
									</Text>
									<Text
										id="textcampo1693674750943"
										format={{"type":"currency"}}
										src="@firebase.firestore.ofertavendedores.(@cookie.keyoferta).alquiler"
										className="w-100 h-auto border-top border-bottom border-start border-end position-relative mt-15px text-end" >
										{t('qmf_consulta_oferta.textcampo1693674750943')}
									</Text>
								</Group>
								<Group
									id="grouposcampoprecio"
									direction="vertical"
									className="max-w-500px min-w-250px w-auto h-auto position-relative me-30px" >
									<Text
										id="texttitulo1693675468087"
										className="w-100 h-auto position-relative" >
										{t('qmf_consulta_oferta.texttitulo1693675468087')}
									</Text>
									<Text
										id="textcampo1693675468087"
										format={{"type":"currency"}}
										src="@firebase.firestore.ofertavendedores.(@cookie.keyoferta).precio"
										className="w-100 h-auto border-top border-bottom border-start border-end position-relative mt-15px text-end" >
										{t('qmf_consulta_oferta.textcampo1693675468087')}
									</Text>
								</Group>
							</Group>
						</Group>
						<Group
							id="grouppmotivo"
							direction="auto"
							className="w-100 h-auto position-relative p-25px" >
							<Group
								id="grouhhup1693650557147"
								direction="vertical"
								className="w-100 h-auto position-relative" >
								<Text
									id="temotivo"
									tag="h2"
									className="w-100 h-auto position-relative text-primary-light ff-gantaribold" >
									{t('qmf_consulta_oferta.temotivo')}
								</Text>
								<Text
									id="linmotivo"
									className="w-100 min-h-3px h-3px position-relative bg-primary-light" >
									{t('qmf_consulta_oferta.linmotivo')}
								</Text>
							</Group>
							<Group
								id="grupoodstmotivo"
								direction="auto"
								className="w-100 h-auto valign-center position-relative p-10px" >
								<Group
									id="grouposcamposmoti"
									direction="vertical"
									className="min-w-250px w-100 h-auto position-relative p-15px me-30px" >
									<Text
										id="texttitulomotiv"
										className="w-100 h-auto position-relative" >
										{t('qmf_consulta_oferta.texttitulomotiv')}
									</Text>
									<Text
										id="textcampomoti"
										src="@firebase.firestore.ofertavendedores.(@cookie.keyoferta).motivo"
										className="w-100 h-auto border-top border-bottom border-start border-end position-relative mt-15px" >
										{t('qmf_consulta_oferta.textcampomoti')}
									</Text>
								</Group>
								<Group
									id="grouposcamposobservaciones1693844169468"
									direction="vertical"
									className="min-w-250px w-100 h-auto position-relative p-15px me-30px" >
									<Text
										id="texttitulo1693844169468"
										className="w-100 h-auto position-relative" >
										{t('qmf_consulta_oferta.texttitulo1693844169468')}
									</Text>
									<Text
										id="textcampo1693844169468"
										src="@firebase.firestore.ofertavendedores.(@cookie.keyoferta).observaciones"
										className="w-100 h-auto border-top border-bottom border-start border-end position-relative mt-15px" >
										{t('qmf_consulta_oferta.textcampo1693844169468')}
									</Text>
								</Group>
							</Group>
						</Group>
						<Group
							id="grojjdocumentosanotciones"
							direction="auto"
							className="w-100 h-auto position-relative p-25px" >
							<Group
								id="groupdocum1693650557147"
								direction="horizontal"
								className="w-100 h-auto position-relative mt-24px" >
								<Text
									id="titulodocumentos"
									tag="h2"
									className="w-auto h-auto position-relative text-primary-light ff-gantaribold" >
									{t('qmf_consulta_oferta.titulodocumentos')}
								</Text>
								<Text
									id="texlincodu"
									className="w-fill h-auto position-relative" >
									{t('qmf_consulta_oferta.texlincodu')}
								</Text>
								<Text
									id="texgfganadir"
									onClick={texgfganadirOnClick}
									tag="mark"
									className="w-auto h-auto border-radius-20px position-relative bg-primary p-15px m-5px text-text-on-accent f-10px" >
									{t('qmf_consulta_oferta.texgfganadir')}
								</Text>
							</Group>
							<Text
								id="linscpadocu"
								className="w-100 min-h-3px h-3px position-relative bg-primary-light" >
								{t('qmf_consulta_oferta.linscpadocu')}
							</Text>
							<Group
								id="grupodedocumentosoferta"
								direction="vertical"
								className="w-100 h-auto border-radius-15px align-center position-relative p-32px mt-40px scrollable" >
								<List
									id="listadocumentosoferta"
									src="@firebase.firestore.ofertavendedores.(@cookie.keyoferta).documentos"
									className="w-100 h-auto position-relative" >
									<Group
										id="groupdetalledocumetnos"
										direction="horizontal"
										className="w-100 h-auto position-relative mt-15px" >
										<Text
											id="textnombredocuemento"
											src="nombre"
											className="w-auto h-auto position-relative ff-gantaribold" >
											{t('qmf_consulta_oferta.textnombredocuemento')}
										</Text>
										<Text
											id="texkspacios"
											className="w-fill h-auto position-relative ff-gantaribold" >
											{t('qmf_consulta_oferta.texkspacios')}
										</Text>
										<Image
											id="imagfverdocumento"
											onClick={imagfverdocumentoOnClick}
											src={publicarverde}
											className="w-auto min-h-20px h-20px align-end position-relative" />
										<Text
											id="texthjspciosdocuemntos"
											className="w-auto h-auto position-relative" >
											{t('qmf_consulta_oferta.texthjspciosdocuemntos')}
										</Text>
									</Group>
								</List>
							</Group>
							<Group
								id="grojjkaanotaciones"
								direction="auto"
								className="w-100 h-auto position-relative p-25px" >
								<Group
									id="groupanotaciones"
									direction="horizontal"
									className="w-100 h-auto position-relative mt-24px" >
									<Text
										id="ttfgcshyge1233x888t1693650557147"
										tag="h2"
										className="w-auto h-auto position-relative text-primary-light ff-gantaribold" >
										{t('qmf_consulta_oferta.ttfgcshyge1233x888t1693650557147')}
									</Text>
									<Text
										id="texfgfgft1693650557147"
										className="w-fill h-auto position-relative" >
										{t('qmf_consulta_oferta.texfgfgft1693650557147')}
									</Text>
								</Group>
								<Text
									id="trrspcioslls"
									className="w-100 min-h-3px h-3px position-relative bg-primary-light" >
									{t('qmf_consulta_oferta.trrspcioslls')}
								</Text>
								<Group
									id="signioounFormanotciones"
									direction="vertical"
									className="w-100 h-auto border-radius-15px align-center position-relative p-32px mt-5px scrollable" >
									<List
										id="listaanotacionesofertas"
										src="@firebase.firestore.ofertavendedores.(@cookie.keyoferta).anotaciones"
										order={{"type":"asc","field":"timestamp"}}
										className="w-100 h-auto position-relative" >
										<Group
											id="groupdetalleanotaciones"
											direction="vertical"
											className="w-100 h-auto border-top border-bottom border-start border-end border-radius-20px position-relative p-10px mt-10px" >
											<Group
												id="groupfechaestadoof1694194680686"
												direction="auto"
												className="w-100 h-auto position-relative" >
												<Text
													id="textfechaantoacion"
													format={{"type":"date","day":"numeric","hour":"numeric","minute":"numeric","month":"numeric","weekday":"short","year":"numeric"}}
													src="timestamp"
													className="w-auto h-auto position-relative ff-gantaribold" >
													{t('qmf_consulta_oferta.textfechaantoacion')}
												</Text>
												<Text
													id="textfill1694194751257"
													className="w-fill h-auto position-relative" >
													{t('qmf_consulta_oferta.textfill1694194751257')}
												</Text>
												<Text
													id="textfestadioddf"
													tag="small"
													src="estado"
													className="w-auto h-auto border-radius-10px position-relative bg-accent p-5px text-text-on-accent" >
													{t('qmf_consulta_oferta.textfestadioddf')}
												</Text>
											</Group>
											<Text
												id="textanota"
												src="anotacion"
												className="w-auto h-auto position-relative" >
												{t('qmf_consulta_oferta.textanota')}
											</Text>
										</Group>
									</List>
								</Group>
							</Group>
						</Group>
					</Group>
				</Group>
				<Group
					id="groufffp1693650557147"
					direction="auto"
					className="w-auto h-auto align-end position-relative" >
					<Text
						id="texgestionarfgft1693650557147"
						onClick={texgestionarfgft1693650557147OnClick}
						tag="mark"
						className="w-auto h-auto border-radius-20px align-end valign-end position-relative bg-primary-light-to-primary-dark p-15px me-30px mt-15px mb-20px text-text-on-accent text-center" >
						{t('qmf_consulta_oferta.texgestionarfgft1693650557147')}
					</Text>
					<Text
						id="texvofdflvergfgft1693650557147"
						onClick={texvofdflvergfgft1693650557147OnClick}
						tag="mark"
						className="w-auto h-auto border-radius-20px align-end valign-end position-relative bg-primary-light-to-primary-dark p-15px me-30px mt-15px mb-20px text-text-on-accent text-center" >
						{t('qmf_consulta_oferta.texvofdflvergfgft1693650557147')}
					</Text>
				</Group>
			</Group>
      </FullscreenWrapper>
    </>
  );
};
