import React from 'react';
import { useDialogs } from '../hooks';
import { useFunctions } from '../hooks';

// Custom imports:
import { Modal, Group, Input, Button } from '../components';
import { useTranslation } from 'react-i18next';

const Qmf_comprobar_numero_demandaDialog = () => {
    const { run } = useFunctions();
    // Custom exports:
	const { t } = useTranslation();

    // Custom functions:
	const qmf_comprobar_numero_demanda_buttons_positiveOnClick = async (options) => {
		run([{"id":"popup1691262740727","function":"popup","dialog":"qmf_numero_correcto","if":{"what":"@firebase.firestore.demandacompradores.(@cookie.keydemandacompra).numeroenviado","is":"@property.compracion_numero","else":[{"id":"popup1691319676468","function":"popup","dialog":"qmf_error_numero"}]}},{"function":"hide","dialog":"qmf_comprobar_numero_demanda"}], options)
	}
	const qmf_comprobar_numero_demanda_buttons_negativeOnClick = async (options) => {
		run([{"function":"hide","dialog":"qmf_comprobar_numero_demanda"}], options)
	}

  return (
    
    
			<Modal id="qmf_comprobar_numero_demanda" centered size="md">
					<Modal.Header closeButton>
						<Modal.Title>{t('qmf_comprobar_numero_demanda.title')}</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Group
							id="group1691309398870"
							direction="vertical"
							className="w-100 h-auto position-relative" >
							<Input.Number
								id="inputtext1691262370927"
								label={t('qmf_comprobar_numero_demanda.inputtext1691262370927.label')}
								name="text"
								src="@property.compracion_numero"
							autoComplete="off"
								className="w-auto h-auto position-relative" />
						</Group>
					</Modal.Body>
					<Modal.Footer>
						<Button
							id="qmf_comprobar_numero_demanda_buttons_negative"
							onClick={qmf_comprobar_numero_demanda_buttons_negativeOnClick}
							variant="transparent"
							className="w-auto h-auto position-relative" >
							{t('qmf_comprobar_numero_demanda.qmf_comprobar_numero_demanda_buttons_negative')}
						</Button>
						<Button
							id="qmf_comprobar_numero_demanda_buttons_positive"
							onClick={qmf_comprobar_numero_demanda_buttons_positiveOnClick}
							variant="primary"
							className="w-auto h-auto position-relative" >
							{t('qmf_comprobar_numero_demanda.qmf_comprobar_numero_demanda_buttons_positive')}
						</Button>
					</Modal.Footer>
			</Modal>
    
  );
};

export default Qmf_comprobar_numero_demandaDialog;
