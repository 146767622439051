import Dexie from 'dexie';

const db = new Dexie('Codoozer-Web');

db.version(1).stores({ databases: 'id,data' });
export const useDexie = () => {
  const add = async (obj, options) => {
    await db.transaction('rw', db.databases, async () => {
      try {
        await db.databases.add(obj, options);
      } catch ({ name }) {
        if (name === 'ConstraintError') {
          return await update(obj.id, obj.data);
        }
        return obj;
      }
    });
  };

  const _delete = async (key) => {
    await db.transaction('rw', db.databases, async () => {
      await db.databases.delete(key);
    });
  };

  const get = async (key) => {
    const result = await db.databases.toArray();
    return result.find(({ id }) => id === key) || result;
  };

  const update = async (id, data) => {
    await db.transaction('rw', db.databases, async () => {
      await db.databases.update(id, { data });
      return data;
    });
  };
  return { add, db, delete: _delete, get, update };
};
