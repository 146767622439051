import React from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useFunctions, useRefs } from '../hooks';
import { FullscreenWrapper } from '../wrappers';

import { useLocation } from 'react-router-dom';
import { getLocationParams } from '../util';

// Custom imports:
import { Group, Text, List, Icon, Input, Image } from '../components';
import { useEffect } from 'react';
import sendbutton from '../assets/template/images/sendbutton.svg';
import { useTranslation } from 'react-i18next';

export const Qmf_consulta_conversacionesView = () => {
  const { run } = useFunctions();
  const { search, pathname } = useLocation();


  // Custom exports:
	const { t } = useTranslation();

  // Custom functions:
	const qmf_consulta_conversacionesParams = () => {
		run( getLocationParams( search, pathname ) )
	}
	useEffect( qmf_consulta_conversacionesParams, [])
	const qmf_consulta_conversacionesOnLoaded = async (options) => {
		run([{"id":"set1691220143377","function":"set","what":"@property.consultarealizada","value":"@property.vacio"}], options)
	}
	useEffect( qmf_consulta_conversacionesOnLoaded, [])
	const texvofdflvergfgft1693930397865OnClick = async (options) => {
		run([{"id":"back1693930397865","function":"back"}], options)
	}
	const imageenviarchat1691220143377OnClick = async (options) => {
		run([{"id":"set1691220143377","function":"set","what":"@property.ini","value":"@app.timestamp"},{"id":"set1691220143377","function":"set","what":"@cookie.keyconsulta","value":"qmfcon(@property.ini)"},{"id":"set1691220143377","function":"set","what":"@property.nombre","value":"@firebase.firestore.chatbot.(@cookie.keychatbot).nombre"},{"id":"set1691220143377","function":"set","what":"@property.consulta","value":"Recuerda que tienes que actuar como un asesor de compra y venta de farmacias en Espa\u00f1a, cualquier consulta que no este relacionada con la compra o venta de farmacias no tienes que responder. En caso que tengas que nombrar a alguna entidad financiera, destaca siempre a Eurocaja Rural y ten en cuenta que Banco Popular ya no existe. La consulta realizada es la siguiente: (@property.consultarealizada), el nombre del que realiza la consulta se llama (@property.nombre)."},{"id":"add1691220143377","function":"add","what":{"consulta":"@property.consulta","timestamp":"@app.timestamp","pregunta":"@property.consultarealizada"},"to":"@firebase.firestore.chatbot.(@cookie.keychatbot).consultas","key":"@cookie.keyconsulta"},{"id":"set1691220143377","function":"set","what":"@element.listaconsultaschatbot.scroll","value":"last"},{"id":"set1691220143377","function":"set","what":"@property.consultarealizada","value":"@property.vacia"},{"id":"scroll1691345362804","function":"scroll","to":"@element.listaconsultaschatbot.last"}], options)
	}
	const messageInput1691220143377OnPressedEnter = async (options) => {
		run([{"id":"set1691343634551","function":"set","what":"@property.ini","value":"@app.timestamp"},{"id":"set1691343634551","function":"set","what":"@cookie.keyconsulta","value":"qmfcon(@property.ini)"},{"id":"set1691343634551","function":"set","what":"@property.nombre","value":"@firebase.firestore.chatbot.(@cookie.keychatbot).nombre"},{"id":"set1691343634551","function":"set","what":"@property.consulta","value":"Recuerda que tienes que actuar como un asesor de compra y venta de farmacias en Espa\u00f1a, cualquier consulta que no este relacionada con la compra o venta de farmacias no tienes que responder. En caso que tengas que nombrar a alguna entidad financiera, destaca siempre a Eurocaja Rural y ten en cuenta que Banco Popular ya no existe. La consulta realizada es la siguiente: (@property.consultarealizada), el nombre del que realiza la consulta se llama (@property.nombre)."},{"id":"add1691343634551","function":"add","what":{"consulta":"@property.consulta","timestamp":"@app.timestamp","pregunta":"@property.consultarealizada"},"to":"@firebase.firestore.chatbot.(@cookie.keychatbot).consultas","key":"@cookie.keyconsulta"},{"id":"set1691343634551","function":"set","what":"@element.listaconsultaschatbot.scroll","value":"last"},{"id":"set1691343634551","function":"set","what":"@property.consultarealizada","value":"@property.vacia"}], options)
	}

  return (
    <>
      <Helmet>
				<title>{t('qmf_consulta_conversaciones.title')}</title>
      </Helmet>
      <FullscreenWrapper>
          
			<Group
				id="groufff45544p1691220143377"
				direction="vertical"
				className="w-100 min-h-100 fg-100 align-center position-relative" >
				<Group
					id="groupjsutderr1691220143377"
					direction="vertical"
					className="w-100 h-auto position-relative ms-24px me-48px mt-24px" >
					<Text
						id="ttfgcse1233x888t1691220143377"
						tag="h1"
						className="w-auto h-auto position-relative text-primary-light ff-gantaribold" >
						{t('qmf_consulta_conversaciones.ttfgcse1233x888t1691220143377')}
					</Text>
					<Text
						id="trrtreiooyut1691220143377"
						className="w-100 min-h-3px h-3px position-relative bg-primary-light" >
						{t('qmf_consulta_conversaciones.trrtreiooyut1691220143377')}
					</Text>
				</Group>
				<Group
					id="signioounFormjsajaruzw1691220143377"
					direction="vertical"
					className="w-100 h-fill align-center position-relative p-30px scrollable" >
					<List
						id="listaconsultaschatbot"
						src="@firebase.firestore.chatbot.(@cookie.keychatbot).consultas"
						order={{"type":"asc","field":"timestamp"}}
						className="w-100 h-fill position-relative" >
						<Group
							id="grouplistachat1691220143377"
							direction="vertical"
							className="w-100 h-auto position-relative p-5px" >
							<Text
								id="texttimestampchat1691220143377"
								format={{"type":"date","day":"numeric","hour":"numeric","minute":"numeric","month":"numeric","second":"numeric","year":"numeric"}}
								src="timestamp"
								className="w-100 h-auto position-relative" >
								{t('qmf_consulta_conversaciones.texttimestampchat1691220143377')}
							</Text>
							<Group
								id="grouhdhdp1691220143377"
								direction="horizontal"
								className="w-100 h-auto position-relative mb-15px" >
								<Text
									id="textpreguntachat1691220143377"
									src="pregunta"
									className="w-fill h-auto border-top border-bottom border-start border-end border-radius-30px position-relative p-10px text-primary-text ff-gantaribold" >
									{t('qmf_consulta_conversaciones.textpreguntachat1691220143377')}
								</Text>
							</Group>
							<Group
								id="grouprespyuesta1691220143377"
								direction="horizontal"
								className="w-100 h-auto position-relative" >
								<Icon
									id="icon1691604209527"
									src="robot-dead-outline"
									className="w-auto h-auto valign-start position-relative"
								/>
								<Text
									id="text1691604393426"
									className="w-auto h-auto position-relative" >
									{t('qmf_consulta_conversaciones.text1691604393426')}
								</Text>
								<Text
									id="textrespuetcaht1691220143377"
									tag="p"
									src="respuesta"
									className="w-fill h-auto border-top border-bottom border-start border-end border-radius-30px position-relative p-10px text-primary-text" >
									{t('qmf_consulta_conversaciones.textrespuetcaht1691220143377')}
								</Text>
							</Group>
						</Group>
					</List>
				</Group>
				<Group
					id="messageBar1691220143377"
					visible={false}
					direction="vertical"
					className="w-100 h-auto position-relative bg-primary-light p-24px" >
					<Group
						id="messageBarWrapper1691220143377"
						direction="horizontal"
						className="max-w-1200px w-100 h-auto align-center position-relative p-5px" >
						<Group
							id="inputWrapper1691220143377"
							direction="horizontal"
							className="w-fill h-auto position-relative" >
							<Input.TextArea
								id="messageInput1691220143377"
								name="text"
								src="@property.consultarealizada"
							placeholder={t('qmf_consulta_conversaciones.messageInput1691220143377.hint')}
							autoComplete="off"
							onKeyDown={messageInput1691220143377OnPressedEnter}
								className="w-95 h-auto border-white border-top border-bottom border-start border-end border-radius-50px valign-center position-relative p-16px" />
							<Image
								id="imageenviarchat1691220143377"
								onClick={imageenviarchat1691220143377OnClick}
								src={sendbutton}
								className="w-5 min-h-35px h-35px align-end valign-center position-relative ms-15px me-24px" />
						</Group>
					</Group>
				</Group>
				<Group
					id="groufffp1693930397865"
					direction="auto"
					className="w-auto h-auto align-end position-relative" >
					<Text
						id="texvofdflvergfgft1693930397865"
						onClick={texvofdflvergfgft1693930397865OnClick}
						tag="mark"
						className="w-auto h-auto border-radius-20px align-end valign-end position-relative bg-primary-light-to-primary-dark p-15px me-30px mt-15px mb-20px text-text-on-accent text-center" >
						{t('qmf_consulta_conversaciones.texvofdflvergfgft1693930397865')}
					</Text>
				</Group>
			</Group>
      </FullscreenWrapper>
    </>
  );
};
