import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFunctions } from '../hooks';
import DoyoBadge from '../components/DoyoBadge';

// Custom imports:
import { Group, Nav, Dropdown, Icon, Text, Image } from '../components';
import icon_512 from '../assets/icon/icon_512.png';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Container from 'react-bootstrap/Container';
import { Link } from 'react-router-dom'

export const Qmf_portadaWrapper = ({ children }) => {
  const { t } = useTranslation();
  const { run } = useFunctions();
  
  // Custom consts:

  // Custom functions:
	const Qmf_inicio_sesionItemsMenu1691311371688OnClick = async (options) => {
		run([{"id":"goto1692897156400","function":"goto","view":"login"}], options)
	}
	const Qmf_administrodoresItemsMenu1693590169749OnClick = async (options) => {
		run([{"id":"logout1693590270091","function":"logout","provider":"firebase"},{"id":"goto1693590270091","function":"goto","view":"qmfhome"}], options)
	}
	const Qmf_administrodoresItemsMenu1694132148675OnClick = async (options) => {
		run([{"id":"goto1694132148675","function":"goto","view":"qmf_administradores"}], options)
	}
	const Qmf_politicasItemsMenu1700859222083OnClick = async (options) => {
		run([{"id":"popup1700859251583","function":"popup","dialog":"qmf_politica_cookies"}], options)
	}

  return (
    <main id="Qmf_portadaWrapper" className="view h-fill">
        
				<Navbar bg="white" expand="lg" sticky="top" className="z-index-10 px-3 border-bottom">
					<Navbar.Toggle aria-controls="basic-navbar-nav" className="border-0 rounded-circle me-3 p-1" />
					<Navbar.Offcanvas id="basic-navbar-nav" className="w-auto d-lg-none">
					<Offcanvas.Body className="p-0">
						<Nav
							id="qmf_portada_actionbar_menu"
							className="w-auto h-auto align-end valign-center position-relative" >
							<Nav.Item title={t('qmf_politicas.items.menu1700859222083.title')} onClick={Qmf_politicasItemsMenu1700859222083OnClick} />
						</Nav>
						<Group
							id="qmf_portada_actionbar_content"
							className="w-auto h-auto position-relative" >
							<Group
								id="groupporta1693589698850"
								direction="auto"
								className="w-100 h-auto position-relative" >
								<Group
									id="groupone1693589698850"
									if={{"what":"@firebase.user.id","is_not":null}}
									container={1}
									direction="vertical"
									className="w-auto h-auto valign-center position-relative mt-15px" >
									<Dropdown
										id="dropdown1693589698850"
										className="w-auto h-auto position-relative"
										variant="outline-accent"
										title={<>

										<Icon
											id="dropdown1693589698850Icon"
											src="face-man-profile"
											className="w-auto h-auto position-relative"
										/>
										<Text
											id="dropdown1693589698850Title"
											src="@firebase.firestore.usuarios.(@firebase.user.id).nombre"
											className="w-auto h-auto position-relative" >
											{t('qmf_portada.dropdown1693589698850Title')}
										</Text>
									</>} >
										<Dropdown.Item title={t('qmf_administrodores.items.menu1693590133245.title')} />
										<Dropdown.Item title={t('qmf_administrodores.items.menu1694132148675.title')} onClick={Qmf_administrodoresItemsMenu1694132148675OnClick} href="qmf_administradores" />
										<Dropdown.Item title={t('qmf_administrodores.items.menu1693590169749.title')} onClick={Qmf_administrodoresItemsMenu1693590169749OnClick} href="qmfhome" />
									</Dropdown>
								</Group>
								<Group
									id="group1693589698850"
									if={{"what":"@firebase.user.id","is":null}}
									container={1}
									direction="horizontal"
									className="w-auto h-auto valign-center position-relative mt-15px" >
									<Dropdown
										id="dropdowntwo1693589698850"
										className="w-auto h-auto position-relative"
										variant="accent"
										title={<>

										<Text
											id="dropdowntwo1693589698850Title"
											className="w-auto h-auto position-relative" >
											{t('qmf_portada.dropdowntwo1693589698850Title')}
										</Text>
									</>} >
										<Dropdown.Item title={t('qmf_inicio_sesion.items.menu1691311371688.title')} onClick={Qmf_inicio_sesionItemsMenu1691311371688OnClick} href="login" />
									</Dropdown>
								</Group>
							</Group>
						</Group>
					</Offcanvas.Body>
					</Navbar.Offcanvas>
						<Navbar.Brand>
							<Link to="/">
								<Image
									id="qmf_portada_actionbar_logo"
									src={icon_512}
									className="w-auto min-h-40px h-40px position-relative" />
							</Link>
						</Navbar.Brand>
				<Group className="w-auto h-auto d-none d-lg-flex">
						<Nav
							id="qmf_portada_actionbar_menu"
							className="w-auto h-auto align-end valign-center position-relative" >
							<Nav.Item title={t('qmf_politicas.items.menu1700859222083.title')} onClick={Qmf_politicasItemsMenu1700859222083OnClick} />
						</Nav>
				</Group>
				<Group className="w-fill h-auto" />
				<Group className="w-auto h-auto d-none d-lg-flex">
						<Group
							id="qmf_portada_actionbar_content"
							className="w-auto h-auto position-relative" >
							<Group
								id="groupporta1693589698850"
								direction="auto"
								className="w-100 h-auto position-relative" >
								<Group
									id="groupone1693589698850"
									if={{"what":"@firebase.user.id","is_not":null}}
									container={1}
									direction="vertical"
									className="w-auto h-auto valign-center position-relative mt-15px" >
									<Dropdown
										id="dropdown1693589698850"
										className="w-auto h-auto position-relative"
										variant="outline-accent"
										title={<>

										<Icon
											id="dropdown1693589698850Icon"
											src="face-man-profile"
											className="w-auto h-auto position-relative"
										/>
										<Text
											id="dropdown1693589698850Title"
											src="@firebase.firestore.usuarios.(@firebase.user.id).nombre"
											className="w-auto h-auto position-relative" >
											{t('qmf_portada.dropdown1693589698850Title')}
										</Text>
									</>} >
										<Dropdown.Item title={t('qmf_administrodores.items.menu1693590133245.title')} />
										<Dropdown.Item title={t('qmf_administrodores.items.menu1694132148675.title')} onClick={Qmf_administrodoresItemsMenu1694132148675OnClick} href="qmf_administradores" />
										<Dropdown.Item title={t('qmf_administrodores.items.menu1693590169749.title')} onClick={Qmf_administrodoresItemsMenu1693590169749OnClick} href="qmfhome" />
									</Dropdown>
								</Group>
								<Group
									id="group1693589698850"
									if={{"what":"@firebase.user.id","is":null}}
									container={1}
									direction="horizontal"
									className="w-auto h-auto valign-center position-relative mt-15px" >
									<Dropdown
										id="dropdowntwo1693589698850"
										className="w-auto h-auto position-relative"
										variant="accent"
										title={<>

										<Text
											id="dropdowntwo1693589698850Title"
											className="w-auto h-auto position-relative" >
											{t('qmf_portada.dropdowntwo1693589698850Title')}
										</Text>
									</>} >
										<Dropdown.Item title={t('qmf_inicio_sesion.items.menu1691311371688.title')} onClick={Qmf_inicio_sesionItemsMenu1691311371688OnClick} href="login" />
									</Dropdown>
								</Group>
							</Group>
						</Group>
				</Group>
				</Navbar>
		
				<Group className="flex-grow-1">{children}</Group>
        <DoyoBadge />
    </main>
  );
};
