import React from 'react';
import { useDialogs } from '../hooks';
import { useFunctions } from '../hooks';

// Custom imports:
import { Modal, Group, Input, Button } from '../components';
import { useTranslation } from 'react-i18next';

const Crear_subastaDialog = () => {
    const { run } = useFunctions();
    // Custom exports:
	const { t } = useTranslation();

    // Custom functions:
	const crear_subasta_buttons_positiveOnClick = async (options) => {
		run([{"id":"set1685867416129","function":"set","what":"@firebase.firestore.ofertas.(@element.listaofertas.selected).preciosalida","value":"@property.preciosalida"},{"id":"set1685867489580","function":"set","what":"@firebase.firestore.ofertas.(@element.listaofertas.selected).puja","value":"@property.preciosalida"},{"id":"set1685867667907","function":"set","what":"@firebase.firestore.ofertas.(@element.listaofertas.selected).fechafin","value":"@property.fechafin"},{"id":"set1685869968068","function":"set","what":"@firebase.firestore.ofertas.(@element.listaofertas.selected).subasta","value":true},{"function":"hide","dialog":"crear_subasta"}], options)
	}
	const crear_subasta_buttons_negativeOnClick = async (options) => {
		run([{"function":"hide","dialog":"crear_subasta"}], options)
	}

  return (
    
    
			<Modal id="crear_subasta">
					<Modal.Header>
						<Modal.Title>{t('crear_subasta.title')}</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Group
							id="group1685867239666"
							direction="vertical"
							className="w-100 h-auto position-relative p-10px scrollable" >
							<Input.TextArea
								id="inputtext1685867239666"
								label={t('crear_subasta.inputtext1685867239666.label')}
								name="text"
								src="@property.preciosalida"
							placeholder={t('crear_subasta.inputtext1685867239666.hint')}
							autoComplete="off"
								className="w-100 h-auto position-relative mb-10px" />
							<Input.Date
								id="inputtext1685867608637"
								label={t('crear_subasta.inputtext1685867608637.label')}
								name="text"
								src="@property.fechafin"
							placeholder={t('crear_subasta.inputtext1685867608637.hint')}
							autoComplete="off"
								className="w-100 h-auto position-relative mb-10px" />
						</Group>
					</Modal.Body>
					<Modal.Footer>
						<Button
							id="crear_subasta_buttons_negative"
							onClick={crear_subasta_buttons_negativeOnClick}
							variant="transparent"
							className="w-auto h-auto position-relative" >
							{t('crear_subasta.crear_subasta_buttons_negative')}
						</Button>
						<Button
							id="crear_subasta_buttons_positive"
							onClick={crear_subasta_buttons_positiveOnClick}
							variant="primary"
							className="w-auto h-auto position-relative" >
							{t('crear_subasta.crear_subasta_buttons_positive')}
						</Button>
					</Modal.Footer>
			</Modal>
    
  );
};

export default Crear_subastaDialog;
