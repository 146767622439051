import React from 'react';
import { useDialogs } from '../hooks';
import { useFunctions } from '../hooks';

// Custom imports:
import { Modal, Group, Input, Button } from '../components';
import { useTranslation } from 'react-i18next';

const Qmf_gestionarofertaDialog = () => {
    const { run } = useFunctions();
    // Custom exports:
	const { t } = useTranslation();

    // Custom functions:
	const qmf_gestionaroferta_buttons_positiveOnClick = async (options) => {
		run([{"id":"set1693730426696","function":"set","what":"@property.nombre","value":"@firebase.firestore.ofertavendedores.(@cookie.keyoferta).nombre"},{"id":"set1700296621961","function":"set","what":"@property.nombreadmin","value":"@firebase.firestore.usuarios.(@firebase.user.id).nombre"},{"id":"add1700297631548","function":"add","what":{"anotacion":"@property.anotacion","timestamp":"@app.timestamp","estado":"@property.estado","administrador":"@property.nombreadmin"},"to":"@firebase.firestore.ofertavendedores.(@cookie.keyoferta).anotaciones","if":{"what":"@property.estado","is_not":"@propety.vacio","else":[{"id":"popup1700297631548","function":"popup","dialog":"gestionaroferta"}]}},{"id":"add1693730426696","function":"add","what":{"timestamp":"@app.timestamp","anotacion":"La oferta\u00a0(@cookie.keyoferta) de (@property.nombre) ha cambiado a estado (@property.estado), gestionada por: (@property.nombreadmin)"},"to":"@firebase.firestore.historial","if":{"what":"@property.estado","is_not":"@propety.vacio","else":[{"id":"popup1693730426696","function":"popup","dialog":"gestionaroferta"}]}},{"id":"set1693730426696","function":"set","what":"@firebase.firestore.ofertavendedores.(@cookie.keyoferta).estado","value":"@property.estado","if":{"what":"@property.estado","is_not":"@property.vacio"}},{"id":"add1693730426696","function":"add","what":{"email":"ctorres@quieromifarma.es; germanmunoz1@gmail.com; ajpedraza@gmail.com","asunto":"Oferta (@cookie.keyoferta) (@property.estado)","contenido":"Ref:\u00a0(@cookie.keyoferta) de (@property.nombre). Estado: (@property.estado). Anotaci\u00f3n: (@property.anotacion) Gestionada por: (@property.nombreadmin)"},"to":"@firebase.firestore.enviarmail","if":{"what":"@property.estado","is_not":"@property.vacio"}},{"function":"hide","dialog":"qmf_gestionaroferta"}], options)
	}
	const qmf_gestionaroferta_buttons_negativeOnClick = async (options) => {
		run([{"function":"hide","dialog":"qmf_gestionaroferta"}], options)
	}
	const qmf_gestionarofertaOnLoaded = async (options) => {
		run([{"id":"set1693730426696","function":"set","what":"@property.estado","value":"@firebase.firestore.ofertavendedores.(@cookie.keyoferta).estado"},{"id":"set1693730426696","function":"set","what":"@property.anotacion","value":"@property.vacio"}], options)
	}

  return (
    
    
			<Modal id="qmf_gestionaroferta" onShow={qmf_gestionarofertaOnLoaded}>
					<Modal.Header closeButton>
						<Modal.Title>{t('qmf_gestionaroferta.title')}</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Group
							id="group1693730426696"
							direction="vertical"
							className="w-100 h-auto position-relative" >
							<Input.TextArea
								id="inputtext1693730426696"
								label={t('qmf_gestionaroferta.inputtext1693730426696.label')}
								name="text"
								src="@property.anotacion"
							placeholder={t('qmf_gestionaroferta.inputtext1693730426696.hint')}
							autoComplete="off"
								className="w-100 h-auto position-relative" />
							<Input.Select
								id="select1693730426696"
								label={t('qmf_gestionaroferta.select1693730426696.label')}
								name="select"
								src="@property.estado"
								dataSrc="@firebase.firestore.estados_ofertas"
								className="w-100 h-auto position-relative" >
							</Input.Select>
						</Group>
					</Modal.Body>
					<Modal.Footer>
						<Button
							id="qmf_gestionaroferta_buttons_negative"
							onClick={qmf_gestionaroferta_buttons_negativeOnClick}
							variant="transparent"
							className="w-auto h-auto position-relative" >
							{t('qmf_gestionaroferta.qmf_gestionaroferta_buttons_negative')}
						</Button>
						<Button
							id="qmf_gestionaroferta_buttons_positive"
							onClick={qmf_gestionaroferta_buttons_positiveOnClick}
							variant="primary"
							className="w-auto h-auto position-relative" >
							{t('qmf_gestionaroferta.qmf_gestionaroferta_buttons_positive')}
						</Button>
					</Modal.Footer>
			</Modal>
    
  );
};

export default Qmf_gestionarofertaDialog;
