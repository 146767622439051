import React from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useFunctions, useRefs } from '../hooks';
import { FullscreenWrapper } from '../wrappers';

import { useLocation } from 'react-router-dom';
import { getLocationParams } from '../util';

// Custom imports:
import { Group, Image, Text } from '../components';
import { useEffect } from 'react';
import oip from '../assets/oip.jpg';
import icon_512 from '../assets/icon/icon_512.png';
import { useTranslation } from 'react-i18next';

export const Coach1View = () => {
  const { run } = useFunctions();
  const { search, pathname } = useLocation();


  // Custom exports:
	const { t } = useTranslation();

  // Custom functions:
	const coach1Params = () => {
		run( getLocationParams( search, pathname ) )
	}
	useEffect( coach1Params, [])
	const coach1OnLoaded = async (options) => {
		run([{"id":"set1678215605055","function":"set","what":"@element.grupopregunta1.visible","value":true},{"id":"set1678215652332","function":"set","what":"@element.grupopregunta2.visible","value":false},{"id":"set1678215672431","function":"set","what":"@element.grupopregunta3.visible","value":false},{"id":"set1678215688660","function":"set","what":"@element.gruporespuesta1.visible","value":false},{"id":"set1678215713045","function":"set","what":"@element.gruporespuesta2.visible","value":false},{"id":"set1678215738277","function":"set","what":"@element.gruporespuesta3.visible","value":false},{"id":"set1678223760753","function":"set","what":"@property.pregunta1","value":"Hola, soy tu coach. \u00bfQu\u00e9 estas buscando?"},{"id":"set1678223885491","function":"set","what":"@property.pregunta2","value":"\u00a0\u00bfQu\u00e9 tipo de farmacia quieres comprar, en zona rural o en ciudad?"},{"id":"set1678223989089","function":"set","what":"@property.pregunta3","value":"\u00a0La farmacia\u00a0 que vendes, \u00bfSe encuentra en zona rural o en ciudad?"},{"id":"set1678224291769","function":"set","what":"@property.op11","value":"Quiero comprar una farmacia"},{"id":"set1678224369149","function":"set","what":"@property.op12","value":"Quiero vender mi farmacia"},{"id":"set1678224421978","function":"set","what":"@property.op21","value":"En zona rural"},{"id":"set1678224479293","function":"set","what":"@property.op22","value":"En ciudad"},{"id":"set1678224512402","function":"set","what":"@property.op31","value":"En zona rural"},{"id":"set1678224550916","function":"set","what":"@property.op32","value":"En ciudad"}], options)
	}
	useEffect( coach1OnLoaded, [])
	const continuar1OnClick = async (options) => {
		run([{"id":"set1678518570359","function":"set","what":"@property.pagcoach2","value":"uno"},{"id":"goto1678393855454","function":"goto","view":"coach2"}], options)
	}
	const op32OnClick = async (options) => {
		run([{"id":"set1678217470846","function":"set","what":"@element.op31.visible","value":false},{"id":"set1678217470846","function":"set","what":"@element.op32.visible","value":false},{"id":"set1678217470846","function":"set","what":"@element.gruporespuesta3.visible","value":true},{"id":"set1678217470846","function":"set","what":"@property.respuesta3","value":"@property.op32"},{"id":"set1678227074833","function":"set","what":"@element.continuar1.visible","value":true}], options)
	}
	const op31OnClick = async (options) => {
		run([{"id":"set1678217394338","function":"set","what":"@element.op31.visible","value":false},{"id":"set1678217394338","function":"set","what":"@element.op32.visible","value":false},{"id":"set1678217394338","function":"set","what":"@element.gruporespuesta3.visible","value":true},{"id":"set1678217394338","function":"set","what":"@property.respuesta3","value":"@property.op31"},{"id":"set1678227132428","function":"set","what":"@element.continuar1.visible","value":true}], options)
	}
	const op22OnClick = async (options) => {
		run([{"id":"set1678217075398","function":"set","what":"@element.op21.visible","value":false},{"id":"set1678217075398","function":"set","what":"@element.op22.visible","value":false},{"id":"set1678217075398","function":"set","what":"@element.gruporespuesta3.visible","value":true},{"id":"set1678217075398","function":"set","what":"@property.respuesta3","value":"@property.op22"},{"id":"set1678227150818","function":"set","what":"@element.continuar1.visible","value":true}], options)
	}
	const op21OnClick = async (options) => {
		run([{"id":"set1678216658958","function":"set","what":"@element.op21.visible","value":false},{"id":"set1678216658959","function":"set","what":"@element.op22.visible","value":false},{"id":"set1678216658959","function":"set","what":"@element.gruporespuesta2.visible","value":true},{"id":"set1678217021650","function":"set","what":"@property.respuesta2","value":"@property.op21"},{"id":"set1678227159480","function":"set","what":"@element.continuar1.visible","value":true}], options)
	}
	const op12OnClick = async (options) => {
		run([{"id":"set1678216375412","function":"set","what":"@element.op11.visible","value":false},{"id":"set1678216375412","function":"set","what":"@element.op12.visible","value":false},{"id":"set1678216539502","function":"set","what":"@element.op31.visible","value":true},{"id":"set1678216548292","function":"set","what":"@element.op32.visible","value":true},{"id":"set1678216375412","function":"set","what":"@element.grupopregunta3.visible","value":true},{"id":"set1678216375412","function":"set","what":"@element.gruporespuesta1.visible","value":true},{"id":"set1678217203530","function":"set","what":"@property.respuesta1","value":"@property.op12"},{"id":"set1685428816502","function":"set","what":"@cookie.formulario","value":"vendedor"}], options)
	}
	const op11OnClick = async (options) => {
		run([{"id":"set1678216156750","function":"set","what":"@element.op11.visible","value":false},{"id":"set1678216204773","function":"set","what":"@element.op12.visible","value":false},{"id":"set1678216441723","function":"set","what":"@element.op21.visible","value":true},{"id":"set1678216451190","function":"set","what":"@element.op22.visible","value":true},{"id":"set1678216247439","function":"set","what":"@element.grupopregunta2.visible","value":true},{"id":"set1678216305735","function":"set","what":"@element.gruporespuesta1.visible","value":true},{"id":"set1678217146523","function":"set","what":"@property.respuesta1","value":"@property.op11"},{"id":"set1685428791655","function":"set","what":"@cookie.formulario","value":"comprador"}], options)
	}
	const conectate1680553336296OnClick = async (options) => {
		run([{"id":"goto1680553336296","function":"goto","view":"login"}], options)
	}
	const op1680553336296OnClick = async (options) => {
		run([{"id":"goto1680553336296","function":"goto","view":"coach1"}], options)
	}

  return (
    <>
      <Helmet>
				<title>{t('coach1.title')}</title>
      </Helmet>
      <FullscreenWrapper>
          
			<Group
				id="group1685427507067"
				direction="vertical"
				className="w-100 min-h-100 fg-100 position-relative" >
				<Group
					id="grupoinicio1680553336296"
					visible={false}
					direction="horizontal"
					className="w-fill min-h-10 fg-10 align-center valign-center position-relative bg-primary-light-to-primary-dark p-10px" >
					<Image
						id="image1680554304838"
						src={icon_512}
						className="w-60px h-auto align-center valign-center position-relative ms-20px" />
					<Text
						id="text1680627105412"
						className="w-fill h-auto position-relative" >
						{t('coach1.text1680627105412')}
					</Text>
					<Text
						id="op1680553336296"
						visible={false}
						onClick={op1680553336296OnClick}
						className="max-w-100px w-auto h-auto border-radius-15px align-end valign-center position-relative bg-primary-dark p-12px m-20px text-text-on-primary f-12px text-center" >
						{t('coach1.op1680553336296')}
					</Text>
					<Text
						id="conectate1680553336296"
						onClick={conectate1680553336296OnClick}
						className="max-w-100px w-auto h-auto border-radius-15px align-end valign-center position-relative bg-text-on-accent p-12px m-10px text-primary-dark f-12px text-center" >
						{t('coach1.conectate1680553336296')}
					</Text>
				</Group>
				<Group
					id="group1685427630950"
					direction="vertical"
					className="w-100 h-fill position-relative" >
					<Text
						id="text1685427642480"
						className="w-100 h-auto position-relative" >
						{t('coach1.text1685427642480')}
					</Text>
				</Group>
				<Group
					id="grupoinicio"
					direction="vertical"
					className="w-100 h-auto valign-end position-relative" >
					<Text
						id="text1678202500365"
						className="w-100 h-fill valign-end position-relative" >
						{t('coach1.text1678202500365')}
					</Text>
					<Group
						id="group1678201206134"
						direction="vertical"
						className="w-100 h-auto position-relative" >
						<Group
							id="grupopregunta1"
							direction="horizontal"
							className="max-w-900px w-90 h-auto align-center position-relative p-3px" >
							<Image
								id="image1678202085599"
								src={oip}
								className="w-15 h-auto border-radius-40px align-center valign-center position-relative m-20px" />
							<Group
								id="group1678208141283"
								direction="vertical"
								className="w-auto h-auto border-radius-30px position-relative bg-primary-light-to-primary-dark" >
								<Text
									id="text1678202234866"
									src="@property.pregunta1"
									className="w-auto h-auto border-radius-20px align-center position-relative p-20px text-text-on-accent f-15px text-center" >
									{t('coach1.text1678202234866')}
								</Text>
							</Group>
						</Group>
						<Group
							id="gruporespuesta1"
							visible={false}
							direction="horizontal"
							className="max-w-900px w-90 h-auto align-end position-relative p-10px" >
							<Group
								id="group1678214118596"
								direction="vertical"
								className="w-auto h-auto border-radius-30px position-relative bg-gray-to-white" >
								<Text
									id="text1678214118596"
									src="@property.respuesta1"
									className="w-auto h-auto align-center position-relative p-20px f-15px text-center" >
									{t('coach1.text1678214118596')}
								</Text>
							</Group>
						</Group>
						<Group
							id="grupopregunta2"
							visible={false}
							direction="horizontal"
							className="max-w-900px w-90 h-auto align-center position-relative p-3px" >
							<Image
								id="image1678214406091"
								src={oip}
								className="w-15 h-auto border-radius-40px align-center valign-center position-relative m-20px" />
							<Group
								id="group1678214406091"
								direction="vertical"
								className="w-auto h-auto border-radius-30px position-relative bg-primary-light-to-primary-dark" >
								<Text
									id="text1678214406091"
									src="@property.pregunta2"
									className="w-auto h-auto align-center position-relative p-20px text-text-on-accent f-15px text-center" >
									{t('coach1.text1678214406091')}
								</Text>
							</Group>
						</Group>
						<Group
							id="gruporespuesta2"
							visible={false}
							direction="horizontal"
							className="max-w-900px w-90 h-auto align-end position-relative p-10px" >
							<Group
								id="group1678214419829"
								direction="vertical"
								className="w-auto h-auto border-radius-30px position-relative bg-gray-to-white" >
								<Text
									id="text1678214419829"
									src="@property.respuesta2"
									className="w-auto h-auto align-center position-relative p-20px f-15px text-center" >
									{t('coach1.text1678214419829')}
								</Text>
							</Group>
						</Group>
						<Group
							id="grupopregunta3"
							visible={false}
							direction="horizontal"
							className="max-w-900px w-90 h-auto align-center position-relative p-3px" >
							<Image
								id="image1678214456083"
								src={oip}
								className="w-15 h-auto border-radius-40px align-center valign-center position-relative m-20px" />
							<Group
								id="group1678214456083"
								direction="vertical"
								className="w-auto h-auto border-radius-30px position-relative bg-primary-light-to-primary-dark" >
								<Text
									id="text1678214456083"
									src="@property.pregunta3"
									className="w-auto h-auto align-center position-relative p-20px text-text-on-accent f-15px text-center" >
									{t('coach1.text1678214456083')}
								</Text>
							</Group>
						</Group>
						<Group
							id="gruporespuesta3"
							visible={false}
							direction="horizontal"
							className="max-w-900px w-90 h-auto align-end position-relative p-10px" >
							<Group
								id="group1678214453471"
								direction="vertical"
								className="w-auto h-auto border-radius-30px position-relative bg-gray-to-white" >
								<Text
									id="text1678214453471"
									src="@property.respuesta3"
									className="w-auto h-auto align-center position-relative p-20px f-15px text-center" >
									{t('coach1.text1678214453471')}
								</Text>
							</Group>
						</Group>
					</Group>
					<Text
						id="text1678200556617"
						className="w-100 h-fill align-center valign-end position-relative text-center" >
						{t('coach1.text1678200556617')}
					</Text>
					<Text
						id="op11"
						onClick={op11OnClick}
						src="@property.op11"
						className="max-w-900px w-90 h-auto border-radius-15px align-center valign-end position-relative bg-accent p-20px m-20px text-text-on-accent text-center" >
						{t('coach1.op11')}
					</Text>
					<Text
						id="op12"
						onClick={op12OnClick}
						src="@property.op12"
						className="max-w-900px w-90 h-auto border-radius-15px align-center valign-end position-relative bg-accent p-20px mt-20px mb-100px text-text-on-accent text-center" >
						{t('coach1.op12')}
					</Text>
					<Text
						id="op21"
						visible={false}
						onClick={op21OnClick}
						src="@property.op21"
						className="max-w-900px w-90 h-auto border-radius-15px align-center valign-end position-relative bg-accent p-20px m-20px text-text-on-accent text-center" >
						{t('coach1.op21')}
					</Text>
					<Text
						id="op22"
						visible={false}
						onClick={op22OnClick}
						src="@property.op22"
						className="max-w-900px w-90 h-auto border-radius-15px align-center valign-end position-relative bg-accent p-20px m-20px text-text-on-accent text-center" >
						{t('coach1.op22')}
					</Text>
					<Text
						id="op31"
						visible={false}
						onClick={op31OnClick}
						src="@property.op31"
						className="max-w-900px w-90 h-auto border-radius-15px align-center valign-end position-relative bg-accent p-20px m-20px text-text-on-accent text-center" >
						{t('coach1.op31')}
					</Text>
					<Text
						id="op32"
						visible={false}
						onClick={op32OnClick}
						src="@property.op32"
						className="max-w-900px w-90 h-auto border-radius-15px align-center valign-end position-relative bg-accent p-20px m-20px text-text-on-accent text-center" >
						{t('coach1.op32')}
					</Text>
					<Text
						id="continuar1"
						visible={false}
						onClick={continuar1OnClick}
						className="max-w-900px w-90 h-auto border-radius-15px align-center valign-end position-relative bg-accent p-20px m-20px text-text-on-accent text-center" >
						{t('coach1.continuar1')}
					</Text>
					<Text
						id="text1678223685041"
						className="w-100 h-fill align-center valign-end position-relative text-center" >
						{t('coach1.text1678223685041')}
					</Text>
				</Group>
			</Group>
      </FullscreenWrapper>
    </>
  );
};
