import React from 'react';
import {
  Bar as BarChart,
  Doughnut as DoughnutChart,
  Line as LineChart,
  Pie as PieChart,
  PolarArea as PolarChart,
  Radar as RadarChart,
  Scatter as ScatterChart,
} from 'react-chartjs-2';
import Component from './Component';
import { useSource } from '../hooks';

const getGradients = (datasets) => {
  return datasets.map((dataset) => {
    const { backgroundColor } = dataset;
    if (Array.isArray(backgroundColor)) {
      return {
        ...dataset,
        backgroundColor: function (context) {
          const chart = context.chart;
          const { ctx, chartArea } = chart;

          if (!chartArea) {
            // This case happens on initial chart load
            return;
          }
          let gradient = ctx.createLinearGradient(
            0,
            chartArea.bottom,
            0,
            chartArea.top
          );
          backgroundColor.forEach((bgColor, index) => {
            const perc = index / backgroundColor.length;
            gradient.addColorStop(perc, bgColor);
          });
          return gradient;
        },
      };
    }
    return dataset;
  });
};

export const Bar = (props) => {
  let { data = {}, options = {} } = props;
  let { datasets = [] } = data;
  datasets = getGradients(datasets);
  data = { ...data, datasets };
  const { elements = {} } = options;
  const { bar = {} } = elements;
  const { backgroundColor } = bar;
  return (
    <Chart
      {...props}
      backgroundColor={backgroundColor}
      data={data}
      component={BarChart}
    />
  );
};

export const Line = (props) => {
  let { data = {}, options = {} } = props;
  let { datasets = [] } = data;
  datasets = getGradients(datasets);
  data = { ...data, datasets };
  const { elements = {} } = options;
  const { line = {} } = elements;
  const { backgroundColor } = line;
  return (
    <Chart
      {...props}
      backgroundColor={backgroundColor}
      data={data}
      component={LineChart}
    />
  );
};

export const Doughnut = (props) => {
  return <Chart {...props} component={DoughnutChart} />;
};

export const Scatter = (props) => {
  return <Chart {...props} component={ScatterChart} />;
};

export const Pie = (props) => {
  return <Chart {...props} component={PieChart} />;
};

export const Polar = (props) => {
  return <Chart {...props} component={PolarChart} />;
};

export const Radar = (props) => {
  return <Chart {...props} component={RadarChart} />;
};

export const Chart = ({
  backgroundColor,
  className = '',
  component = BarChart,
  data: dataProps = {},
  src: dataSource,
  options = {},
  ...props
}) => {
  let defaultData = { ...dataProps };
  const { datasets } = defaultData;
  let datasetsSource;
  if (!datasets) {
    defaultData.datasets = [];
    defaultData.labels = [];
  }
  if (dataSource) {
    datasetsSource = `${dataSource}.datasets`;
  }
  let { value: data = defaultData } = useSource(dataSource);
  let { value: srcDatasets = data?.datasets } = useSource(datasetsSource);

  if (!data || data?._equals({})) {
    data = defaultData;
  }
  if (srcDatasets && !srcDatasets?._equals({})) {
    data = data._clone();
    data._merge({ datasets: srcDatasets });
  }

  const datasetsWithBackground = data.datasets.map((ds) => {
    if (backgroundColor) {
      return { ...ds, backgroundColor };
    }
    return ds;
  });
  data = {
    ...data,
    datasets: getGradients(datasetsWithBackground),
  };

  return (
    <div className={`chart d-flex align-items-center ${className}`.trim()}>
      <Component
        {...props}
        data={data}
        options={{ responsive: true, maintainAspectRatio: false, ...options }}
        component={component}
      />
    </div>
  );
};

Chart.Bar = Bar;
Chart.Line = Line;
Chart.Doughnut = Doughnut;
Chart.Scatter = Scatter;
Chart.Pie = Pie;
Chart.Polar = Polar;
Chart.Radar = Radar;

Chart.displayName = 'Chart';
export default Chart;
