import React from 'react';
import { useDialogs } from '../hooks';
import { useFunctions } from '../hooks';

// Custom imports:
import { Modal, Input, Button } from '../components';
import { useTranslation } from 'react-i18next';

const PujaDialog = () => {
    const { run } = useFunctions();
    // Custom exports:
	const { t } = useTranslation();

    // Custom functions:
	const puja_buttons_positiveOnClick = async (options) => {
		run([{"id":"set1681555006796","function":"set","what":"@property.pujasi","value":true,"if":{"what":"@property.puja","more_than":"@firebase.firestore.ofertas.(@property.indicesubasta).puja","else":[{"id":"set1681579039854","function":"set","what":"@property.pujasi","value":false}]}},{"id":"set1681578910214","function":"set","what":"@firebase.firestore.ofertas.(@property.indicesubasta).puja","value":"@property.puja","if":{"what":"@property.puja","more_than":"@firebase.firestore.ofertas.(@property.indicesubasta).puja"}},{"id":"popup1681577347022","function":"popup","dialog":"exitopuja","if":{"what":"@property.pujasi","else":[{"id":"popup1681577495825","function":"popup","dialog":"errorpuja"}],"is":true}},{"function":"hide","dialog":"puja"}], options)
	}

  return (
    
    
			<Modal id="puja">
					<Modal.Body>
						<Input.Text
							id="inputpuja"
							name="text"
							src="@property.puja"
						autoComplete="off"
							className="w-auto h-auto position-relative" />
					</Modal.Body>
					<Modal.Footer>
						<Button
							id="puja_buttons_positive"
							onClick={puja_buttons_positiveOnClick}
							variant="primary"
							className="w-auto h-auto position-relative" >
							{t('puja.puja_buttons_positive')}
						</Button>
					</Modal.Footer>
			</Modal>
    
  );
};

export default PujaDialog;
