import React from 'react';
import BootstrapCarousel from 'react-bootstrap/Carousel';
import Component from './Component';

export const Carousel = ({ children, ...props }) => {
  return (
    <Component {...props} component={BootstrapCarousel}>
      {children}
    </Component>
  );
};

Carousel.Caption = BootstrapCarousel.Caption;
Carousel.Item = BootstrapCarousel.Item;

Carousel.displayName = 'Carousel';
export default Carousel;
