import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFunctions } from '../hooks';
import DoyoBadge from '../components/DoyoBadge';

// Custom imports:
import { Group, Dropdown, Icon, Text, Nav, Image } from '../components';
import icon_512 from '../assets/icon/icon_512.png';
import qmflargo from '../assets/qmflargo.png';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Container from 'react-bootstrap/Container';
import { Link } from 'react-router-dom'

export const Wr_menu_portadaWrapper = ({ children }) => {
  const { t } = useTranslation();
  const { run } = useFunctions();
  
  // Custom consts:

  // Custom functions:
	const conectate1686438900823OnClick = async (options) => {
		run([{"id":"goto1686438900823","function":"goto","view":"login"}], options)
	}
	const registrate1686438900823OnClick = async (options) => {
		run([{"id":"goto1686438900823","function":"goto","view":"coach1"}], options)
	}
	const Menu_portadaItemsMenu1686384332233OnClick = async (options) => {
		run([{"id":"goto1686413152672","function":"goto","view":"bienvenida"}], options)
	}
	const Menu_portadaItemsMenu1686383675384OnClick = async (options) => {
		run([{"id":"goto1686391332280","function":"goto","view":"inicio"}], options)
	}

  return (
    <main id="Wr_menu_portadaWrapper" className="view h-fill">
        
				<Navbar bg="white" expand="lg" sticky="top" className="z-index-10">
					<Container>
					<Navbar.Toggle aria-controls="basic-navbar-nav" className="border-0 rounded-circle me-3 p-1" />
					<Navbar.Offcanvas id="basic-navbar-nav" className="w-auto d-lg-none">
					<Offcanvas.Body className="p-0">
						<Group
							id="wr_menu_portada_actionbar_content"
							className="w-auto h-auto position-relative" >
							<Group
								id="grupoinicio1686438900823"
								direction="horizontal"
								className="w-100 h-auto align-start valign-start position-relative p-10px" >
								<Dropdown
									id="dropdown1686438900823"
									visible={false}
									className="w-auto h-auto align-start valign-center position-relative bg-primary-light-to-primary-dark me-20px"
									arrow={false}
									variant="outline-white"
									title={<>

									<Icon
										id="dropdown1686438900823Icon"
										src="menu"
										className="w-auto h-auto position-relative"
									/>
									<Text
										id="dropdown1686438900823Title"
										className="w-auto h-auto position-relative" >
										{t('wr_menu_portada.dropdown1686438900823Title')}
									</Text>
								</>} >
									<Dropdown.Item image={qmflargo} title={t('menu_portada.items.menu1686383675384.title')} onClick={Menu_portadaItemsMenu1686383675384OnClick} href="inicio" />
									<Dropdown.Item title={t('menu_portada.items.menu1686384332233.title')} onClick={Menu_portadaItemsMenu1686384332233OnClick} href="bienvenida" />
								</Dropdown>
								<Nav
									id="nav1686438900823"
									className="w-auto h-auto align-start valign-center position-relative ms-20px"
									variant="tabs"
									direction="auto" >
									<Nav.Item image={qmflargo} title={t('menu_portada.items.menu1686383675384.title')} onClick={Menu_portadaItemsMenu1686383675384OnClick} href="inicio" />
									<Nav.Item title={t('menu_portada.items.menu1686384332233.title')} onClick={Menu_portadaItemsMenu1686384332233OnClick} href="bienvenida" />
								</Nav>
								<Text
									id="registrate1686438900823"
									visible={false}
									onClick={registrate1686438900823OnClick}
									className="max-w-100px w-auto h-auto border-radius-15px align-end valign-center position-relative bg-primary-light-to-primary-dark p-12px m-20px text-text-on-accent f-12px text-center" >
									{t('wr_menu_portada.registrate1686438900823')}
								</Text>
								<Text
									id="conectate1686438900823"
									visible={false}
									onClick={conectate1686438900823OnClick}
									className="max-w-100px w-auto h-auto border-radius-15px align-end valign-center position-relative bg-primary-light-to-primary-dark p-12px m-10px text-text-on-accent f-12px text-center" >
									{t('wr_menu_portada.conectate1686438900823')}
								</Text>
							</Group>
						</Group>
					</Offcanvas.Body>
					</Navbar.Offcanvas>
						<Navbar.Brand>
							<Link to="/">
								<Image
									id="wr_menu_portada_actionbar_logo"
									src={icon_512}
									className="w-auto min-h-40px h-40px position-relative" />
							</Link>
						</Navbar.Brand>
				<Group className="w-fill h-auto" />
				<Group className="w-auto h-auto d-none d-lg-flex">
						<Group
							id="wr_menu_portada_actionbar_content"
							className="w-auto h-auto position-relative" >
							<Group
								id="grupoinicio1686438900823"
								direction="horizontal"
								className="w-100 h-auto align-start valign-start position-relative p-10px" >
								<Dropdown
									id="dropdown1686438900823"
									visible={false}
									className="w-auto h-auto align-start valign-center position-relative bg-primary-light-to-primary-dark me-20px"
									arrow={false}
									variant="outline-white"
									title={<>

									<Icon
										id="dropdown1686438900823Icon"
										src="menu"
										className="w-auto h-auto position-relative"
									/>
									<Text
										id="dropdown1686438900823Title"
										className="w-auto h-auto position-relative" >
										{t('wr_menu_portada.dropdown1686438900823Title')}
									</Text>
								</>} >
									<Dropdown.Item image={qmflargo} title={t('menu_portada.items.menu1686383675384.title')} onClick={Menu_portadaItemsMenu1686383675384OnClick} href="inicio" />
									<Dropdown.Item title={t('menu_portada.items.menu1686384332233.title')} onClick={Menu_portadaItemsMenu1686384332233OnClick} href="bienvenida" />
								</Dropdown>
								<Nav
									id="nav1686438900823"
									className="w-auto h-auto align-start valign-center position-relative ms-20px"
									variant="tabs"
									direction="auto" >
									<Nav.Item image={qmflargo} title={t('menu_portada.items.menu1686383675384.title')} onClick={Menu_portadaItemsMenu1686383675384OnClick} href="inicio" />
									<Nav.Item title={t('menu_portada.items.menu1686384332233.title')} onClick={Menu_portadaItemsMenu1686384332233OnClick} href="bienvenida" />
								</Nav>
								<Text
									id="registrate1686438900823"
									visible={false}
									onClick={registrate1686438900823OnClick}
									className="max-w-100px w-auto h-auto border-radius-15px align-end valign-center position-relative bg-primary-light-to-primary-dark p-12px m-20px text-text-on-accent f-12px text-center" >
									{t('wr_menu_portada.registrate1686438900823')}
								</Text>
								<Text
									id="conectate1686438900823"
									visible={false}
									onClick={conectate1686438900823OnClick}
									className="max-w-100px w-auto h-auto border-radius-15px align-end valign-center position-relative bg-primary-light-to-primary-dark p-12px m-10px text-text-on-accent f-12px text-center" >
									{t('wr_menu_portada.conectate1686438900823')}
								</Text>
							</Group>
						</Group>
				</Group>
					</Container>
				</Navbar>
		
				<Group className="flex-grow-1">{children}</Group>
        <DoyoBadge />
    </main>
  );
};
