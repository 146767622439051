import React from 'react';
import { useDialogs } from '../hooks';
import { useFunctions } from '../hooks';

// Custom imports:
import { Modal, Button } from '../components';
import { useTranslation } from 'react-i18next';

const ExitopujaDialog = () => {
    const { run } = useFunctions();
    // Custom exports:
	const { t } = useTranslation();

    // Custom functions:
	const exitopuja_buttons_positiveOnClick = async (options) => {
		run([{"function":"hide","dialog":"exitopuja"}], options)
	}
	const exitopujaOnLoaded = async (options) => {
		run([{"id":"add1681577894565","function":"add","what":{"usuario":"@cookie.userID","fecha":"@app.timestamp","puja":"@property.puja"},"to":"@firebase.firestore.ofertas.(@property.indicesubasta).historialpujas"}], options)
	}

  return (
    
    
			<Modal id="exitopuja" onShow={exitopujaOnLoaded}>
					<Modal.Header>
						<Modal.Title>{t('exitopuja.title')}</Modal.Title>
					</Modal.Header>
					<Modal.Body>
					</Modal.Body>
					<Modal.Footer>
						<Button
							id="exitopuja_buttons_positive"
							onClick={exitopuja_buttons_positiveOnClick}
							variant="primary"
							className="w-auto h-auto position-relative" >
							{t('exitopuja.exitopuja_buttons_positive')}
						</Button>
					</Modal.Footer>
			</Modal>
    
  );
};

export default ExitopujaDialog;
