import React from 'react';

import { FunctionsProvider } from './Functions';
import { RefsProvider } from './Refs';
// Custom imports:
import { ApiProvider } from './Api';
import { FirebaseProvider } from './Firebase';
import { DialogsProvider } from './Dialogs';
import { DatabasesProvider } from './Databases';


export const ContextProviders = ({ children }) => {
  // Custom code:
  return (
    
		<ApiProvider>
			<FirebaseProvider>
				<DatabasesProvider>
					<RefsProvider>
						<DialogsProvider>
							<FunctionsProvider>
								<>{children}</>
							</FunctionsProvider>
						</DialogsProvider>
					</RefsProvider>
				</DatabasesProvider>
			</FirebaseProvider>
		</ApiProvider>

  );
};


export * from './Functions';
export * from './Refs';
// Custom exports:
export * from './Api';
export * from './Firebase';
export * from './Dialogs';
export * from './Databases';
