import React from 'react';
import { useDialogs } from '../hooks';
import { useFunctions } from '../hooks';

// Custom imports:
import { Modal, Text, Button } from '../components';
import { useTranslation } from 'react-i18next';

const Auth_terms_1Dialog = () => {
    const { run } = useFunctions();
    // Custom exports:
	const { t } = useTranslation();

    // Custom functions:
	const auth_terms_1_buttons_positiveOnClick = async (options) => {
		run([{"id":"set1678558130220","function":"set","what":"@cookie.authTermsAccepted","value":true},{"function":"hide","dialog":"auth_terms_1"}], options)
	}
	const auth_terms_1_buttons_negativeOnClick = async (options) => {
		run([{"id":"set1700865162395","function":"set","what":"@cookie.authTermsAccepted","value":false},{"function":"hide","dialog":"auth_terms_1"}], options)
	}

  return (
    
    
			<Modal id="auth_terms_1">
					<Modal.Header closeButton>
						<Modal.Title>{t('auth_terms_1.title')}</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Text
							id="text1685469167119"
							className="w-100 h-auto position-relative" >
							{t('auth_terms_1.text1685469167119')}
						</Text>
					</Modal.Body>
					<Modal.Footer>
						<Button
							id="auth_terms_1_buttons_negative"
							onClick={auth_terms_1_buttons_negativeOnClick}
							variant="transparent"
							className="w-auto h-auto position-relative" >
							{t('auth_terms_1.auth_terms_1_buttons_negative')}
						</Button>
						<Button
							id="auth_terms_1_buttons_positive"
							onClick={auth_terms_1_buttons_positiveOnClick}
							variant="primary"
							className="w-auto h-auto position-relative" >
							{t('auth_terms_1.auth_terms_1_buttons_positive')}
						</Button>
					</Modal.Footer>
			</Modal>
    
  );
};

export default Auth_terms_1Dialog;
