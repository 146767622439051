import React from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useFunctions, useRefs } from '../hooks';
import { Qmf_wradministradoresWrapper } from '../wrappers';

import { useLocation } from 'react-router-dom';
import { getLocationParams } from '../util';

// Custom imports:
import { Group, Text, Input, List } from '../components';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export const Qmf_ofertasView = () => {
  const { run } = useFunctions();
  const { search, pathname } = useLocation();


  // Custom exports:
	const { t } = useTranslation();

  // Custom functions:
	const qmf_ofertasParams = () => {
		run( getLocationParams( search, pathname ) )
	}
	useEffect( qmf_ofertasParams, [])
	const qmf_ofertasOnLoaded = async (options) => {
		run([{"id":"goto1694131845187","function":"goto","view":"qmfhome","if":{"what":"@firebase.user.id","is":null}},{"id":"set1694131845187","function":"set","what":"@property.desde","value":0},{"id":"set1694131845187","function":"set","what":"@property.hasta","value":4102444800000},{"id":"set1694639421949","function":"set","what":"@property.estado","value":"todas"}], options)
	}
	useEffect( qmf_ofertasOnLoaded, [])
	const texfhhgbt1695056800742OnClick = async (options) => {
		run([{"id":"set1695056800742","function":"set","what":"@cookie.keyoferta","value":"@element.lista_ofertas.selected"},{"id":"goto1695056800742","function":"goto","view":"qmf_consulta_oferta"}], options)
	}
	const texfhhgbt1693637372877OnClick = async (options) => {
		run([{"id":"set1693637372877","function":"set","what":"@cookie.keyoferta","value":"@element.lista_ofertas.selected"},{"id":"goto1693637372877","function":"goto","view":"qmf_consulta_oferta"}], options)
	}
	const inputtextdedesOnChanged = async (options) => {
		run([{"id":"set1693647662049","function":"set","what":"@property.desde","value":0,"if":{"what":"@property.desde","more_than":"@property.hasta"}},{"id":"set1693647662049","function":"set","what":"@property.desde","value":0,"if":{"what":"@property.desde","is":"@property.hasta"}}], options)
	}

  return (
    <>
      <Helmet>
				<title>{t('qmf_ofertas.title')}</title>
      </Helmet>
      <Qmf_wradministradoresWrapper>
          
			<Group
				id="groukkyy45544p1693637372877"
				direction="vertical"
				className="w-100 h-fill align-center position-relative p-20px" >
				<Group
					id="group1693637372877"
					direction="auto"
					className="w-100 h-auto position-relative mt-24px" >
					<Text
						id="ttfgcse1233x888t1693637372877"
						className="w-auto h-auto valign-center position-relative text-primary-light f-25px ff-gantaribold" >
						{t('qmf_ofertas.ttfgcse1233x888t1693637372877')}
					</Text>
					<Text
						id="texfgfgft1693637372877"
						className="w-fill h-auto position-relative" >
						{t('qmf_ofertas.texfgfgft1693637372877')}
					</Text>
				</Group>
				<Group
					id="groupfiltroofer"
					direction="auto"
					className="w-100 h-auto position-relative" >
					<Group
						id="groupdesde1693647662049"
						direction="horizontal"
						className="w-auto h-auto position-relative" >
						<Text
							id="texdedesd"
							className="w-auto h-auto position-relative m-5px text-primary" >
							{t('qmf_ofertas.texdedesd')}
						</Text>
						<Input.Date
							id="inputtextdedes"
							name="text"
							src="@property.desde"
						placeholder={t('qmf_ofertas.inputtextdedes.hint')}
						autoComplete="off"
						onChange={inputtextdedesOnChanged}
							className="w-auto h-auto position-relative m-5px" />
					</Group>
					<Group
						id="grouphasta1693647662049"
						direction="horizontal"
						className="w-auto h-auto position-relative" >
						<Text
							id="tehasta"
							className="w-auto h-auto position-relative m-5px text-primary" >
							{t('qmf_ofertas.tehasta')}
						</Text>
						<Input.Date
							id="inputtexthasta"
							name="text"
							src="@property.hasta"
						placeholder={t('qmf_ofertas.inputtexthasta.hint')}
						autoComplete="off"
							className="w-auto h-auto position-relative m-5px" />
					</Group>
					<Group
						id="groupestado"
						direction="horizontal"
						className="w-auto h-auto position-relative" >
						<Text
							id="tehasta1693647792836"
							className="w-auto h-auto position-relative m-5px text-primary" >
							{t('qmf_ofertas.tehasta1693647792836')}
						</Text>
						<Input.Select
							id="selectestado"
							name="select"
							src="@property.estado"
							dataSrc="@firebase.firestore.estados_ofertas"
							className="w-auto h-auto position-relative" >
						</Input.Select>
					</Group>
				</Group>
				<Text
					id="trrtreiooyut1695314402383"
					className="w-100 min-h-3px h-3px position-relative bg-primary-light" >
					{t('qmf_ofertas.trrtreiooyut1695314402383')}
				</Text>
				<Group
					id="signioounForm1693637372877"
					direction="vertical"
					className="w-100 h-auto border-radius-15px align-center position-relative p-32px mt-40px" >
					<List
						id="lista_ofertas"
						src="@firebase.firestore.ofertavendedores"
						filter={{"what":"fecha_creacion","more_equal_than":"@property.desde","and":{"what":"fecha_creacion","less_than":"@property.hasta"}}}
						order={{"type":"desc","field":"fecha_creacion"}}
						className="w-fill h-auto position-relative" >
						<Group
							id="groupdetallefiltro1693637372877"
							if={{"what":"@field.estado","is":"@property.estado"}}
							direction="vertical"
							className="w-100 h-auto border-top border-bottom border-start border-end border-radius-20px position-relative p-10px mt-10px" >
							<Group
								id="groupdetallelista1693637372877"
								direction="auto"
								className="w-fill h-auto position-relative p-10px" >
								<Text
									id="textghtfdds1693637372877"
									tag="p"
									className="w-auto h-auto position-relative" >
									{t('qmf_ofertas.textghtfdds1693637372877')}
								</Text>
								<Text
									id="texghhhhaqt1693637372877"
									className="w-fill h-auto position-relative" >
									{t('qmf_ofertas.texghhhhaqt1693637372877')}
								</Text>
								<Text
									id="tehhuqxt1693637372877"
									tag="h3"
									src="estado"
									className="w-auto h-auto border-radius-20px position-relative bg-accent p-20px text-text-on-accent" >
									{t('qmf_ofertas.tehhuqxt1693637372877')}
								</Text>
							</Group>
							<Group
								id="groupprincida"
								direction="auto"
								className="w-100 h-auto position-relative" >
								<Group
									id="groupdato"
									direction="vertical"
									className="min-w-600px w-fill h-auto position-relative p-10px" >
									<Group
										id="grouhdhfech"
										direction="horizontal"
										className="w-100 h-auto position-relative" >
										<Text
											id="texjjkoppt1693637372877"
											className="w-auto h-auto position-relative ff-gantaribold" >
											{t('qmf_ofertas.texjjkoppt1693637372877')}
										</Text>
										<Text
											id="texjjkoppt1693640108645"
											format={{"type":"date","day":"numeric","hour":"numeric","month":"numeric","year":"numeric","minute":"numeric"}}
											src="fecha_creacion"
											className="w-fill h-auto position-relative ff-gantaribold" >
											{t('qmf_ofertas.texjjkoppt1693640108645')}
										</Text>
									</Group>
									<Group
										id="grouhhhnom"
										direction="horizontal"
										className="w-100 h-auto position-relative" >
										<Text
											id="texjjkdeohjjppt1693641977599"
											className="w-fill h-auto position-relative ff-gantaribold" >
											{t('qmf_ofertas.texjjkdeohjjppt1693641977599')}
										</Text>
									</Group>
									<Group
										id="grouhhmail"
										direction="horizontal"
										className="w-100 h-auto position-relative" >
										<Text
											id="texjjkdeohhntdjjppt1693642057440"
											className="w-fill h-auto position-relative ff-gantaribold" >
											{t('qmf_ofertas.texjjkdeohhntdjjppt1693642057440')}
										</Text>
									</Group>
									<Group
										id="grouhhtelf"
										direction="horizontal"
										className="w-100 h-auto position-relative" >
										<Text
											id="texjjtelf"
											className="w-fill h-auto position-relative ff-gantaribold" >
											{t('qmf_ofertas.texjjtelf')}
										</Text>
									</Group>
								</Group>
							</Group>
							<Group
								id="groupmenulista1693637372877"
								direction="auto"
								className="w-100 h-auto position-relative mt-20px" >
								<Text
									id="texgggt1693637372877"
									className="w-fill h-auto position-relative" >
									{t('qmf_ofertas.texgggt1693637372877')}
								</Text>
								<Text
									id="texfhhgbt1693637372877"
									onClick={texfhhgbt1693637372877OnClick}
									tag="sup"
									className="w-auto h-auto border-radius-10px position-relative bg-primary-light-to-primary-dark p-15px me-15px text-text-on-accent text-center" >
									{t('qmf_ofertas.texfhhgbt1693637372877')}
								</Text>
							</Group>
						</Group>
						<Group
							id="groupdetalletodqas"
							if={{"what":"@property.estado","is":"todas"}}
							direction="vertical"
							className="w-100 h-auto border-top border-bottom border-start border-end border-radius-20px position-relative p-10px mt-10px" >
							<Group
								id="groupdetallelista1695056800742"
								direction="auto"
								className="w-fill h-auto position-relative p-10px" >
								<Text
									id="textghtfdds1695056800742"
									tag="p"
									className="w-auto h-auto position-relative" >
									{t('qmf_ofertas.textghtfdds1695056800742')}
								</Text>
								<Text
									id="texghhhhaqt1695056800742"
									className="w-fill h-auto position-relative" >
									{t('qmf_ofertas.texghhhhaqt1695056800742')}
								</Text>
								<Text
									id="tehhuqxt1695056800742"
									tag="h3"
									src="estado"
									className="w-auto h-auto border-radius-20px position-relative bg-accent p-20px text-text-on-accent" >
									{t('qmf_ofertas.tehhuqxt1695056800742')}
								</Text>
							</Group>
							<Group
								id="groupprincida1695056800742"
								direction="auto"
								className="w-100 h-auto position-relative" >
								<Group
									id="groupdato1695056800742"
									direction="vertical"
									className="min-w-600px w-fill h-auto position-relative p-10px" >
									<Group
										id="grouhdhfech1695056800742"
										direction="horizontal"
										className="w-100 h-auto position-relative" >
										<Text
											id="texjjkoppt1695056800742"
											className="w-auto h-auto position-relative ff-gantaribold" >
											{t('qmf_ofertas.texjjkoppt1695056800742')}
										</Text>
										<Text
											id="texjjkoppt1695056800742"
											format={{"type":"date","day":"numeric","hour":"numeric","month":"numeric","year":"numeric","minute":"numeric"}}
											src="fecha_creacion"
											className="w-fill h-auto position-relative ff-gantaribold" >
											{t('qmf_ofertas.texjjkoppt1695056800742')}
										</Text>
									</Group>
									<Group
										id="grouhhhnom1695056800742"
										direction="horizontal"
										className="w-100 h-auto position-relative" >
										<Text
											id="texjjkdeohjjppt1695056800742"
											className="w-fill h-auto position-relative ff-gantaribold" >
											{t('qmf_ofertas.texjjkdeohjjppt1695056800742')}
										</Text>
									</Group>
									<Group
										id="grouhhmail1695056800742"
										direction="horizontal"
										className="w-100 h-auto position-relative" >
										<Text
											id="texjjkdeohhntdjjppt1695056800742"
											className="w-fill h-auto position-relative ff-gantaribold" >
											{t('qmf_ofertas.texjjkdeohhntdjjppt1695056800742')}
										</Text>
									</Group>
									<Group
										id="grouhhtelf1695056800742"
										direction="horizontal"
										className="w-100 h-auto position-relative" >
										<Text
											id="texjjtelf1695056800742"
											className="w-fill h-auto position-relative ff-gantaribold" >
											{t('qmf_ofertas.texjjtelf1695056800742')}
										</Text>
									</Group>
								</Group>
							</Group>
							<Group
								id="groupmenulista1695056800742"
								direction="auto"
								className="w-100 h-auto position-relative mt-20px" >
								<Text
									id="texgggt1695056800742"
									className="w-fill h-auto position-relative" >
									{t('qmf_ofertas.texgggt1695056800742')}
								</Text>
								<Text
									id="texfhhgbt1695056800742"
									onClick={texfhhgbt1695056800742OnClick}
									tag="sup"
									className="w-auto h-auto border-radius-10px position-relative bg-primary-light-to-primary-dark p-15px me-15px text-text-on-accent text-center" >
									{t('qmf_ofertas.texfhhgbt1695056800742')}
								</Text>
							</Group>
						</Group>
					</List>
				</Group>
			</Group>
      </Qmf_wradministradoresWrapper>
    </>
  );
};
