import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFunctions } from '../hooks';
import DoyoBadge from '../components/DoyoBadge';

// Custom imports:
import { Group, Nav, Image } from '../components';
import qmflargo from '../assets/qmflargo.png';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Container from 'react-bootstrap/Container';
import { Link } from 'react-router-dom'

export const Qmf_wradministradoresWrapper = ({ children }) => {
  const { t } = useTranslation();
  const { run } = useFunctions();
  
  // Custom consts:

  // Custom functions:
	const Qmf_menu_administradoresItemsMenufcerrarOnClick = async (options) => {
		run([{"id":"logout1693854705342","function":"logout","provider":"firebase"},{"id":"goto1693854705342","function":"goto","view":"qmfhome"}], options)
	}
	const Qmf_menu_administradoresItemsMenue1actividad1701682045661OnClick = async (options) => {
		run([{"id":"goto1701682045661","function":"goto","view":"qmfsimulador_1"}], options)
	}
	const Qmf_menu_administradoresItemsMenue1actividad1705829586417OnClick = async (options) => {
		run([{"id":"goto1705829586417","function":"goto","view":"qmf_visitas"}], options)
	}
	const Qmf_menu_administradoresItemsMenue1actividad1694133599759OnClick = async (options) => {
		run([{"id":"goto1694157938165","function":"goto","view":"qmf_conversaciones"}], options)
	}
	const Qmf_menu_administradoresItemsMenue1actividadOnClick = async (options) => {
		run([{"id":"goto1700301542683","function":"goto","view":"qmfhistorial"}], options)
	}
	const Qmf_menu_administradoresItemsMenucdemandasOnClick = async (options) => {
		run([{"id":"goto1693854705342","function":"goto","view":"qmf_demandas"}], options)
	}
	const Qmf_menu_administradoresItemsMenub1693854705342OnClick = async (options) => {
		run([{"id":"goto1693854705342","function":"goto","view":"qmf_ofertas"}], options)
	}
	const Qmf_menu_administradoresItemsMenudadminstradore1705829534490OnClick = async (options) => {
		run([{"id":"goto1705829534490","function":"goto","view":"qmf_administradores"}], options)
	}
	const Qmf_menu_administradoresItemsMenudadminstradore1694132183121OnClick = async (options) => {
		run([{"id":"goto1694132183121","function":"goto","view":"qmf_perfil"}], options)
	}

  return (
    <main id="Qmf_wradministradoresWrapper" className="view h-fill">
        
				<Navbar bg="white" expand="lg" sticky="top" className="z-index-10 border-bottom">
					<Container>
					<Navbar.Toggle aria-controls="basic-navbar-nav" className="border-0 rounded-circle me-3 p-1" />
					<Navbar.Offcanvas id="basic-navbar-nav" className="w-auto d-lg-none">
					<Offcanvas.Body className="p-0">
						<Nav
							id="qmf_wradministradores_actionbar_menu"
							className="w-auto h-auto align-end valign-center position-relative" >
							<Nav.Item title={t('qmf_menu_administradores.items.menudadminstradore1694132183121.title')} onClick={Qmf_menu_administradoresItemsMenudadminstradore1694132183121OnClick} href="qmf_perfil" />
							<Nav.Item title={t('qmf_menu_administradores.items.menudadminstradore1705829534490.title')} onClick={Qmf_menu_administradoresItemsMenudadminstradore1705829534490OnClick} href="qmf_administradores" />
							<Nav.Item title={t('qmf_menu_administradores.items.menub1693854705342.title')} onClick={Qmf_menu_administradoresItemsMenub1693854705342OnClick} href="qmf_ofertas" />
							<Nav.Item title={t('qmf_menu_administradores.items.menucdemandas.title')} onClick={Qmf_menu_administradoresItemsMenucdemandasOnClick} href="qmf_demandas" />
							<Nav.Item title={t('qmf_menu_administradores.items.menudrelaciones.title')} />
							<Nav.Item title={t('qmf_menu_administradores.items.menue1actividad.title')} onClick={Qmf_menu_administradoresItemsMenue1actividadOnClick} href="qmfhistorial" />
							<Nav.Item title={t('qmf_menu_administradores.items.menue1actividad1694133599759.title')} onClick={Qmf_menu_administradoresItemsMenue1actividad1694133599759OnClick} href="qmf_conversaciones" />
							<Nav.Item title={t('qmf_menu_administradores.items.menue1actividad1705829586417.title')} onClick={Qmf_menu_administradoresItemsMenue1actividad1705829586417OnClick} href="qmf_visitas" />
							<Nav.Item title={t('qmf_menu_administradores.items.menue1actividad1701682045661.title')} onClick={Qmf_menu_administradoresItemsMenue1actividad1701682045661OnClick} href="qmfsimulador_1" />
							<Nav.Item title={t('qmf_menu_administradores.items.menufcerrar.title')} onClick={Qmf_menu_administradoresItemsMenufcerrarOnClick} href="qmfhome" />
						</Nav>
						<Group
							id="qmf_wradministradores_actionbar_content"
							className="w-auto h-auto position-relative" >
						</Group>
					</Offcanvas.Body>
					</Navbar.Offcanvas>
						<Navbar.Brand>
							<Link to="/">
								<Image
									id="qmf_wradministradores_actionbar_logo"
									src={qmflargo}
									className="w-auto min-h-40px h-40px position-relative" />
							</Link>
						</Navbar.Brand>
				<Group className="w-auto h-auto d-none d-lg-flex">
						<Nav
							id="qmf_wradministradores_actionbar_menu"
							className="w-auto h-auto align-end valign-center position-relative" >
							<Nav.Item title={t('qmf_menu_administradores.items.menudadminstradore1694132183121.title')} onClick={Qmf_menu_administradoresItemsMenudadminstradore1694132183121OnClick} href="qmf_perfil" />
							<Nav.Item title={t('qmf_menu_administradores.items.menudadminstradore1705829534490.title')} onClick={Qmf_menu_administradoresItemsMenudadminstradore1705829534490OnClick} href="qmf_administradores" />
							<Nav.Item title={t('qmf_menu_administradores.items.menub1693854705342.title')} onClick={Qmf_menu_administradoresItemsMenub1693854705342OnClick} href="qmf_ofertas" />
							<Nav.Item title={t('qmf_menu_administradores.items.menucdemandas.title')} onClick={Qmf_menu_administradoresItemsMenucdemandasOnClick} href="qmf_demandas" />
							<Nav.Item title={t('qmf_menu_administradores.items.menudrelaciones.title')} />
							<Nav.Item title={t('qmf_menu_administradores.items.menue1actividad.title')} onClick={Qmf_menu_administradoresItemsMenue1actividadOnClick} href="qmfhistorial" />
							<Nav.Item title={t('qmf_menu_administradores.items.menue1actividad1694133599759.title')} onClick={Qmf_menu_administradoresItemsMenue1actividad1694133599759OnClick} href="qmf_conversaciones" />
							<Nav.Item title={t('qmf_menu_administradores.items.menue1actividad1705829586417.title')} onClick={Qmf_menu_administradoresItemsMenue1actividad1705829586417OnClick} href="qmf_visitas" />
							<Nav.Item title={t('qmf_menu_administradores.items.menue1actividad1701682045661.title')} onClick={Qmf_menu_administradoresItemsMenue1actividad1701682045661OnClick} href="qmfsimulador_1" />
							<Nav.Item title={t('qmf_menu_administradores.items.menufcerrar.title')} onClick={Qmf_menu_administradoresItemsMenufcerrarOnClick} href="qmfhome" />
						</Nav>
				</Group>
				<Group className="w-fill h-auto" />
				<Group className="w-auto h-auto d-none d-lg-flex">
						<Group
							id="qmf_wradministradores_actionbar_content"
							className="w-auto h-auto position-relative" >
						</Group>
				</Group>
					</Container>
				</Navbar>
		
				<Group className="flex-grow-1">{children}</Group>
        <DoyoBadge />
    </main>
  );
};
