import React from 'react';
import { useDialogs } from '../hooks';
import { useFunctions } from '../hooks';

// Custom imports:
import { Modal, Group, Icon, Text, Button } from '../components';
import { useTranslation } from 'react-i18next';

const Qmf_advertenciaDialog = () => {
    const { run } = useFunctions();
    // Custom exports:
	const { t } = useTranslation();

    // Custom functions:
	const qmf_advertencia_buttons_positiveOnClick = async (options) => {
		run([{"function":"hide","dialog":"qmf_advertencia"}], options)
	}

  return (
    
    
			<Modal id="qmf_advertencia" centered animation={false}>
					<Modal.Body>
						<Group
							id="group1699692567075"
							direction="vertical"
							className="w-100 h-auto position-relative" >
							<Icon
								id="icon1699692581767"
								src="exclamation-thick"
								className="w-auto h-auto position-relative"
							/>
							<Text
								id="text1691857966129"
								src="@property.advertencia"
								className="w-auto h-auto position-relative" >
								{t('qmf_advertencia.text1691857966129')}
							</Text>
						</Group>
					</Modal.Body>
					<Modal.Footer>
						<Button
							id="qmf_advertencia_buttons_positive"
							onClick={qmf_advertencia_buttons_positiveOnClick}
							variant="primary"
							className="w-auto h-auto position-relative" >
							{t('qmf_advertencia.qmf_advertencia_buttons_positive')}
						</Button>
					</Modal.Footer>
			</Modal>
    
  );
};

export default Qmf_advertenciaDialog;
