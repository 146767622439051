import React from 'react';
import { useDialogs } from '../hooks';
import { useFunctions } from '../hooks';

// Custom imports:
import { Modal, Group, Input, Button } from '../components';
import { useTranslation } from 'react-i18next';

const Crear_inversionDialog = () => {
    const { run } = useFunctions();
    // Custom exports:
	const { t } = useTranslation();

    // Custom functions:
	const crear_inversion_buttons_positiveOnClick = async (options) => {
		run([{"id":"set1685783855027","function":"set","what":"@property.ini","value":"@app.timestamp"},{"id":"set1685783855027","function":"set","what":"@property.keyinversion","value":"qmfin(@property.ini)","if":{"what":"@property.crearinversion","is":true,"else":[{"id":"set1685783855027","function":"set","what":"@property.keyinversion","value":"@element.listainversiones.selected"}]}},{"id":"add1685783855027","function":"add","what":{"usuario":"@cookie.userID","nivel":"@property.nivel","rendimiento":"@property.rendimiento","horizonte":"@property.horizonte","presupuesto":"@property.presupuesto","ref":"@property.keyinversion"},"to":"@firebase.firestore.inversiones","key":"@property.keyinversion"},{"function":"hide","dialog":"crear_inversion"}], options)
	}
	const crear_inversion_buttons_negativeOnClick = async (options) => {
		run([{"function":"hide","dialog":"crear_inversion"}], options)
	}

  return (
    
    
			<Modal id="crear_inversion">
					<Modal.Header>
						<Modal.Title>{t('crear_inversion.title')}</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Group
							id="group1685783855027"
							direction="vertical"
							className="w-100 h-auto position-relative p-10px scrollable" >
							<Input.Select
								id="selectbnbgnas1685783855027"
								label={t('crear_inversion.selectbnbgnas1685783855027.label')}
								name="select"
								src="@property.presupuesto"
								className="w-100 h-auto position-relative mb-10px" >
								<option value={"Menos de 100.000€"}>{t('crear_inversion.selectbnbgnas1685783855027.opt0')}</option>
								<option value={"De 100.000€ a 300.000€"}>{t('crear_inversion.selectbnbgnas1685783855027.opt1')}</option>
								<option value={"De 300.000€ a 600.000€"}>{t('crear_inversion.selectbnbgnas1685783855027.opt2')}</option>
								<option value={"De 600.000€ a 10.000.000€"}>{t('crear_inversion.selectbnbgnas1685783855027.opt3')}</option>
								<option value={"Más de 1.000.000€"}>{t('crear_inversion.selectbnbgnas1685783855027.opt4')}</option>
							</Input.Select>
							<Input.Select
								id="selecvbdfgdfgfdt1685783855027"
								label={t('crear_inversion.selecvbdfgdfgfdt1685783855027.label')}
								name="select"
								src="@property.horizonte"
								className="w-100 h-auto position-relative mb-10px" >
								<option value={"@property.vacia "}>{t('crear_inversion.selecvbdfgdfgfdt1685783855027.opt0')}</option>
								<option value={"Largo plazo"}>{t('crear_inversion.selecvbdfgdfgfdt1685783855027.opt1')}</option>
								<option value={"Corto plazo"}>{t('crear_inversion.selecvbdfgdfgfdt1685783855027.opt2')}</option>
								<option value={"Flexible"}>{t('crear_inversion.selecvbdfgdfgfdt1685783855027.opt3')}</option>
							</Input.Select>
							<Input.Select
								id="selemmnnbbct1685783855027"
								label={t('crear_inversion.selemmnnbbct1685783855027.label')}
								name="select"
								src="@property.nivel"
								className="w-100 h-auto position-relative mb-10px" >
								<option value={"@property.vacia"}>{t('crear_inversion.selemmnnbbct1685783855027.opt0')}</option>
								<option value={"Propietario-gerente activo"}>{t('crear_inversion.selemmnnbbct1685783855027.opt1')}</option>
								<option value={"Participación pasiva"}>{t('crear_inversion.selemmnnbbct1685783855027.opt2')}</option>
							</Input.Select>
							<Input.Select
								id="selecthhjk1685783855027"
								label={t('crear_inversion.selecthhjk1685783855027.label')}
								name="select"
								src="@property.rendimiento"
								className="w-100 h-auto position-relative mb-10px" >
								<option value={"@property.vacia"}>{t('crear_inversion.selecthhjk1685783855027.opt0')}</option>
								<option value={"Un rango especifico de ROI"}>{t('crear_inversion.selecthhjk1685783855027.opt1')}</option>
								<option value={"Objetivo por rendimiento"}>{t('crear_inversion.selecthhjk1685783855027.opt2')}</option>
							</Input.Select>
						</Group>
					</Modal.Body>
					<Modal.Footer>
						<Button
							id="crear_inversion_buttons_negative"
							onClick={crear_inversion_buttons_negativeOnClick}
							variant="transparent"
							className="w-auto h-auto position-relative" >
							{t('crear_inversion.crear_inversion_buttons_negative')}
						</Button>
						<Button
							id="crear_inversion_buttons_positive"
							onClick={crear_inversion_buttons_positiveOnClick}
							variant="primary"
							className="w-auto h-auto position-relative" >
							{t('crear_inversion.crear_inversion_buttons_positive')}
						</Button>
					</Modal.Footer>
			</Modal>
    
  );
};

export default Crear_inversionDialog;
