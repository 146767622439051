import React from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useFunctions, useRefs } from '../hooks';
import { FullscreenWrapper } from '../wrappers';

import { useLocation } from 'react-router-dom';
import { getLocationParams } from '../util';

// Custom imports:
import { Group, Text, List, Image } from '../components';
import { useEffect } from 'react';
import publicarverde from '../assets/iconos/assest/publicarverde.png';
import { useTranslation } from 'react-i18next';

export const Qmf_consulta_demandaView = () => {
  const { run } = useFunctions();
  const { search, pathname } = useLocation();


  // Custom exports:
	const { t } = useTranslation();

  // Custom functions:
	const qmf_consulta_demandaParams = () => {
		run( getLocationParams( search, pathname ) )
	}
	useEffect( qmf_consulta_demandaParams, [])
	const qmf_consulta_demandaOnLoaded = async (options) => {
		run([{"id":"goto1694131798355","function":"goto","view":"qmfhome","if":{"what":"@firebase.user.id","is":null}}], options)
	}
	useEffect( qmf_consulta_demandaOnLoaded, [])
	const texvofdflvergfgft1693762891694OnClick = async (options) => {
		run([{"id":"back1693762891694","function":"back"}], options)
	}
	const texgestionarfgft1693762891694OnClick = async (options) => {
		run([{"id":"popup1693762891694","function":"popup","dialog":"qmf_gestionardemanda"}], options)
	}
	const imagfverdocumento1693762891694OnClick = async (options) => {
		run([{"id":"browser1693762891694","function":"browser","url":"@firebase.firestore.documentostorage.(@element.listadocumentosdemanda.selected).token"}], options)
	}
	const texgfganadircompOnClick = async (options) => {
		run([{"id":"popup1693762891694","function":"popup","dialog":"qmf_anadir_documento_oferta"}], options)
	}

  return (
    <>
      <Helmet>
				<title>{t('qmf_consulta_demanda.title')}</title>
				<meta name="description" content={t('qmf_consulta_demanda.title')} data-react-helmet="true" />
      </Helmet>
      <FullscreenWrapper>
          
			<Group
				id="groupprincidemandas"
				direction="vertical"
				className="w-100 min-h-100 fg-100 position-relative" >
				<Group
					id="groupkkjjhjh1693762891694"
					direction="vertical"
					className="w-100 h-fill position-relative scrollable" >
					<Group
						id="gr12ou45544p1693762891694"
						direction="vertical"
						className="w-100 h-fill align-center position-relative scrollable" >
						<Group
							id="signio5566ounFortrerm1693762891694"
							direction="vertical"
							className="w-100 h-auto border-radius-15px align-center position-relative p-32px mt-24px" >
							<Text
								id="te12lli33x888t1693762891694"
								tag="h1"
								className="w-100 h-auto position-relative text-primary-light ff-gantaribold" >
								{t('qmf_consulta_demanda.te12lli33x888t1693762891694')}
							</Text>
							<Text
								id="treiollooyut1693762891694"
								className="w-100 min-h-3px h-3px position-relative bg-primary-light" >
								{t('qmf_consulta_demanda.treiollooyut1693762891694')}
							</Text>
						</Group>
						<Group
							id="grouptipologia1693762891694"
							direction="auto"
							className="w-100 h-auto position-relative p-25px" >
							<Group
								id="groutittipologia1693762891694"
								direction="vertical"
								className="w-100 h-auto position-relative" >
								<Text
									id="tittipologia1693762891694"
									tag="h2"
									className="w-100 h-auto position-relative text-primary-light ff-gantaribold" >
									{t('qmf_consulta_demanda.tittipologia1693762891694')}
								</Text>
								<Text
									id="lintipologia1693762891694"
									className="w-100 min-h-3px h-3px position-relative bg-primary-light" >
									{t('qmf_consulta_demanda.lintipologia1693762891694')}
								</Text>
							</Group>
							<Group
								id="grupodatostipoologia1693762891694"
								direction="auto"
								className="w-100 h-auto valign-center position-relative p-10px" >
								<Group
									id="grouposubicacion"
									direction="vertical"
									className="max-w-500px min-w-250px w-auto h-auto position-relative me-30px" >
									<Text
										id="texttitulubicacion"
										className="w-100 h-auto position-relative" >
										{t('qmf_consulta_demanda.texttitulubicacion')}
									</Text>
									<Text
										id="textmetros1693762891694"
										src="@firebase.firestore.demandacompradores.(@cookie.keydemanda).ubicacion"
										className="w-100 h-auto border-top border-bottom border-start border-end position-relative mt-15px" >
										{t('qmf_consulta_demanda.textmetros1693762891694')}
									</Text>
								</Group>
								<Group
									id="groupootefct"
									direction="vertical"
									className="max-w-500px min-w-250px w-auto h-auto position-relative me-30px" >
									<Text
										id="texttitefct"
										className="w-100 h-auto position-relative" >
										{t('qmf_consulta_demanda.texttitefct')}
									</Text>
									<Text
										id="texttefctr"
										src="@firebase.firestore.demandacompradores.(@cookie.keydemanda).facturacion"
										className="w-100 h-auto border-top border-bottom border-start border-end position-relative mt-15px" >
										{t('qmf_consulta_demanda.texttefctr')}
									</Text>
								</Group>
								<Group
									id="grouposempleadoscom"
									direction="vertical"
									className="max-w-500px min-w-250px w-auto h-auto position-relative me-30px" >
									<Text
										id="texttiempleadoscom"
										className="w-100 h-auto position-relative" >
										{t('qmf_consulta_demanda.texttiempleadoscom')}
									</Text>
									<Text
										id="textempleadoscomp"
										src="@firebase.firestore.demandacompradores.(@cookie.keydemanda).empleados"
										className="w-100 h-auto border-top border-bottom border-start border-end position-relative mt-15px" >
										{t('qmf_consulta_demanda.textempleadoscomp')}
									</Text>
								</Group>
							</Group>
						</Group>
						<Group
							id="groupeprescu"
							direction="auto"
							className="w-100 h-auto position-relative p-25px" >
							<Group
								id="grouptipresup"
								direction="vertical"
								className="w-100 h-auto position-relative" >
								<Text
									id="tepresupuesto"
									tag="h2"
									className="w-100 h-auto position-relative text-primary-light ff-gantaribold" >
									{t('qmf_consulta_demanda.tepresupuesto')}
								</Text>
								<Text
									id="treiooyut1693762891694"
									className="w-100 min-h-3px h-3px position-relative bg-primary-light" >
									{t('qmf_consulta_demanda.treiooyut1693762891694')}
								</Text>
							</Group>
							<Group
								id="grupoodtoseconomicos1693762891694"
								direction="auto"
								className="w-100 h-auto valign-center position-relative p-15px" >
								<Group
									id="grouposcampofactgruacion1693762891694"
									direction="vertical"
									className="max-w-500px min-w-250px w-auto h-auto position-relative me-30px" >
									<Text
										id="texttituloinvesrion"
										className="w-100 h-auto position-relative" >
										{t('qmf_consulta_demanda.texttituloinvesrion')}
									</Text>
									<Text
										id="textcampoinversion"
										src="@firebase.firestore.demandacompradores.(@cookie.keydemanda).presupuesto"
										className="w-100 h-auto border-top border-bottom border-start border-end position-relative mt-15px text-end" >
										{t('qmf_consulta_demanda.textcampoinversion')}
									</Text>
								</Group>
								<Group
									id="grouposcapitalpropio"
									direction="vertical"
									className="max-w-500px min-w-250px w-auto h-auto position-relative me-30px" >
									<Text
										id="texttitulocapital"
										className="w-100 h-auto position-relative" >
										{t('qmf_consulta_demanda.texttitulocapital')}
									</Text>
									<Text
										id="textcampocapital"
										src="@firebase.firestore.demandacompradores.(@cookie.keydemanda).capitalpropio"
										className="w-100 h-auto border-top border-bottom border-start border-end position-relative mt-15px text-end" >
										{t('qmf_consulta_demanda.textcampocapital')}
									</Text>
								</Group>
							</Group>
						</Group>
						<Group
							id="grouppmotivo1693762891694"
							direction="auto"
							className="w-100 h-auto position-relative p-25px" >
							<Group
								id="grouhhup1693762891694"
								direction="vertical"
								className="w-100 h-auto position-relative" >
								<Text
									id="temotivocomp"
									tag="h2"
									className="w-100 h-auto position-relative text-primary-light ff-gantaribold" >
									{t('qmf_consulta_demanda.temotivocomp')}
								</Text>
								<Text
									id="linmotivocomp"
									className="w-100 min-h-3px h-3px position-relative bg-primary-light" >
									{t('qmf_consulta_demanda.linmotivocomp')}
								</Text>
							</Group>
							<Group
								id="grupoodstmotivocomp"
								direction="auto"
								className="w-100 h-auto valign-center position-relative p-10px" >
								<Group
									id="grouposcamposmotivocomp"
									direction="vertical"
									className="min-w-250px w-100 h-auto position-relative p-15px me-30px" >
									<Text
										id="texttitulo1693762891694"
										className="w-100 h-auto position-relative" >
										{t('qmf_consulta_demanda.texttitulo1693762891694')}
									</Text>
									<Text
										id="textcampomotivo"
										src="@firebase.firestore.demandacompradores.(@cookie.keydemanda).motivo"
										className="w-100 h-auto border-top border-bottom border-start border-end position-relative mt-15px" >
										{t('qmf_consulta_demanda.textcampomotivo')}
									</Text>
								</Group>
								<Group
									id="grouposcamposobservacionescomp"
									direction="vertical"
									className="min-w-250px w-100 h-auto position-relative p-15px me-30px" >
									<Text
										id="texttitulo1693843806166"
										className="w-100 h-auto position-relative" >
										{t('qmf_consulta_demanda.texttitulo1693843806166')}
									</Text>
									<Text
										id="textcampomobservacionescomp"
										src="@firebase.firestore.demandacompradores.(@cookie.keydemanda).observaciones"
										className="w-100 h-auto border-top border-bottom border-start border-end position-relative mt-15px" >
										{t('qmf_consulta_demanda.textcampomobservacionescomp')}
									</Text>
								</Group>
							</Group>
						</Group>
						<Group
							id="grojjdocumentosanotcionescomp"
							direction="auto"
							className="w-100 h-auto position-relative p-25px" >
							<Group
								id="groupdocumcomp"
								direction="horizontal"
								className="w-100 h-auto position-relative mt-24px" >
								<Text
									id="titulodocumentos1693762891694"
									tag="h2"
									className="w-auto h-auto position-relative text-primary-light ff-gantaribold" >
									{t('qmf_consulta_demanda.titulodocumentos1693762891694')}
								</Text>
								<Text
									id="texlincoducomp"
									className="w-fill h-auto position-relative" >
									{t('qmf_consulta_demanda.texlincoducomp')}
								</Text>
								<Text
									id="texgfganadircomp"
									onClick={texgfganadircompOnClick}
									tag="mark"
									className="w-auto h-auto border-radius-20px position-relative bg-primary p-15px m-5px text-text-on-accent f-10px" >
									{t('qmf_consulta_demanda.texgfganadircomp')}
								</Text>
							</Group>
							<Text
								id="linscpadocucomp"
								className="w-100 min-h-3px h-3px position-relative bg-primary-light" >
								{t('qmf_consulta_demanda.linscpadocucomp')}
							</Text>
							<Group
								id="grupodedocumentosdemanda"
								direction="vertical"
								className="w-100 h-auto border-radius-15px align-center position-relative p-32px mt-40px scrollable" >
								<List
									id="listadocumentosdemanda"
									src="@firebase.firestore.demandacompradores.(@cookie.keydemanda).documentos"
									className="w-100 h-auto position-relative" >
									<Group
										id="groupdetalledocumetnoscomp"
										direction="horizontal"
										className="w-100 h-auto position-relative mt-15px" >
										<Text
											id="textnombredocuemento1693762891694"
											src="nombre"
											className="w-auto h-auto position-relative ff-gantaribold" >
											{t('qmf_consulta_demanda.textnombredocuemento1693762891694')}
										</Text>
										<Text
											id="texkspacios1693762891694"
											className="w-fill h-auto position-relative ff-gantaribold" >
											{t('qmf_consulta_demanda.texkspacios1693762891694')}
										</Text>
										<Image
											id="imagfverdocumento1693762891694"
											onClick={imagfverdocumento1693762891694OnClick}
											src={publicarverde}
											className="w-auto min-h-20px h-20px align-end position-relative" />
										<Text
											id="texthjspciosdocuemntos1693762891694"
											className="w-auto h-auto position-relative" >
											{t('qmf_consulta_demanda.texthjspciosdocuemntos1693762891694')}
										</Text>
									</Group>
								</List>
							</Group>
							<Group
								id="grojjkaanotacionescomp"
								direction="auto"
								className="w-100 h-auto position-relative p-25px" >
								<Group
									id="groupanotacionescomp"
									direction="horizontal"
									className="w-100 h-auto position-relative mt-24px" >
									<Text
										id="ttfgcshyge1233x888t1693762891694"
										tag="h2"
										className="w-auto h-auto position-relative text-primary-light ff-gantaribold" >
										{t('qmf_consulta_demanda.ttfgcshyge1233x888t1693762891694')}
									</Text>
									<Text
										id="texfgfgft1693762891694"
										className="w-fill h-auto position-relative" >
										{t('qmf_consulta_demanda.texfgfgft1693762891694')}
									</Text>
								</Group>
								<Text
									id="trrspciosllscomp"
									className="w-100 min-h-3px h-3px position-relative bg-primary-light" >
									{t('qmf_consulta_demanda.trrspciosllscomp')}
								</Text>
								<Group
									id="signioounFormanotcionescomp"
									direction="vertical"
									className="w-100 h-auto border-radius-15px align-center position-relative p-32px mt-5px scrollable" >
									<List
										id="listaanotacionesdemandas"
										src="@firebase.firestore.demandacompradores.(@cookie.keydemanda).anotaciones"
										order={{"type":"desc","field":"timestamp"}}
										className="w-100 h-auto position-relative" >
										<Group
											id="groupdetalleanotacionescomp"
											direction="vertical"
											className="w-100 h-auto border-top border-bottom border-start border-end border-radius-20px position-relative p-10px mt-10px" >
											<Group
												id="groupfechaestadoof1694194911262"
												direction="auto"
												className="w-100 h-auto position-relative" >
												<Text
													id="textfechaantoacion1694194911262"
													format={{"type":"date","day":"numeric","hour":"numeric","minute":"numeric","month":"numeric","weekday":"short","year":"numeric"}}
													src="timestamp"
													className="w-auto h-auto position-relative ff-gantaribold" >
													{t('qmf_consulta_demanda.textfechaantoacion1694194911262')}
												</Text>
												<Text
													id="textfill1694194911262"
													className="w-fill h-auto position-relative" >
													{t('qmf_consulta_demanda.textfill1694194911262')}
												</Text>
												<Text
													id="textfestadio1694194911262"
													tag="small"
													src="estado"
													className="w-auto h-auto border-radius-10px position-relative bg-accent p-5px text-text-on-accent" >
													{t('qmf_consulta_demanda.textfestadio1694194911262')}
												</Text>
											</Group>
											<Text
												id="textanota1693762891694"
												src="anotacion"
												className="w-auto h-auto position-relative" >
												{t('qmf_consulta_demanda.textanota1693762891694')}
											</Text>
										</Group>
									</List>
								</Group>
							</Group>
						</Group>
					</Group>
				</Group>
				<Group
					id="groufffp1693762891694"
					direction="auto"
					className="w-auto h-auto align-end position-relative" >
					<Text
						id="texgestionarfgft1693762891694"
						onClick={texgestionarfgft1693762891694OnClick}
						tag="mark"
						className="w-auto h-auto border-radius-20px align-end valign-end position-relative bg-primary-light-to-primary-dark p-15px me-30px mt-15px mb-20px text-text-on-accent text-center" >
						{t('qmf_consulta_demanda.texgestionarfgft1693762891694')}
					</Text>
					<Text
						id="texvofdflvergfgft1693762891694"
						onClick={texvofdflvergfgft1693762891694OnClick}
						tag="mark"
						className="w-auto h-auto border-radius-20px align-end valign-end position-relative bg-primary-light-to-primary-dark p-15px me-30px mt-15px mb-20px text-text-on-accent text-center" >
						{t('qmf_consulta_demanda.texvofdflvergfgft1693762891694')}
					</Text>
				</Group>
			</Group>
      </FullscreenWrapper>
    </>
  );
};
