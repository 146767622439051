import React from 'react';
import { useDialogs } from '../hooks';
import { useFunctions } from '../hooks';

// Custom imports:
import { Modal, Text, Button } from '../components';
import { useTranslation } from 'react-i18next';

const Qmf_politica_cookiesDialog = () => {
    const { run } = useFunctions();
    // Custom exports:
	const { t } = useTranslation();

    // Custom functions:
	const qmf_politica_cookies_buttons_positiveOnClick = async (options) => {
		run([{"id":"set1702813241850","function":"set","what":"@cookie.aceptar_cookies","value":true},{"id":"request1702813241850","function":"request","url":"https:\/\/api.ipify.org\/","into":"@cookie.ip"},{"id":"wait1702813241850","function":"wait","seconds":10},{"id":"add1702813241850","function":"add","what":{"direccionip":"@cookie.ip","timestamp":"@app.timestamp","nombrechat":"@cookie.nombrechat","usuario":"@firebase.user.id","dispositivo":"@window.navigator.platform","lenguaje":"@window.navigator.language","hostname":"@window.location.hostname"},"to":"@firebase.firestore.cookies"},{"function":"hide","dialog":"qmf_politica_cookies"}], options)
	}
	const qmf_politica_cookies_buttons_negativeOnClick = async (options) => {
		run([{"id":"set1700860560723","function":"set","what":"@cookie.aceptar_cookies","value":false},{"function":"hide","dialog":"qmf_politica_cookies"}], options)
	}

  return (
    
    
			<Modal id="qmf_politica_cookies">
					<Modal.Header closeButton>
						<Modal.Title>{t('qmf_politica_cookies.title')}</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Text
							id="text1700860260732"
							className="w-100 h-auto position-relative" >
							{t('qmf_politica_cookies.text1700860260732')}
						</Text>
					</Modal.Body>
					<Modal.Footer>
						<Button
							id="qmf_politica_cookies_buttons_negative"
							onClick={qmf_politica_cookies_buttons_negativeOnClick}
							variant="transparent"
							className="w-auto h-auto position-relative" >
							{t('qmf_politica_cookies.qmf_politica_cookies_buttons_negative')}
						</Button>
						<Button
							id="qmf_politica_cookies_buttons_positive"
							onClick={qmf_politica_cookies_buttons_positiveOnClick}
							variant="primary"
							className="w-auto h-auto position-relative" >
							{t('qmf_politica_cookies.qmf_politica_cookies_buttons_positive')}
						</Button>
					</Modal.Footer>
			</Modal>
    
  );
};

export default Qmf_politica_cookiesDialog;
