import React from 'react';
import { useDialogs } from '../hooks';
import { useFunctions } from '../hooks';

// Custom imports:
import { Modal, Button } from '../components';
import { useTranslation } from 'react-i18next';

const Ok_imagenDialog = () => {
    const { run } = useFunctions();
    // Custom exports:
	const { t } = useTranslation();

    // Custom functions:
	const ok_imagen_buttons_positiveOnClick = async (options) => {
		run([{"function":"hide","dialog":"ok_imagen"}], options)
	}
	const ok_imagenOnLoaded = async (options) => {
		run([{"id":"set1680640925482","function":"set","what":"@firebase.storage.usuarios\/(@firebase.user.id)\/foto.jpg","value":"@property.tempFile"}], options)
	}

  return (
    
    
			<Modal id="ok_imagen" onShow={ok_imagenOnLoaded}>
					<Modal.Header>
						<Modal.Title>{t('ok_imagen.title')}</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						{t('ok_imagen.text')}
					</Modal.Body>
					<Modal.Footer>
						<Button
							id="ok_imagen_buttons_positive"
							onClick={ok_imagen_buttons_positiveOnClick}
							variant="primary"
							className="w-auto h-auto position-relative" >
							{t('ok_imagen.ok_imagen_buttons_positive')}
						</Button>
					</Modal.Footer>
			</Modal>
    
  );
};

export default Ok_imagenDialog;
