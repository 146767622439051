import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ContextProviders } from './context';


// Custom imports:
import Dialogs from './dialogs';
import { Auth_destinationView, Auth_logoutView, BienvenidaView, Coach1View, Coach2View, LoginView, Qmf_administradoresView, Qmf_consulta_conversacionesView, Qmf_consulta_demandaView, Qmf_consulta_ofertaView, Qmf_conversacionesView, Qmf_demandasView, Qmf_ofertasView, Qmf_perfilView, Qmf_visitasView, QmfhistorialView, QmfhomeView, QmfhomeblancoView, QmfrelacionesView, QmfsimuladorView, Qmfsimulador_1View } from './pages';
import PrivateRoute from './PrivateRoute';


import './styles/main.scss';

function App() {
  const { t } = useTranslation();
  return (
    <>
      <Helmet>
          <title>{t('title')}</title>
          <meta name="description" content={t('description')} data-react-helmet="true" />
      </Helmet>
      <Router>
        <ContextProviders>
          <Switch>
            <Route exact path="/" component={QmfhomeblancoView} />
						<Route exact path="/auth_destination" component={Auth_destinationView} />
<Route exact path="/auth_destination/:params(.+)" component={Auth_destinationView} />
						<Route exact path="/auth_logout" component={Auth_logoutView} />
						<Route exact path="/auth_logout/:params(.+)" component={Auth_logoutView} />
						<Route exact path="/bienvenida" component={BienvenidaView} />
						<Route exact path="/bienvenida/:params(.+)" component={BienvenidaView} />
						<Route exact path="/coach1" component={Coach1View} />
						<Route exact path="/coach1/:params(.+)" component={Coach1View} />
						<Route exact path="/coach2" component={Coach2View} />
						<Route exact path="/coach2/:params(.+)" component={Coach2View} />
						<Route exact path="/login" component={LoginView} />
						<Route exact path="/login/:params(.+)" component={LoginView} />
						<PrivateRoute exact path="/qmf_administradores" component={Qmf_administradoresView} />
						<PrivateRoute exact path="/qmf_administradores/:params(.+)" component={Qmf_administradoresView} />
						<PrivateRoute exact path="/qmf_consulta_conversaciones" component={Qmf_consulta_conversacionesView} />
						<PrivateRoute exact path="/qmf_consulta_conversaciones/:params(.+)" component={Qmf_consulta_conversacionesView} />
						<PrivateRoute exact path="/qmf_consulta_demanda" component={Qmf_consulta_demandaView} />
						<PrivateRoute exact path="/qmf_consulta_demanda/:params(.+)" component={Qmf_consulta_demandaView} />
						<PrivateRoute exact path="/qmf_consulta_oferta" component={Qmf_consulta_ofertaView} />
						<PrivateRoute exact path="/qmf_consulta_oferta/:params(.+)" component={Qmf_consulta_ofertaView} />
						<PrivateRoute exact path="/qmf_conversaciones" component={Qmf_conversacionesView} />
						<PrivateRoute exact path="/qmf_conversaciones/:params(.+)" component={Qmf_conversacionesView} />
						<PrivateRoute exact path="/qmf_demandas" component={Qmf_demandasView} />
						<PrivateRoute exact path="/qmf_demandas/:params(.+)" component={Qmf_demandasView} />
						<Route exact path="/qmf_ofertas" component={Qmf_ofertasView} />
						<Route exact path="/qmf_ofertas/:params(.+)" component={Qmf_ofertasView} />
						<PrivateRoute exact path="/qmf_perfil" component={Qmf_perfilView} />
						<PrivateRoute exact path="/qmf_perfil/:params(.+)" component={Qmf_perfilView} />
						<Route exact path="/qmf_visitas" component={Qmf_visitasView} />
						<Route exact path="/qmf_visitas/:params(.+)" component={Qmf_visitasView} />
						<PrivateRoute exact path="/qmfhistorial" component={QmfhistorialView} />
						<PrivateRoute exact path="/qmfhistorial/:params(.+)" component={QmfhistorialView} />
						<Route exact path="/qmfhome" component={QmfhomeView} />
						<Route exact path="/qmfhome/:params(.+)" component={QmfhomeView} />
						<Route exact path="/qmfhomeblanco" component={QmfhomeblancoView} />
						<Route exact path="/qmfhomeblanco/:params(.+)" component={QmfhomeblancoView} />
						<PrivateRoute exact path="/qmfrelaciones" component={QmfrelacionesView} />
						<PrivateRoute exact path="/qmfrelaciones/:params(.+)" component={QmfrelacionesView} />
						<Route exact path="/qmfsimulador" component={QmfsimuladorView} />
						<Route exact path="/qmfsimulador/:params(.+)" component={QmfsimuladorView} />
						<Route exact path="/qmfsimulador_1" component={Qmfsimulador_1View} />
						<Route exact path="/qmfsimulador_1/:params(.+)" component={Qmfsimulador_1View} />
						<Route exact path="*" component={QmfhomeblancoView} />
						
          </Switch>

					<Dialogs />
        </ContextProviders>
      </Router>
    </>
  );
}

export default App;
