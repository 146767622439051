import React from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useFunctions, useRefs } from '../hooks';
import { FullscreenWrapper } from '../wrappers';

import { useLocation } from 'react-router-dom';
import { getLocationParams } from '../util';

// Custom imports:
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export const QmfhomeblancoView = () => {
  const { run } = useFunctions();
  const { search, pathname } = useLocation();


  // Custom exports:
	const { t } = useTranslation();

  // Custom functions:
	const qmfhomeblancoParams = () => {
		run( getLocationParams( search, pathname ) )
	}
	useEffect( qmfhomeblancoParams, [])
	const qmfhomeblancoOnLoaded = async (options) => {
		run([{"id":"goto1699556560341","function":"goto","view":"qmfhome"}], options)
	}
	useEffect( qmfhomeblancoOnLoaded, [])

  return (
    <>
      <Helmet>
				<title>{t('qmfhomeblanco.title')}</title>
      </Helmet>
      <FullscreenWrapper>
          
      </FullscreenWrapper>
    </>
  );
};
