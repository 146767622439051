import React from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useFunctions, useRefs } from '../hooks';
import { Qmf_wradministradoresWrapper } from '../wrappers';

import { useLocation } from 'react-router-dom';
import { getLocationParams } from '../util';

// Custom imports:
import { Group, Text, List } from '../components';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export const QmfrelacionesView = () => {
  const { run } = useFunctions();
  const { search, pathname } = useLocation();


  // Custom exports:
	const { t } = useTranslation();

  // Custom functions:
	const qmfrelacionesParams = () => {
		run( getLocationParams( search, pathname ) )
	}
	useEffect( qmfrelacionesParams, [])
	const qmfrelacionesOnLoaded = async (options) => {
		run([{"id":"goto1690107826276","function":"goto","view":"inicio","if":{"what":"@firebase.user.id","is":null}},{"id":"open1690107826276","function":"open","what":"@firebase.firestore.demandas","order":{"type":"desc","field":"timestamp"}}], options)
	}
	useEffect( qmfrelacionesOnLoaded, [])
	const textmaqwrt1690107826276OnClick = async (options) => {
		run([{"id":"delete1690107826276","function":"delete","what":"@firebase.firestore.demandas.(@element.listademandascompra.selected)","if":{"what":"@firebase.firestore.demandas.(@element.listademandascompra.selected).estado","is":"Borrador","else":[{"id":"set1690107826276","function":"set","what":"@property.textnopermitido","value":"No se puede eliminar la demanda, debe de estar en estado borrador para poderla eliminar"},{"id":"popup1690107826276","function":"popup","dialog":"accesorestringido"}]}}], options)
	}
	const temkloppaxt1690107826276OnClick = async (options) => {
		run([{"id":"set1690107826276","function":"set","what":"@cookie.keydemanda","value":"@element.listademandascompra.selected"},{"id":"set1690107826276","function":"set","what":"@property.comunidad","value":"@firebase.firestore.demandas.(@cookie.keydemanda).comunidad"},{"id":"set1690107826276","function":"set","what":"@property.provincia","value":"@firebase.firestore.demandas.(@cookie.keydemanda).provincia"},{"id":"set1690107826276","function":"set","what":"@property.zona","value":"@firebase.firestore.demandas.(@cookie.keydemanda).zona"},{"id":"set1690107826276","function":"set","what":"@property.presupuesto","value":"@firebase.firestore.demandas.(@cookie.keydemanda).presupuesto"},{"id":"set1690107826276","function":"set","what":"@property.capitalcomprador","value":"@firebase.firestore.demandas.(@cookie.keydemanda).capitalcomprador"},{"id":"set1690107826276","function":"set","what":"@property.garantias","value":"@firebase.firestore.demandas.(@cookie.keydemanda).garantias"},{"id":"set1690107826276","function":"set","what":"@property.experiencia","value":"@firebase.firestore.demandas.(@cookie.keydemanda).experiencia"},{"id":"set1690107826276","function":"set","what":"@property.observaciones","value":"@firebase.firestore.demandas.(@cookie.keydemanda).observaciones"},{"id":"goto1690107826276","function":"goto","view":"creardemanda","if":{"what":"@firebase.firestore.demandas.(@element.listademandascompra.selected).estado","is":"Borrador","else":[{"id":"set1690107826276","function":"set","what":"@property.textnopermitido","value":"El estado en que se encuentra no permite modificar."},{"id":"popup1690107826276","function":"popup","dialog":"accesorestringido"}],"or":{"what":"@firebase.firestore.demandas.(@element.listademandascompra.selected).estado","is":"Incompleta"}}}], options)
	}
	const texfhhgbt1690107826276OnClick = async (options) => {
		run([{"id":"set1690107826276","function":"set","what":"@cookie.keydemanda","value":"@element.listademandascompra.selected"},{"id":"goto1690107826276","function":"goto","view":"consulta_demanda"}], options)
	}
	const text1yyrtr1690107826276OnClick = async (options) => {
		run([{"id":"set1690107826276","function":"set","what":"@property.ini","value":"@app.timestamp"},{"id":"set1690107826276","function":"set","what":"@cookie.keyrelacion","value":"qmfrel(@property.ini)"},{"id":"goto1690107826276","function":"goto","view":"crear_relacion"}], options)
	}

  return (
    <>
      <Helmet>
				<title>{t('qmfrelaciones.title')}</title>
      </Helmet>
      <Qmf_wradministradoresWrapper>
          
			<Group
				id="groukkyy45544p1690107826276"
				direction="vertical"
				className="w-100 h-fill align-center position-relative p-20px" >
				<Text
					id="text1yyrtr1690107826276"
					onClick={text1yyrtr1690107826276OnClick}
					tag="mark"
					className="w-auto h-auto border-radius-15px align-end valign-end position-relative bg-primary-light-to-primary-dark p-15px m-15px text-text-on-accent text-center" >
					{t('qmfrelaciones.text1yyrtr1690107826276')}
				</Text>
				<Group
					id="group1690107826276"
					direction="auto"
					className="w-100 h-auto position-relative mt-24px" >
					<Text
						id="ttfgcse1233x888t1690107826276"
						className="w-auto h-auto valign-center position-relative text-primary-light f-25px ff-gantaribold" >
						{t('qmfrelaciones.ttfgcse1233x888t1690107826276')}
					</Text>
					<Text
						id="texfgfgft1690107826276"
						className="w-fill h-auto position-relative" >
						{t('qmfrelaciones.texfgfgft1690107826276')}
					</Text>
				</Group>
				<Text
					id="trrtreiooyut1690107826276"
					className="w-100 min-h-3px h-3px position-relative bg-primary-light" >
					{t('qmfrelaciones.trrtreiooyut1690107826276')}
				</Text>
				<Group
					id="signioounForm1690107826276"
					direction="vertical"
					className="w-100 h-auto align-center position-relative p-32px mt-40px" >
					<List
						id="listarelaciones"
						src="@firebase.firestore.relaciones"
						className="w-100 h-auto position-relative" >
						<Group
							id="group1690107826276"
							direction="auto"
							className="w-100 h-auto border-top border-bottom border-start border-end border-radius-20px position-relative p-10px mt-10px" >
							<Group
								id="grouhdhdp1690107826276"
								direction="vertical"
								className="w-auto h-auto position-relative mt-15px" >
								<Text
									id="texjjkoppt1690107826276"
									format={{"type":"date","day":"numeric","hour":"numeric","month":"numeric","year":"numeric","minute":"numeric"}}
									src="timestamp"
									className="w-fill h-auto position-relative m-10px ff-gantaribold" >
									{t('qmfrelaciones.texjjkoppt1690107826276')}
								</Text>
								<Text
									id="texjjkoppdddt1691084416263"
									src="@firebase.firestore.usuarios.(@field.vendedor).nombre"
									className="w-fill h-auto position-relative m-10px ff-gantaribold" >
									{t('qmfrelaciones.texjjkoppdddt1691084416263')}
								</Text>
								<Text
									id="textghtfdds1690107826276"
									tag="p"
									src="oferta"
									className="w-auto h-auto position-relative" >
									{t('qmfrelaciones.textghtfdds1690107826276')}
								</Text>
								<Text
									id="textghtfdds1690620313644"
									tag="p"
									src="demanda"
									className="w-auto h-auto position-relative" >
									{t('qmfrelaciones.textghtfdds1690620313644')}
								</Text>
								<Text
									id="texjjkoppdddt1691085481709"
									src="@firebase.firestore.usuarios.(@field.comprador).nombre"
									className="w-fill h-auto position-relative m-10px ff-gantaribold" >
									{t('qmfrelaciones.texjjkoppdddt1691085481709')}
								</Text>
							</Group>
							<Text
								id="texghhhhaqt1690107826276"
								className="w-fill h-auto position-relative" >
								{t('qmfrelaciones.texghhhhaqt1690107826276')}
							</Text>
							<Text
								id="tehhuqxt1690107826276"
								tag="h3"
								src="estado"
								className="w-auto h-auto border-radius-20px position-relative bg-accent p-20px text-text-on-accent" >
								{t('qmfrelaciones.tehhuqxt1690107826276')}
							</Text>
							<Group
								id="groupmenulista1690107826276"
								visible={false}
								direction="auto"
								className="w-100 h-auto position-relative mt-20px" >
								<Text
									id="texgggt1690107826276"
									className="w-fill h-auto position-relative" >
									{t('qmfrelaciones.texgggt1690107826276')}
								</Text>
								<Text
									id="texfhhgbt1690107826276"
									onClick={texfhhgbt1690107826276OnClick}
									tag="sup"
									className="w-auto h-auto border-radius-10px position-relative bg-primary-light-to-primary-dark p-15px me-15px text-text-on-accent text-center" >
									{t('qmfrelaciones.texfhhgbt1690107826276')}
								</Text>
								<Text
									id="temkloppaxt1690107826276"
									onClick={temkloppaxt1690107826276OnClick}
									tag="sup"
									className="w-auto h-auto border-radius-10px position-relative bg-primary-light-to-primary-dark p-15px me-15px text-text-on-accent text-center" >
									{t('qmfrelaciones.temkloppaxt1690107826276')}
								</Text>
								<Text
									id="textmaqwrt1690107826276"
									onClick={textmaqwrt1690107826276OnClick}
									tag="sup"
									className="w-auto h-auto border-radius-10px position-relative bg-primary-light-to-primary-dark p-15px me-15px text-text-on-accent text-center" >
									{t('qmfrelaciones.textmaqwrt1690107826276')}
								</Text>
							</Group>
						</Group>
					</List>
				</Group>
			</Group>
			<Text
				id="text1701716227811"
				className="w-100 h-auto position-relative" >
			</Text>
      </Qmf_wradministradoresWrapper>
    </>
  );
};
