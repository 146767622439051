import React from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useFunctions, useRefs } from '../hooks';
import { Qmf_wradministradoresWrapper } from '../wrappers';

import { useLocation } from 'react-router-dom';
import { getLocationParams } from '../util';

// Custom imports:
import { Group, Text, Input, List } from '../components';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export const Qmf_demandasView = () => {
  const { run } = useFunctions();
  const { search, pathname } = useLocation();


  // Custom exports:
	const { t } = useTranslation();

  // Custom functions:
	const qmf_demandasParams = () => {
		run( getLocationParams( search, pathname ) )
	}
	useEffect( qmf_demandasParams, [])
	const qmf_demandasOnLoaded = async (options) => {
		run([{"id":"goto1694945509874","function":"goto","view":"qmfhome","if":{"what":"@firebase.user.id","is":null}},{"id":"set1694945509874","function":"set","what":"@property.desde","value":0},{"id":"set1694945509874","function":"set","what":"@property.hasta","value":4102444800000},{"id":"set1694945509874","function":"set","what":"@property.estadodem","value":"todas"}], options)
	}
	useEffect( qmf_demandasOnLoaded, [])
	const texfhhgbt1695056884367OnClick = async (options) => {
		run([{"id":"set1695056884367","function":"set","what":"@cookie.keydemanda","value":"@element.lista_demandas.selected"},{"id":"goto1695056884367","function":"goto","view":"qmf_consulta_demanda"}], options)
	}
	const texfhhgbt1693761942969OnClick = async (options) => {
		run([{"id":"set1693761942969","function":"set","what":"@cookie.keydemanda","value":"@element.lista_demandas.selected"},{"id":"goto1693761942969","function":"goto","view":"qmf_consulta_demanda"}], options)
	}
	const inputtextdedes1693761942969OnChanged = async (options) => {
		run([{"id":"set1693761942969","function":"set","what":"@property.desde","value":0,"if":{"what":"@property.desde","more_than":"@property.hasta"}},{"id":"set1693761942969","function":"set","what":"@property.desde","value":0,"if":{"what":"@property.desde","is":"@property.hasta"}}], options)
	}

  return (
    <>
      <Helmet>
				<title>{t('qmf_demandas.title')}</title>
      </Helmet>
      <Qmf_wradministradoresWrapper>
          
			<Group
				id="groukkyy45544p1693761942969"
				direction="vertical"
				className="w-100 h-fill align-center position-relative p-20px" >
				<Group
					id="groukkkkp1693761942969"
					direction="auto"
					className="w-100 h-auto position-relative mt-24px" >
					<Text
						id="ttfgcse1233x888t1693761942969"
						className="w-auto h-auto valign-center position-relative text-primary-light f-25px ff-gantaribold" >
						{t('qmf_demandas.ttfgcse1233x888t1693761942969')}
					</Text>
					<Text
						id="texfgfgft1693761942969"
						className="w-fill h-auto position-relative" >
						{t('qmf_demandas.texfgfgft1693761942969')}
					</Text>
				</Group>
				<Group
					id="groupfiltroofer1693761942969"
					direction="auto"
					className="w-100 h-auto position-relative" >
					<Group
						id="groupdesde1693761942969"
						direction="horizontal"
						className="w-auto h-auto position-relative" >
						<Text
							id="texdedesd1693761942969"
							className="w-auto h-auto position-relative m-5px text-primary" >
							{t('qmf_demandas.texdedesd1693761942969')}
						</Text>
						<Input.Date
							id="inputtextdedes1693761942969"
							name="text"
							src="@property.desde"
						placeholder={t('qmf_demandas.inputtextdedes1693761942969.hint')}
						autoComplete="off"
						onChange={inputtextdedes1693761942969OnChanged}
							className="w-auto h-auto position-relative m-5px" />
					</Group>
					<Group
						id="grouphasta1693761942969"
						direction="horizontal"
						className="w-auto h-auto position-relative" >
						<Text
							id="tehasta1693761942969"
							className="w-auto h-auto position-relative m-5px text-primary" >
							{t('qmf_demandas.tehasta1693761942969')}
						</Text>
						<Input.Date
							id="inputtexthasta1693761942969"
							name="text"
							src="@property.hasta"
						placeholder={t('qmf_demandas.inputtexthasta1693761942969.hint')}
						autoComplete="off"
							className="w-auto h-auto position-relative m-5px" />
					</Group>
					<Group
						id="groupestado1693761942969"
						direction="horizontal"
						className="w-auto h-auto position-relative" >
						<Text
							id="teestado61942969"
							className="w-auto h-auto position-relative m-5px text-primary" >
							{t('qmf_demandas.teestado61942969')}
						</Text>
						<Input.Select
							id="selectestado1693761942969"
							name="select"
							src="@property.estadodem"
							dataSrc="@firebase.firestore.estados_demandas"
							className="w-auto h-auto position-relative" >
						</Input.Select>
					</Group>
				</Group>
				<Text
					id="trrtreiooyut1693761942969"
					className="w-100 min-h-3px h-3px position-relative bg-primary-light" >
					{t('qmf_demandas.trrtreiooyut1693761942969')}
				</Text>
				<Group
					id="signioounForm1693761942969"
					direction="vertical"
					className="w-100 h-auto border-radius-15px align-center position-relative p-32px mt-40px" >
					<List
						id="lista_demandas"
						src="@firebase.firestore.demandacompradores"
						filter={{"what":"fecha_creacion","more_equal_than":"@property.desde","and":{"what":"fecha_creacion","less_than":"@property.hasta"}}}
						order={{"type":"desc","field":"fecha_creacion"}}
						className="w-100 h-auto position-relative" >
						<Group
							id="groupdetalle1693761942969"
							if={{"what":"@field.estado","is":"@property.estadodem"}}
							direction="vertical"
							className="w-100 h-auto border-top border-bottom border-start border-end border-radius-20px position-relative p-10px mt-10px" >
							<Group
								id="groupdetallelista1693761942969"
								direction="auto"
								className="w-fill h-auto position-relative p-10px" >
								<Text
									id="textghtfdds1693761942969"
									tag="p"
									className="w-auto h-auto position-relative" >
									{t('qmf_demandas.textghtfdds1693761942969')}
								</Text>
								<Text
									id="texghhhhaqt1693761942969"
									className="w-fill h-auto position-relative" >
									{t('qmf_demandas.texghhhhaqt1693761942969')}
								</Text>
								<Text
									id="tehhuqxt1693761942969"
									tag="h3"
									src="estado"
									className="w-auto h-auto border-radius-20px position-relative bg-accent p-20px text-text-on-accent" >
									{t('qmf_demandas.tehhuqxt1693761942969')}
								</Text>
							</Group>
							<Group
								id="groupprincida1693761942969"
								direction="auto"
								className="w-100 h-auto position-relative" >
								<Group
									id="groupdato1693761942969"
									direction="vertical"
									className="min-w-600px w-fill h-auto position-relative p-10px" >
									<Group
										id="grouhdhfech1693761942969"
										direction="horizontal"
										className="w-100 h-auto position-relative" >
										<Text
											id="texjjkoppt1693761942969"
											className="w-auto h-auto position-relative ff-gantaribold" >
											{t('qmf_demandas.texjjkoppt1693761942969')}
										</Text>
										<Text
											id="texjjkoppt1693761942969"
											format={{"type":"date","day":"numeric","hour":"numeric","month":"numeric","year":"numeric","minute":"numeric"}}
											src="fecha_creacion"
											className="w-fill h-auto position-relative ff-gantaribold" >
											{t('qmf_demandas.texjjkoppt1693761942969')}
										</Text>
									</Group>
									<Group
										id="grouhhhnom1693761942969"
										direction="horizontal"
										className="w-100 h-auto position-relative" >
										<Text
											id="texjjkdeohjjppt1693761942969"
											className="w-fill h-auto position-relative ff-gantaribold" >
											{t('qmf_demandas.texjjkdeohjjppt1693761942969')}
										</Text>
									</Group>
									<Group
										id="grouhhmail1693761942969"
										direction="horizontal"
										className="w-100 h-auto position-relative" >
										<Text
											id="texjjkdeohhntdjjppt1693761942969"
											className="w-fill h-auto position-relative ff-gantaribold" >
											{t('qmf_demandas.texjjkdeohhntdjjppt1693761942969')}
										</Text>
									</Group>
									<Group
										id="grouhhtelf1693761942969"
										direction="horizontal"
										className="w-100 h-auto position-relative" >
										<Text
											id="texjjtelf1693761942969"
											className="w-fill h-auto position-relative ff-gantaribold" >
											{t('qmf_demandas.texjjtelf1693761942969')}
										</Text>
									</Group>
								</Group>
							</Group>
							<Group
								id="groupmenulista1693761942969"
								direction="auto"
								className="w-100 h-auto position-relative mt-20px" >
								<Text
									id="texgggt1693761942969"
									className="w-fill h-auto position-relative" >
									{t('qmf_demandas.texgggt1693761942969')}
								</Text>
								<Text
									id="texfhhgbt1693761942969"
									onClick={texfhhgbt1693761942969OnClick}
									tag="sup"
									className="w-auto h-auto border-radius-10px position-relative bg-primary-light-to-primary-dark p-15px me-15px text-text-on-accent text-center" >
									{t('qmf_demandas.texfhhgbt1693761942969')}
								</Text>
							</Group>
						</Group>
						<Group
							id="groupdetalletodasdem1695056884367"
							if={{"what":"@property.estadodem","is":"todas"}}
							direction="vertical"
							className="w-100 h-auto border-top border-bottom border-start border-end border-radius-20px position-relative p-10px mt-10px" >
							<Group
								id="groupdetallelista1695056884367"
								direction="auto"
								className="w-fill h-auto position-relative p-10px" >
								<Text
									id="textghtfdds1695056884367"
									tag="p"
									className="w-auto h-auto position-relative" >
									{t('qmf_demandas.textghtfdds1695056884367')}
								</Text>
								<Text
									id="texghhhhaqt1695056884367"
									className="w-fill h-auto position-relative" >
									{t('qmf_demandas.texghhhhaqt1695056884367')}
								</Text>
								<Text
									id="tehhuqxt1695056884367"
									tag="h3"
									src="estado"
									className="w-auto h-auto border-radius-20px position-relative bg-accent p-20px text-text-on-accent" >
									{t('qmf_demandas.tehhuqxt1695056884367')}
								</Text>
							</Group>
							<Group
								id="groupprincida1695056884367"
								direction="auto"
								className="w-100 h-auto position-relative" >
								<Group
									id="groupdato1695056884367"
									direction="vertical"
									className="min-w-600px w-fill h-auto position-relative p-10px" >
									<Group
										id="grouhdhfech1695056884367"
										direction="horizontal"
										className="w-100 h-auto position-relative" >
										<Text
											id="texjjkoppt1695056884367"
											className="w-auto h-auto position-relative ff-gantaribold" >
											{t('qmf_demandas.texjjkoppt1695056884367')}
										</Text>
										<Text
											id="texjjkoppt1695056884367"
											format={{"type":"date","day":"numeric","hour":"numeric","month":"numeric","year":"numeric","minute":"numeric"}}
											src="fecha_creacion"
											className="w-fill h-auto position-relative ff-gantaribold" >
											{t('qmf_demandas.texjjkoppt1695056884367')}
										</Text>
									</Group>
									<Group
										id="grouhhhnom1695056884367"
										direction="horizontal"
										className="w-100 h-auto position-relative" >
										<Text
											id="texjjkdeohjjppt1695056884367"
											className="w-fill h-auto position-relative ff-gantaribold" >
											{t('qmf_demandas.texjjkdeohjjppt1695056884367')}
										</Text>
									</Group>
									<Group
										id="grouhhmail1695056884367"
										direction="horizontal"
										className="w-100 h-auto position-relative" >
										<Text
											id="texjjkdeohhntdjjppt1695056884367"
											className="w-fill h-auto position-relative ff-gantaribold" >
											{t('qmf_demandas.texjjkdeohhntdjjppt1695056884367')}
										</Text>
									</Group>
									<Group
										id="grouhhtelf1695056884367"
										direction="horizontal"
										className="w-100 h-auto position-relative" >
										<Text
											id="texjjtelf1695056884367"
											className="w-fill h-auto position-relative ff-gantaribold" >
											{t('qmf_demandas.texjjtelf1695056884367')}
										</Text>
									</Group>
								</Group>
							</Group>
							<Group
								id="groupmenulista1695056884367"
								direction="auto"
								className="w-100 h-auto position-relative mt-20px" >
								<Text
									id="texgggt1695056884367"
									className="w-fill h-auto position-relative" >
									{t('qmf_demandas.texgggt1695056884367')}
								</Text>
								<Text
									id="texfhhgbt1695056884367"
									onClick={texfhhgbt1695056884367OnClick}
									tag="sup"
									className="w-auto h-auto border-radius-10px position-relative bg-primary-light-to-primary-dark p-15px me-15px text-text-on-accent text-center" >
									{t('qmf_demandas.texfhhgbt1695056884367')}
								</Text>
							</Group>
						</Group>
					</List>
				</Group>
			</Group>
      </Qmf_wradministradoresWrapper>
    </>
  );
};
