import React from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useFunctions, useRefs } from '../hooks';
import { Qmf_wradministradoresWrapper } from '../wrappers';

import { useLocation } from 'react-router-dom';
import { getLocationParams } from '../util';

// Custom imports:
import { Group, Text, Input, List } from '../components';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export const Qmf_conversacionesView = () => {
  const { run } = useFunctions();
  const { search, pathname } = useLocation();


  // Custom exports:
	const { t } = useTranslation();

  // Custom functions:
	const qmf_conversacionesParams = () => {
		run( getLocationParams( search, pathname ) )
	}
	useEffect( qmf_conversacionesParams, [])
	const qmf_conversacionesOnLoaded = async (options) => {
		run([{"id":"goto1694131818059","function":"goto","view":"qmfhome","if":{"what":"@firebase.user.id","is":null}},{"id":"set1694020093320","function":"set","what":"@property.desde","value":0},{"id":"set1694020093320","function":"set","what":"@property.hasta","value":4102444800000}], options)
	}
	useEffect( qmf_conversacionesOnLoaded, [])
	const texeliminarOnClick = async (options) => {
		run([{"id":"delete1693935429948","function":"delete","what":"@firebase.firestore.chatbot.(@element.lista_chatbot.selected)"}], options)
	}
	const texfhhgbt1693930037565OnClick = async (options) => {
		run([{"id":"set1693930037565","function":"set","what":"@cookie.keychatbot","value":"@element.lista_chatbot.selected"},{"id":"goto1693930037565","function":"goto","view":"qmf_consulta_conversaciones"}], options)
	}
	const inputtextdedes1693930037565OnChanged = async (options) => {
		run([{"id":"set1693930037565","function":"set","what":"@property.desde","value":0,"if":{"what":"@property.desde","more_than":"@property.hasta"}},{"id":"set1693930037565","function":"set","what":"@property.desde","value":0,"if":{"what":"@property.desde","is":"@property.hasta"}}], options)
	}

  return (
    <>
      <Helmet>
				<title>{t('qmf_conversaciones.title')}</title>
      </Helmet>
      <Qmf_wradministradoresWrapper>
          
			<Group
				id="groukkyy45544p1693930037565"
				direction="vertical"
				className="w-100 h-fill align-center position-relative p-20px" >
				<Group
					id="group1693930037565"
					direction="auto"
					className="w-100 h-auto position-relative mt-24px" >
					<Text
						id="ttfgcse1233x888t1693930037565"
						className="w-auto h-auto valign-center position-relative text-primary-light f-25px ff-gantaribold" >
						{t('qmf_conversaciones.ttfgcse1233x888t1693930037565')}
					</Text>
					<Text
						id="texfgfgft1693930037565"
						className="w-fill h-auto position-relative" >
						{t('qmf_conversaciones.texfgfgft1693930037565')}
					</Text>
				</Group>
				<Group
					id="groupfiltroofer1693930037565"
					direction="auto"
					className="w-100 h-auto position-relative" >
					<Group
						id="groupdesde1693930037565"
						direction="horizontal"
						className="w-auto h-auto position-relative" >
						<Text
							id="texdedesd1693930037565"
							className="w-auto h-auto position-relative m-5px text-primary" >
							{t('qmf_conversaciones.texdedesd1693930037565')}
						</Text>
						<Input.Date
							id="inputtextdedes1693930037565"
							name="text"
							src="@property.desde"
						placeholder={t('qmf_conversaciones.inputtextdedes1693930037565.hint')}
						autoComplete="off"
						onChange={inputtextdedes1693930037565OnChanged}
							className="w-auto h-auto position-relative m-5px" />
					</Group>
					<Group
						id="grouphasta1693930037565"
						direction="horizontal"
						className="w-auto h-auto position-relative" >
						<Text
							id="tehasta1693930037565"
							className="w-auto h-auto position-relative m-5px text-primary" >
							{t('qmf_conversaciones.tehasta1693930037565')}
						</Text>
						<Input.Date
							id="inputtexthasta1693930037565"
							name="text"
							src="@property.hasta"
						placeholder={t('qmf_conversaciones.inputtexthasta1693930037565.hint')}
						autoComplete="off"
							className="w-auto h-auto position-relative m-5px" />
					</Group>
				</Group>
				<Text
					id="trrtreiooyut1693930037565"
					className="w-100 min-h-3px h-3px position-relative bg-primary-light" >
					{t('qmf_conversaciones.trrtreiooyut1693930037565')}
				</Text>
				<Group
					id="signioounForm1693930037565"
					direction="vertical"
					className="w-100 h-auto border-radius-15px align-center position-relative p-32px mt-40px" >
					<List
						id="lista_chatbot"
						src="@firebase.firestore.chatbot"
						filter={{"what":"timestamp","more_equal_than":"@property.desde","and":{"what":"timestamp","less_than":"@property.hasta"}}}
						order={{"type":"desc","field":"timestamp"}}
						className="w-100 h-auto position-relative" >
						<Group
							id="group1693930037565"
							if={{"what":"@field.estado","is_not":"Borrador"}}
							direction="vertical"
							className="w-100 h-auto border-top border-bottom border-start border-end border-radius-20px position-relative p-10px mt-10px" >
							<Group
								id="groupprincida1693930037565"
								direction="auto"
								className="w-100 h-auto position-relative" >
								<Group
									id="groupdato1693930037565"
									direction="vertical"
									className="min-w-600px w-fill h-auto position-relative p-10px" >
									<Group
										id="grouhdhfech1693930037565"
										direction="horizontal"
										className="w-100 h-auto position-relative" >
										<Text
											id="texjjkoppt1693930037565"
											className="w-auto h-auto position-relative ff-gantaribold" >
											{t('qmf_conversaciones.texjjkoppt1693930037565')}
										</Text>
										<Text
											id="texjjkoppt1693930037565"
											format={{"type":"date","day":"numeric","hour":"numeric","month":"numeric","year":"numeric","minute":"numeric"}}
											src="timestamp"
											className="w-fill h-auto position-relative ff-gantaribold" >
											{t('qmf_conversaciones.texjjkoppt1693930037565')}
										</Text>
									</Group>
									<Group
										id="grouhhhnom1693930037565"
										direction="horizontal"
										className="w-100 h-auto position-relative" >
										<Text
											id="texjjkdeohjjppt1693930037565"
											className="w-fill h-auto position-relative ff-gantaribold" >
											{t('qmf_conversaciones.texjjkdeohjjppt1693930037565')}
										</Text>
									</Group>
								</Group>
							</Group>
							<Group
								id="groupmenulista1693930037565"
								direction="auto"
								className="w-100 h-auto position-relative mt-20px" >
								<Text
									id="texgggt1693930037565"
									className="w-fill h-auto position-relative" >
									{t('qmf_conversaciones.texgggt1693930037565')}
								</Text>
								<Text
									id="texfhhgbt1693930037565"
									onClick={texfhhgbt1693930037565OnClick}
									tag="sup"
									className="w-auto h-auto border-radius-10px position-relative bg-primary-light-to-primary-dark p-15px me-15px text-text-on-accent text-center" >
									{t('qmf_conversaciones.texfhhgbt1693930037565')}
								</Text>
								<Text
									id="texeliminar"
									onClick={texeliminarOnClick}
									tag="sup"
									className="w-auto h-auto border-radius-10px position-relative bg-primary-light-to-primary-dark p-15px me-15px text-text-on-accent text-center" >
									{t('qmf_conversaciones.texeliminar')}
								</Text>
							</Group>
						</Group>
					</List>
				</Group>
			</Group>
      </Qmf_wradministradoresWrapper>
    </>
  );
};
