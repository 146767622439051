import React from 'react';
import { useDialogs } from '../hooks';
import { useFunctions } from '../hooks';

// Custom imports:
import { Modal, Text, Input, Button } from '../components';
import { useTranslation } from 'react-i18next';

const Qmf_editar_campoDialog = () => {
    const { run } = useFunctions();
    // Custom exports:
	const { t } = useTranslation();

    // Custom functions:
	const qmf_editar_campo_buttons_positiveOnClick = async (options) => {
		run([{"function":"hide","dialog":"qmf_editar_campo"}], options)
	}

  return (
    
    
			<Modal id="qmf_editar_campo">
					<Modal.Header>
						<Modal.Title>{t('qmf_editar_campo.title')}</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Text
							id="text1705513664997"
							tag="h1"
							src="@property.nombrecampo"
							className="w-100 h-auto position-relative" >
						</Text>
						<Input.Text
							id="inputtext1705513741228"
							name="text"
							src="@firebase.firestore.usuarios.(@firebase.user.id).(@property.campo)"
						autoComplete="off"
							className="w-auto h-auto position-relative" />
					</Modal.Body>
					<Modal.Footer>
						<Button
							id="qmf_editar_campo_buttons_positive"
							onClick={qmf_editar_campo_buttons_positiveOnClick}
							variant="primary"
							className="w-auto h-auto position-relative" >
							{t('qmf_editar_campo.qmf_editar_campo_buttons_positive')}
						</Button>
					</Modal.Footer>
			</Modal>
    
  );
};

export default Qmf_editar_campoDialog;
