import React from 'react';
import { useDialogs } from '../hooks';
import { useFunctions } from '../hooks';

// Custom imports:
import { Modal, Group, Text, Button } from '../components';
import { useTranslation } from 'react-i18next';

const Qmf_advertencia_cookiesDialog = () => {
    const { run } = useFunctions();
    // Custom exports:
	const { t } = useTranslation();

    // Custom functions:
	const qmf_advertencia_cookies_buttons_positiveOnClick = async (options) => {
		run([{"id":"set1700592639353","function":"set","what":"@cookie.aceptar_cookies","value":true},{"function":"hide","dialog":"qmf_advertencia_cookies"}], options)
	}

  return (
    
    
			<Modal id="qmf_advertencia_cookies" fullscreen="sm-down" size="lg">
					<Modal.Body>
						<Group
							id="group1700592403343"
							direction="vertical"
							className="w-100 h-auto position-relative" >
							<Text
								id="text1700592473172"
								tag="p"
								className="w-100 h-auto position-relative text-accent" >
								{t('qmf_advertencia_cookies.text1700592473172')}
							</Text>
						</Group>
					</Modal.Body>
					<Modal.Footer>
						<Button
							id="qmf_advertencia_cookies_buttons_positive"
							onClick={qmf_advertencia_cookies_buttons_positiveOnClick}
							variant="primary"
							className="w-auto h-auto position-relative" >
							{t('qmf_advertencia_cookies.qmf_advertencia_cookies_buttons_positive')}
						</Button>
					</Modal.Footer>
			</Modal>
    
  );
};

export default Qmf_advertencia_cookiesDialog;
