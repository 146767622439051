import React from 'react';

// Custom imports:
import Error_imagenDialog from './Error_imagenDialog';
import AccesorestringidoDialog from './AccesorestringidoDialog';
import Anadir_documentoDialog from './Anadir_documentoDialog';
import Anadir_documento_demandaDialog from './Anadir_documento_demandaDialog';
import Anadir_documento_ofertaDialog from './Anadir_documento_ofertaDialog';
import Auth_dialog_1Dialog from './Auth_dialog_1Dialog';
import Auth_terms_1Dialog from './Auth_terms_1Dialog';
import Crear_inversionDialog from './Crear_inversionDialog';
import Crear_subastaDialog from './Crear_subastaDialog';
import Crear_valoracionDialog from './Crear_valoracionDialog';
import Error_archivoDialog from './Error_archivoDialog';
import ErrorpujaDialog from './ErrorpujaDialog';
import ExitopujaDialog from './ExitopujaDialog';
import GestionarofertaDialog from './GestionarofertaDialog';
import Ok_imagenDialog from './Ok_imagenDialog';
import PujaDialog from './PujaDialog';
import Qmf_advertenciaDialog from './Qmf_advertenciaDialog';
import Qmf_advertencia_cookiesDialog from './Qmf_advertencia_cookiesDialog';
import Qmf_anadir_documento_demandaDialog from './Qmf_anadir_documento_demandaDialog';
import Qmf_anadir_documento_ofertaDialog from './Qmf_anadir_documento_ofertaDialog';
import Qmf_comprobar_numero_demandaDialog from './Qmf_comprobar_numero_demandaDialog';
import Qmf_editar_campoDialog from './Qmf_editar_campoDialog';
import Qmf_envio_de_numeroDialog from './Qmf_envio_de_numeroDialog';
import Qmf_envio_realizadoDialog from './Qmf_envio_realizadoDialog';
import Qmf_error_numeroDialog from './Qmf_error_numeroDialog';
import Qmf_gestionardemandaDialog from './Qmf_gestionardemandaDialog';
import Qmf_gestionarofertaDialog from './Qmf_gestionarofertaDialog';
import Qmf_numero_correctoDialog from './Qmf_numero_correctoDialog';
import Qmf_politica_cookiesDialog from './Qmf_politica_cookiesDialog';
import Template_dialogDialog from './Template_dialogDialog';



export const Dialogs = () => {
  return (
    <>
      
			<Error_imagenDialog />
			<AccesorestringidoDialog />
			<Anadir_documentoDialog />
			<Anadir_documento_demandaDialog />
			<Anadir_documento_ofertaDialog />
			<Auth_dialog_1Dialog />
			<Auth_terms_1Dialog />
			<Crear_inversionDialog />
			<Crear_subastaDialog />
			<Crear_valoracionDialog />
			<Error_archivoDialog />
			<ErrorpujaDialog />
			<ExitopujaDialog />
			<GestionarofertaDialog />
			<Ok_imagenDialog />
			<PujaDialog />
			<Qmf_advertenciaDialog />
			<Qmf_advertencia_cookiesDialog />
			<Qmf_anadir_documento_demandaDialog />
			<Qmf_anadir_documento_ofertaDialog />
			<Qmf_comprobar_numero_demandaDialog />
			<Qmf_editar_campoDialog />
			<Qmf_envio_de_numeroDialog />
			<Qmf_envio_realizadoDialog />
			<Qmf_error_numeroDialog />
			<Qmf_gestionardemandaDialog />
			<Qmf_gestionarofertaDialog />
			<Qmf_numero_correctoDialog />
			<Qmf_politica_cookiesDialog />
			<Template_dialogDialog />
      
    </>
  );
};

export default Dialogs;
