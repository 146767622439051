import React from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';

export const PrivateRoute = ({ component: Component, ...rest }) => {
  const location = useLocation();
  let pathname = 'login';
  const sessionId = Cookies.get('__sid') || null;
  const isPermitted = ( sessionId !== null && sessionId !== 'null' );

  return (
    <Route
      {...rest}
      render={(props) =>
        isPermitted ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              ...location,
              pathname,
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
