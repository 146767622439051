import React from 'react';
import { useDialogs } from '../hooks';
import { useFunctions } from '../hooks';

// Custom imports:
import { Modal, Group, Input, Button } from '../components';
import { useTranslation } from 'react-i18next';

const GestionarofertaDialog = () => {
    const { run } = useFunctions();
    // Custom exports:
	const { t } = useTranslation();

    // Custom functions:
	const gestionaroferta_buttons_positiveOnClick = async (options) => {
		run([{"id":"add1690134250597","function":"add","what":{"anotacion":"@property.anotacion","timestamp":"@app.timestamp"},"to":"@firebase.firestore.ofertas.(@cookie.keyoferta).anotaciones","if":{"what":"@property.estado","is_not":"@propety.vacio","else":[{"id":"popup1690134250597","function":"popup","dialog":"gestionaroferta"}]}},{"id":"set1690134250597","function":"set","what":"@firebase.firestore.ofertas.(@cookie.keyoferta).estado","value":"@property.estado","if":{"what":"@property.estado","is_not":"@property.vacio"}},{"id":"set1690215032340","function":"set","what":"@property.nombre","value":"@firebase.firestore.ofertas.(@cookie.keyoferta).nombre"},{"id":"set1690215032340","function":"set","what":"@property.vendedor","value":"@firebase.firestore.ofertas.(@cookie.keyoferta).vendedor"},{"id":"add1690215032340","function":"add","what":{"email":"@firebase.firestore.usuarios.(@property.vendedor).email","asunto":"Demanda (@property.estado)","contenido":"Estimado (@property.nombre), Le informamos que el estado de su demanda de compra de farmacia enviada a pasado a estado (@property.estado). Para m\u00e1s informaci\u00f3n consulte las anotaciones del expediente.. Atentamente, Alejandro Pedraza Equipo de quieromifarma.com"},"to":"@firebase.firestore.enviarmail","if":{"what":"@property.estado","is_not":"@property.vacio"}},{"id":"add1690215032340","function":"add","what":{"text":"Oferta (@cookie.keyoferta) ha cambiado a estado (@property.estado)","timestamp":"@app.timestamp"},"to":"@firebase.firestore.usuarios.(@property.vendedor).notificaciones","if":{"what":"@property.estado","is_not":"@property.vacio"}},{"id":"set1690215032340","function":"set","what":"@firebase.firestore.usuarios.(@property.comprador).notificaciones","value":true},{"function":"hide","dialog":"gestionaroferta"}], options)
	}
	const gestionaroferta_buttons_negativeOnClick = async (options) => {
		run([{"function":"hide","dialog":"gestionaroferta"}], options)
	}
	const gestionarofertaOnLoaded = async (options) => {
		run([{"id":"set1690134250597","function":"set","what":"@property.estado","value":"@property.vacio"},{"id":"set1690134250597","function":"set","what":"@property.anotacion","value":"@property.vacio"}], options)
	}

  return (
    
    
			<Modal id="gestionaroferta" onShow={gestionarofertaOnLoaded}>
					<Modal.Header closeButton>
						<Modal.Title>{t('gestionaroferta.title')}</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Group
							id="group1690134250597"
							direction="vertical"
							className="w-100 h-auto position-relative" >
							<Input.TextArea
								id="inputtext1690134250597"
								label={t('gestionaroferta.inputtext1690134250597.label')}
								name="text"
								src="@property.anotacion"
							placeholder={t('gestionaroferta.inputtext1690134250597.hint')}
							autoComplete="off"
								className="w-100 h-auto position-relative" />
							<Input.Select
								id="select1690134250597"
								label={t('gestionaroferta.select1690134250597.label')}
								name="select"
								src="@property.estado"
								className="w-100 h-auto position-relative" >
								<option value={"@property.vacio"}>{t('gestionaroferta.select1690134250597.opt0')}</option>
								<option value={"Incompleta"}>{t('gestionaroferta.select1690134250597.opt1')}</option>
								<option value={"Rechazada"}>{t('gestionaroferta.select1690134250597.opt2')}</option>
								<option value={"Aceptada"}>{t('gestionaroferta.select1690134250597.opt3')}</option>
							</Input.Select>
						</Group>
					</Modal.Body>
					<Modal.Footer>
						<Button
							id="gestionaroferta_buttons_negative"
							onClick={gestionaroferta_buttons_negativeOnClick}
							variant="transparent"
							className="w-auto h-auto position-relative" >
							{t('gestionaroferta.gestionaroferta_buttons_negative')}
						</Button>
						<Button
							id="gestionaroferta_buttons_positive"
							onClick={gestionaroferta_buttons_positiveOnClick}
							variant="primary"
							className="w-auto h-auto position-relative" >
							{t('gestionaroferta.gestionaroferta_buttons_positive')}
						</Button>
					</Modal.Footer>
			</Modal>
    
  );
};

export default GestionarofertaDialog;
