import React from 'react';
import { useDialogs } from '../hooks';
import { useFunctions } from '../hooks';

// Custom imports:
import { Modal, Text, Button } from '../components';
import { useTranslation } from 'react-i18next';

const Qmf_numero_correctoDialog = () => {
    const { run } = useFunctions();
    // Custom exports:
	const { t } = useTranslation();

    // Custom functions:
	const qmf_numero_correcto_buttons_positiveOnClick = async (options) => {
		run([{"function":"hide","dialog":"qmf_numero_correcto"}], options)
	}

  return (
    
    
			<Modal id="qmf_numero_correcto">
					<Modal.Header>
						<Modal.Title>{t('qmf_numero_correcto.title')}</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Text
							id="text1691262600216"
							className="w-100 h-auto position-relative" >
							{t('qmf_numero_correcto.text1691262600216')}
						</Text>
					</Modal.Body>
					<Modal.Footer>
						<Button
							id="qmf_numero_correcto_buttons_positive"
							onClick={qmf_numero_correcto_buttons_positiveOnClick}
							variant="primary"
							className="w-auto h-auto position-relative" >
							{t('qmf_numero_correcto.qmf_numero_correcto_buttons_positive')}
						</Button>
					</Modal.Footer>
			</Modal>
    
  );
};

export default Qmf_numero_correctoDialog;
